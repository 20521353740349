// Authorization: `token ${token}`,
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};

const signup = (method, body) => {
    return httpcall("adminusers/signup", method, body);
};

const login = (method, body) => {
    return httpcall("adminusers/login", method, body);
};
const getCategories = (method, body, token) => {
    return httpcall("adminusers/categories/all", method, body, token);
};
const AddCategories = (method, body, token) => {
    return httpcall("adminusers/categories/add", method, body, token);
};
const getCategoriesbyId = (method, body, token, id) => {
    return httpcall(`adminusers/categories/detail/${id}`, method, body, token);
};
const updatecategories = (method, body, token, id) => {
    return httpcall(`adminusers/categories/update/${id}`, method, body, token);
};
const Deletecategories = (method, body, token, id) => {
    return httpcall(`adminusers/categories/delete/${id}`, method, body, token);
};
const getwarehouses = (method, body, token) => {
    return httpcall("warehouses/all", method, body, token);
};

const getWarehousebyId = (method, body, token, id) => {
    return httpcall(`warehouses/detail/${id}`, method, body, token);
};
const AddWarehouse = (method, body, token) => {
    return httpcall("warehouses/add", method, body, token);
};
const updateWarehouse = (method, body, token, id) => {
    return httpcall(`warehouses/update/${id}`, method, body, token);
};
const DeleteWarehouse = (method, body, token, id) => {
    return httpcall(`warehouses/delete/${id}`, method, body, token);
};

const getBanners = (method, body, token) => {
    return httpcall("adminusers/banners/all", method, body, token);
};
const AddBanners = (method, body, token) => {
    return httpcall("adminusers/banners/add", method, body, token);
};
const getBannerbyId = (method, body, token, id) => {
    return httpcall(`adminusers/banners/detail/${id}`, method, body, token);
};
const updateBanner = (method, body, token, id) => {
    return httpcall(`adminusers/banners/update/${id}`, method, body, token);
};
const DeleteBannner = (method, body, token, id) => {
    return httpcall(`adminusers/banners/delete/${id}`, method, body, token);
};
const getUsers = (method, body, token) => {
    return httpcall("adminusers/userslist", method, body, token);
};
const Getuserbyid = (method, body, token, id) => {
    return httpcall(`adminusers/userdetail/${id}`, method, body, token);
};
const updateuser = (method, body, token, id) => {
    return httpcall(`adminusers/user/update/${id}`, method, body, token);
};
const DeleteUser = (method, body, token, id) => {
    return httpcall(`adminusers/user/delete/${id}`, method, body, token);
};
const getProducts = (method, body, token) => {
    return httpcall("products/list", method, body, token);
};
// const getProductsByCategoryId = (method, body, token, id) => {
//     return httpcall(`products/bycategory/${id}`, method, body, token);
// };
const getProductsByProductId = (method, body, token, id) => {
    return httpcall(`products/detail/${id}`, method, body, token);
};
const updateProduct = (method, body, token, id) => {
    return httpcall(`products/update/${id}`, method, body, token);
};
const getPromoCode = (method, body, token) => {
    return httpcall("promocodes/admin/all", method, body, token);
};
const addPromoCode = (method, body, token) => {
    return httpcall("promocodes/admin/add", method, body, token);
};

const getpromocodeByID = (method, body, token, id) => {
    return httpcall(`promocodes/admin/detail/${id}`, method, body, token);
};
const updatepromocode = (method, body, token, id) => {
    return httpcall(`promocodes/admin/update/${id}`, method, body, token);
};
const DeletePromocode = (method, body, token, id) => {
    return httpcall(`promocodes/admin/delete/${id}`, method, body, token);
};
const getAllOrders = (method, body, token, id) => {
    return httpcall(`adminorders`, method, body, token);
};
const orderbyid = (method, body, token, id) => {
    return httpcall(`adminorders/orderdetail/${id}`, method, body, token);
};
const getAdminusers = (method, body, token) => {
    return httpcall("adminusers/list", method, body, token);
};
const addAdminUser = (method, body, token) => {
    return httpcall("adminusers/addadmin", method, body, token);
};
const AdminUserById = (method, body, token, id) => {
    return httpcall(`adminusers/detail/${id}`, method, body, token);
};
const getOrderByStatus = (method, body, token, id) => {
    return httpcall(`adminorders?orderStatus=${id}`, method, body, token);
};
const updateAdmindata = (method, body, token, id) => {
    return httpcall(`adminusers/updateadminuser/${id}`, method, body, token);
};
const deleteAdmindata = (method, body, token, id) => {
    return httpcall(`adminusers/deleteadminuser/${id}`, method, body, token);
};
const updateprofile = (method, body, token, id) => {
    return httpcall(`adminusers/update/${id}`, method, body, token);
};
const getFranchiseForm = (method, body, token) => {
    return httpcall(`forms/franchiseEnquiryforms`, method, body, token);
};
const getCallbackForm = (method, body, token) => {
    return httpcall(`forms/callbackforms`, method, body, token);
};
const getAvailabilityform = (method, body, token) => {
    return httpcall(`forms/availabilityforms`, method, body, token);
};
const orderByUserId = (method, body, token, id) => {
    return httpcall(`adminorders?userId=${id}`, method, body, token);
};
const UpdateOrderDetails = (method, body, token, id) => {
    return httpcall(`adminorders/update/${id}`, method, body, token);
};
const getNotifyProducts = (method, body, token) => {
    return httpcall(`adminusers/notifyproducts`, method, body, token);
};
const updateNotifyProducts = (method, body, token, id) => {
    return httpcall(
        `adminusers/notifyproductupdate/${id}`,
        method,
        body,
        token
    );
};
const AddShipmentData = (method, body, token) => {
    return httpcall(`shiprocket/createorder`, method, body, token);
};
const getCallBackById = (method, body, token, id) => {
    return httpcall(`forms/callback/${id}`, method, body, token);
};
const getFranchiseById = (method, body, token, id) => {
    return httpcall(`forms/franchiseEnquiry/${id}`, method, body, token);
};
const getAvailabilityById = (method, body, token, id) => {
    return httpcall(`forms/availability/${id}`, method, body, token);
};
const getNotifyById = (method, body, token, id) => {
    return httpcall(
        `adminusers/notifyproductdetail/${id}`,
        method,
        body,
        token
    );
};
const warehouseProducts = (method, body, token) => {
    return httpcall(`warehouses/products`, method, body, token);
};
const addWrehouseProducts = (method, body, token, id) => {
    return httpcall(`warehouses/addproduct`, method, body, token);
};
const updateWarehouseProducts = (method, body, token, warehouseid, prodid) => {
    return httpcall(
        `warehouses/updateproducts/${warehouseid}/${prodid}`,
        method,
        body,
        token
    );
};
const getWarehouseProductsbyid = (method, body, token, warehouseid, prodid) => {
    return httpcall(
        `warehouses/productdetail/${warehouseid}/${prodid}`,
        method,
        body,
        token
    );
};
const getSupplier = (method, body, token) => {
    return httpcall(`supplier/all`, method, body, token);
};
const addSupplier = (method, body, token) => {
    return httpcall(`supplier/add`, method, body, token);
};
const SupplierDetails = (method, body, token, id) => {
    return httpcall(`supplier/detail/${id}`, method, body, token);
};
const SupplierUpdate = (method, body, token, id) => {
    return httpcall(`supplier/update/${id}`, method, body, token);
};
const SupplierDelete = (method, body, token, id) => {
    return httpcall(`supplier/delete/${id}`, method, body, token);
};
const getpoentry = (method, body, token) => {
    return httpcall(`adminusers/poentrylist`, method, body, token);
};
const getpoentryDetails = (method, body, token, id) => {
    return httpcall(`adminusers/poentrydetail/${id}`, method, body, token);
};
const getitemDetails = (method, body, token, id) => {
    return httpcall(`adminusers/poitemdetail/${id}`, method, body, token);
};
const Addpoentry = (method, body, token) => {
    return httpcall(`adminusers/addpoentry`, method, body, token);
};
const AddItem = (method, body, token) => {
    return httpcall(`adminusers/addpoitem`, method, body, token);
};
const Updatepoentry = (method, body, token, id) => {
    return httpcall(`adminusers/updatepoentry/${id}`, method, body, token);
};
const DeletePoentry = (method, body, token, id) => {
    return httpcall(`adminusers/Deletepoentry/${id}`, method, body, token);
};
const DeletePoitem = (method, body, token, id) => {
    return httpcall(`adminusers/deletepoitem/${id}`, method, body, token);
};
const UpdatePoitem = (method, body, token, id) => {
    return httpcall(`adminusers/updatepoitem/${id}`, method, body, token);
};
const SupplierSearch = (method, body, token, name) => {
    return httpcall(`supplier/searchbyname/${name}`, method, body, token);
};
const ProductSearch = (method, body, token, name) => {
    return httpcall(`products/searchbyname/${name}`, method, body, token);
};
const ProductSearchpOST = (method, body, token, name) => {
    return httpcall(`products/searchbyname`, method, body, token);
};
const batchinfobyProductid = (method, body, token, id) => {
    return httpcall(`adminusers/poitem/byproductid/${id}`, method, body, token);
};
const getAdminroles = (method, body, token) => {
    return httpcall(`adminusers/adminrolelist`, method, body, token);
};
const UpdateAdminroles = (method, body, token, id) => {
    return httpcall(`adminusers/updateadminrole/${id}`, method, body, token);
};
const DeleteAdminroles = (method, body, token, id) => {
    return httpcall(`adminusers/deleteadminrole/${id}`, method, body, token);
};
const AddAdminroles = (method, body, token) => {
    return httpcall(`adminusers/addadminrole`, method, body, token);
};
const Addpoitem = (method, body, token) => {
    return httpcall(`adminusers/addpoitem`, method, body, token);
};

const warehouseproductSearch = (
    method,
    body,
    token,
    warehousId,
    ProductName
) => {
    return httpcall(
        `warehouses/productsbyname/${warehousId}/${ProductName}`,
        method,
        body,
        token
    );
};
const warehouseproductS = (method, body, token, warehousId, ProductName) => {
    return httpcall(
        `warehouses/productsbyname/${warehousId}`,
        method,
        body,
        token
    );
};
const getSupplierPayments = (method, body, token) => {
    return httpcall(`adminorders/supplierpayments`, method, body, token);
};
const addSupplierPayment = (method, body, token) => {
    return httpcall(`adminorders/addsupplierpayment`, method, body, token);
};
const SupplierPaymentDetails = (method, body, token, id) => {
    return httpcall(
        `adminorders/supplierpaymentdetail/${id}`,
        method,
        body,
        token
    );
};
const SupplierPaymentUpdate = (method, body, token, id) => {
    return httpcall(
        `adminorders/updatesupplierpayment/${id}`,
        method,
        body,
        token
    );
};
const SupplierPaymentdelete = (method, body, token, id) => {
    return httpcall(
        `adminorders/deletesupplierpayment/${id}`,
        method,
        body,
        token
    );
};
const getLedger = (method, body, token, prodid, warehouseid) => {
    return httpcall(
        `adminusers/ledgerbyproduct/${prodid}/${warehouseid}`,
        method,
        body,
        token
    );
};
const returnOrder = (method, body, token) => {
    return httpcall(`adminorders/returnorder`, method, body, token);
};

const sales = (method, body, token, type, app) => {
    return httpcall(`adminusers/${app}?value=${type}`, method, body, token);
};
const salesByDates = (method, body, token, fromDate, toDate, app) => {
    return httpcall(
        `adminusers/${app}?fromdate=${fromDate}&todate=${toDate}`,
        method,
        body,
        token
    );
};
const StockValue = (method, body, token, fromDate,) => {
    return httpcall(`adminusers/getstockvalue?value=all`, method, body, token);
};
const getPrice = (method, body, token, fromDate, toDate) => {
    return httpcall(`shiprocket/listdeliverypartners`, method, body, token);
};
const shopmentOrders = (method, body, token, fromDate, toDate) => {
    return httpcall(`/shiprocket/getorders`, method, body, token);
};
const shopmentOrdersDetailsById = (method, body, token, orderId) => {
    return httpcall(`shiprocket/orderdetail/${orderId}`, method, body, token);
};
const getPoentryById = (method, body, token) => {
    return httpcall(`adminusers/poentrybywarehouse`, method, body, token);
};
const getOrdersById = (method, body, token, Id) => {
    return httpcall(`warehouses/orders/${Id}`, method, body, token);
};
const getInvoicebyId = (method, body, token, Id) => {
    return httpcall(`supplier/invoices/${Id}`, method, body, token);
};
const getMolecules = (method, body, token) => {
    return httpcall(`adminusers/moleculecompositionlist`, method, body, token);
};
const AddMolecules = (method, body, token) => {
    return httpcall(`adminusers/addmoleculecomposition`, method, body, token);
};
const DeleteMolecules = (method, body, token, id) => {
    return httpcall(
        `adminusers/deletemoleculecomposition/${id}`,
        method,
        body,
        token
    );
};
const UpdateMolecules = (method, body, token, id) => {
    return httpcall(
        `adminusers/updatemoleculecomposition/${id}`,
        method,
        body,
        token
    );
};
const getMoleculesDetails = (method, body, token, id) => {
    return httpcall(
        `adminusers/moleculecompositiondetail/${id}`,
        method,
        body,
        token
    );
};
const getCompany = (method, body, token, id) => {
    return httpcall(`adminusers/companieslist`, method, body, token);
};
const getGenericNames = (method, body, token, id) => {
    return httpcall(`products/genericnamesdropdown`, method, body, token);
};
const getManufacturer = (method, body, token, id) => {
    return httpcall(`products/manufacturerdropdown`, method, body, token);
};
const addMasterProduct = (method, body, token) => {
    return httpcall(`products/add`, method, body, token);
};
const addCompany = (method, body, token) => {
    return httpcall(`adminusers/addcompany`, method, body, token);
};
const DeleteCompany = (method, body, token, id) => {
    return httpcall(`adminusers/deletecompany/${id}`, method, body, token);
};
const updateCompany = (method, body, token, id) => {
    return httpcall(`adminusers/updatecompany/${id}`, method, body, token);
};
const getCompanyDetails = (method, body, token, id) => {
    return httpcall(`adminusers/companydetail/${id}`, method, body, token);
};
const DealofTheDay = (method, body, token, id) => {
    return httpcall(`warehouses/dealsoftheday/${id}`, method, body, token);
};
const AddDealofTheDay = (method, body, token) => {
    return httpcall(`warehouses/adddealoftheday`, method, body, token);
};
const pushNotify = (method, body, token) => {
    return httpcall(`adminusers/send_usernotification`, method, body, token);
};
const DeleteDealofTheDay = (method, body, token, warehouseid, productid) => {
    return httpcall(
        `warehouses/deletedealoftheday/${warehouseid}/${productid}`,
        method,
        body,
        token
    );
};
const getstockTransferBywarehouseId = (method, body, token, warehouseid) => {
    return httpcall(
        `adminusers/gettransferstock/${warehouseid}`,
        method,
        body,
        token
    );
};
const allReportsDownload = (method, body, token) => {
    return httpcall(`adminusers/allreports`, method, body, token);
};

const ProductTransferSearch = (method, body, token) => {
    return httpcall(`adminusers/get_poitems_byname`, method, body, token);
};
const stockTransfer = (method, body, token) => {
    return httpcall(`adminusers/transferstock`, method, body, token);
};
const stockUpdateTransfer = (method, body, token, id) => {
    return httpcall(
        `adminusers/updatetransferstock/${id}`,
        method,
        body,
        token
    );
};
const stockDetailsbyId = (method, body, token, id) => {
    return httpcall(
        `adminusers/transferstockdetail/${id}`,
        method,
        body,
        token
    );
};
const getConfig = (method, body, token) => {
    return httpcall(`adminusers/config/all`, method, body, token);
};
const addConfig = (method, body, token) => {
    return httpcall(`adminusers/config/add`, method, body, token);
};
const updateConfig = (method, body, token, Id) => {
    return httpcall(`adminusers/config/update/${Id}`, method, body, token);
};
const getconfigDetailsById = (method, body, token, Id) => {
    return httpcall(`adminusers/config/detail/${Id}`, method, body, token);
};
const deleteconfigDetailsById = (method, body, token, Id) => {
    return httpcall(`adminusers/config/delete/${Id}`, method, body, token);
};
const bulkNotifications = (method, body, token, Id) => {
    return httpcall(
        `adminusers/send_bulk_usernotification`,
        method,
        body,
        token
    );
};
const CancelAdminOrder = (method, body, token, Id) => {
    return httpcall(`adminorders/cancelorder/${Id}`, method, body, token);
};
const uniqueCompositions = (method, body, token, Id) => {
    return httpcall(
        `adminusers/unique_MgCompositions
`,
        method,
        body,
        token
    );
};
const updateComposition = (method, body, token, id) => {
    return httpcall(
        `adminusers/update_MgCompositions/${id}`,
        method,
        body,
        token
    );
};
const getCategoryTypes = (method, body, token) => {
    return httpcall(`adminusers/categorytype/all`, method, body, token);
};
const AddCategoryTypes = (method, body, token) => {
    return httpcall(`adminusers/categorytype/add`, method, body, token);
};
const updateCategoryTypes = (method, body, token, id) => {
    return httpcall(
        `adminusers/categorytype/update/${id}`,
        method,
        body,
        token
    );
};
const CategoryTypeDetail = (method, body, token, id) => {
    return httpcall(
        `adminusers/categorytype/detail/${id}`,
        method,
        body,
        token
    );
};
const editLedger = (method, body, token,) => {
    return httpcall(
        `adminusers/stockadjustment`,
        method,
        body,
        token
    );
};
const gethomepagesections = (method, body, token,) => {
    return httpcall(
        `adminusers/homepagesections`,
        method,
        body,
        token
    );
};
const addhomepagesections = (method, body, token,) => {
    return httpcall(
        `adminusers/homepagesections/add`,
        method,
        body,
        token
    );
};
const deleteHomePageSection = (method, body, token, id) => {
    return httpcall(
        `adminusers/deletehomepagesections/${id}`,
        method,
        body,
        token
    );
};
const updatehomescreenSection = (method, body, token, id) => {
    return httpcall(
        `adminusers/updatehomepagesections/${id}`,
        method,
        body,
        token
    );
};
const gethomescreensectionByid = (method, body, token, id) => {
    return httpcall(
        `adminusers/homepagesections_by_id/${id}`,
        method,
        body,
        token
    );
};
const homepageProductsbyid = (method, body, token, HomeSectionId, warehouseId) => {
    return httpcall(
        `warehouses/homesection-products/${HomeSectionId}/${warehouseId}`,
        method,
        body,
        token
    );
};
const AddHomePageSectionProducts = (method, body, token,) => {
    return httpcall(
        `warehouses/add-homesection-products`,
        method,
        body,
        token
    );
};
const DeleteHomePageProducts = (method, body, token, HomeSectionId,) => {
    return httpcall(
        `warehouses/delete-homesection-products/${HomeSectionId}`,
        method,
        body,
        token
    );
};
const updateHomePageProducts = (method, body, token, HomeSectionId,) => {
    return httpcall(
        `warehouses/update-homesection-products/${HomeSectionId}`,
        method,
        body,
        token
    );
};
const userinfodownload = (method, body, token, HomeSectionId,) => {
    return httpcall(
        `adminusers/medbuzz_UserReports`,
        method,
        body,
        token
    );
};
const failedOrders = (method, body, token, HomeSectionId,) => {
    return httpcall(
        `adminusers/medbuzz_Failed_Orders_Reports`,
        method,
        body,
        token
    );
};
const searchPoentriy = (method, body, token, warehouseId, search,) => {
    return httpcall(
        `adminusers/POentries_Search`,
        method,
        body,
        token
    );
};
const searchByProductName = (method, body, token) => {
    return httpcall(
        `warehouses/warehouseproductsbyname`,
        method,
        body,
        token
    );
};
const deleteUser = (method, body, token, userid) => {
    return httpcall(
        `adminusers/user/delete/${userid}`,
        method,
        body,
        token
    );
};
const medbuzz_Users = (method, body, token, userid) => {
    return httpcall(
        `adminusers/allmedbuzz_users`,
        method,
        body,
        token
    );
};
const medbuzz_Users_Search_Hit = (method, body, token, userid) => {
    return httpcall(
        `adminusers/medbuzz_SearchHits`,
        method,
        body,
        token
    );
};
const ClaimStatus = (method, body, token, id) => {
    return httpcall(
        `adminusers/update_medbuzz_searchhits/${id}`,
        method,
        body,
        token
    );
};
const Xbees_Order_Status = (method, body, token) => {
    return httpcall(
        `adminusers/xbeeorders_status`,
        method,
        body,
        token
    );
};
const Xbees_Orders = (method, body, token, StatusCode, fromDate, ToDate) => {
    return httpcall(
        `adminusers/xbeeorders_by_status?StatusCode=${StatusCode}&FromDate=${fromDate}&ToDate=${ToDate}`,
        method,
        body,
        token
    );
};
const productDetails = (method, body, token, productId, warehouseId) => {
    return httpcall(
        `adminusers/batchesbyproduct/${productId}/${warehouseId}`,
        method,
        body,
        token
    );
};
const updateQTY = (method, body, token, poItemID) => {
    return httpcall(
        `adminusers/update_poitem_quantity/${poItemID}`,
        method,
        body,
        token
    );
};

export const Services = {
    Xbees_Order_Status,
    productDetails,
    Xbees_Orders,
    deleteUser,
    searchPoentriy,
    searchByProductName,
    ClaimStatus,
    updateQTY,
    failedOrders,
    userinfodownload,
    updateHomePageProducts,
    AddHomePageSectionProducts,
    DeleteHomePageProducts,
    gethomescreensectionByid,
    homepageProductsbyid,
    updatehomescreenSection,
    deleteHomePageSection,
    addhomepagesections,
    gethomepagesections,
    CategoryTypeDetail,
    editLedger,
    getCategoryTypes,
    AddCategoryTypes,
    updateCategoryTypes,
    medbuzz_Users,
    CancelAdminOrder,
    getWarehouseProductsbyid,
    signup,
    login,
    getCategories,
    AddCategories,
    getCategoriesbyId,
    updatecategories,
    Deletecategories,
    getwarehouses,
    getWarehousebyId,
    AddWarehouse,
    updateWarehouse,
    DeleteWarehouse,
    getBanners,
    AddBanners,
    getBannerbyId,
    updateBanner,
    DeleteBannner,
    getUsers,
    updateuser,
    DeleteUser,
    Getuserbyid,
    getProducts,
    // getProductsByCategoryId,
    getProductsByProductId,
    updateProduct,
    getPromoCode,
    addPromoCode,
    getpromocodeByID,
    updatepromocode,
    DeletePromocode,
    getAllOrders,
    orderbyid,
    getAdminusers,
    addAdminUser,
    AdminUserById,
    getOrderByStatus,
    updateAdmindata,
    deleteAdmindata,
    updateprofile,
    getFranchiseForm,
    getCallbackForm,
    getAvailabilityform,
    orderByUserId,
    UpdateOrderDetails,
    getNotifyProducts,
    updateNotifyProducts,
    AddShipmentData,
    getCallBackById,
    getFranchiseById,
    getAvailabilityById,
    getNotifyById,
    warehouseProducts,
    addWrehouseProducts,
    updateWarehouseProducts,
    getSupplier,
    addSupplier,
    SupplierDetails,
    SupplierUpdate,
    SupplierDelete,
    getpoentry,
    Addpoentry,
    getpoentryDetails,
    Updatepoentry,
    DeletePoentry,
    AddItem,
    DeletePoitem,
    getitemDetails,
    UpdatePoitem,
    SupplierSearch,
    ProductSearch,
    batchinfobyProductid,
    getAdminroles,
    UpdateAdminroles,
    AddAdminroles,
    DeleteAdminroles,
    Addpoitem,
    warehouseproductSearch,
    getSupplierPayments,
    addSupplierPayment,
    SupplierPaymentDetails,
    SupplierPaymentdelete,
    SupplierPaymentUpdate,
    getLedger,
    returnOrder,
    sales,
    salesByDates,
    StockValue,
    getPrice,
    shopmentOrders,
    shopmentOrdersDetailsById,
    getPoentryById,
    getOrdersById,
    getInvoicebyId,
    getMolecules,
    AddMolecules,
    DeleteMolecules,
    UpdateMolecules,
    getMoleculesDetails,
    getCompany,
    getGenericNames,
    getManufacturer,
    addMasterProduct,
    addCompany,
    DeleteCompany,
    updateCompany,
    getCompanyDetails,
    DealofTheDay,
    DeleteDealofTheDay,
    AddDealofTheDay,
    ProductSearchpOST,
    pushNotify,
    getstockTransferBywarehouseId,
    allReportsDownload,
    ProductTransferSearch,
    warehouseproductS,
    stockTransfer,
    stockUpdateTransfer,
    stockDetailsbyId,
    getConfig,
    addConfig,
    updateConfig,
    getconfigDetailsById,
    deleteconfigDetailsById,
    bulkNotifications,
    uniqueCompositions,
    updateComposition,
    medbuzz_Users_Search_Hit
};
