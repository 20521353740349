import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import { Services } from "../../../Services";
import { useForm } from "react-hook-form";
import cancelClasses from "./cancelorder.module.css";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
function Modal(props) {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const onSubmit = (data) => {
        setloading(true);
        let body = {
            orderStatus: 7,
            cancellationReason: data.cancellationReason,
        };
        Services.CancelAdminOrder("PUT", JSON.stringify(body), token, props.id)
            .then((res) => {
                if (res.Status === 1) {
                    props.getorders();
                    props.setmodal(false);
                    setloading(false);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    props.setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            {loading ? (
                <div className={cancelClasses["modal"]}>
                    <div className={cancelClasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            ) : (
                <div className={cancelClasses["modal"]} onClick={CancelHandler}>
                    <div
                        className={cancelClasses["modal-content"]}
                        onClick={openhandler}>
                        <button className={cancelClasses["close-btn"]}>
                            {/* <FaRegWindowClose size={27} /> */}
                        </button>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={cancelClasses["form-control"]}>
                                <label htmlFor="Cancellation Reason">
                                    Reason for Cancellation
                                </label>
                                <textarea
                                    placeholder="reason"
                                    rows={5}
                                    {...register("cancellationReason", {
                                        required:
                                            "Cancellation Reason is required!",
                                    })}
                                />
                                {errors?.CancellationReason && (
                                    <p className={cancelClasses.error}>
                                        {errors?.CancellationReason?.message}
                                    </p>
                                )}
                            </div>
                            <div className={cancelClasses["control-btns"]}>
                                <button
                                    className={cancelClasses["delete-btn"]}
                                    type="submit">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
