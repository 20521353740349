import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { BsWhatsapp } from "react-icons/bs";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Claim from "./Claim";
function Warehouse() {
    const Navigate = useNavigate();
    const [params, setparams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1);
    const [forcepage, setforcepage] = useState(0);
    const [pagsize, setpagesize] = useState(10);
    const [tableload, settableload] = useState(false);
    const [showPaginate, setShowPagiate] = useState(true);
    const [users, setusers] = useState([]);
    const [warehousedata, setWarehouses] = useState([]);
    const [slot, setSlot] = useState(params.get("slot") || "");
    const [nameSearch, setNamesearch] = useState("");
    const [showClaim, setShowClaim] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(
        params.get("warehouse") || ""
    );
    const [searchID, setSearchID] = useState("");
    const admindata = JSON.parse(localStorage.getItem("admindata"));
    let [loading, setLoading] = useState(true);
    const [Navactive, setNavactive] = useState(params.get("active") || "1");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getUsers = (skip) => {
        console.log(skip);
        //searchByProductName
        settableload(true);

        let body = {
            skip: skip,
            limit: 10,
            ClaimStatus: Navactive,
        };
        if (selectedWarehouse.length > 0) {
            body = { ...body, warehouseId: selectedWarehouse };
        }
        if (nameSearch.length > 0) {
            body = { ...body, Search_Input: nameSearch };
        }
        if (slot.length > 0) {
            body = { ...body, sort: Number(slot) };
        }
        Services.medbuzz_Users("POST", JSON.stringify(body), token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    console.log(response);
                    settableload(false);
                    setusers(response.Users);
                    if (response.Users.length == 0) {
                        setShowPagiate(false);
                    }
                    setpagesize(10);
                    setpageCount(
                        Math.ceil(Number(response?.Count) / Number(10))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getwarehouses();
    }, []);
    useEffect(() => {
        setforcepage(Number(params.get("page")) - 1);
        getUsers((params.get("page") - 1) * 10);
        // eslint-disable-next-line
    }, [Navactive, selectedWarehouse, slot]);

    useEffect(() => {
        let timer;
        if (nameSearch.length > 0) {
            timer = setTimeout(() => {
                getUsers(0);
            }, 300);
        } else {
            getUsers(0);
        }
        return () => clearTimeout(timer);
    }, [nameSearch]);
    const infoFormat = (cell, cellContent) => {
        return (
            <span>
                {cellContent.Name},<br /> {cellContent.PhoneNumber}{" "}
                <button
                    style={{ border: "none", backgroundColor: "inherit" }}
                    onClick={() => {
                        window.open(
                            `https://wa.me/${cellContent.PhoneNumber}`,
                            "_blank"
                        );
                    }}>
                    <BsWhatsapp size={18} color="green" />
                </button>
                ,
                <br /> {cellContent.EmailID}
            </span>
        );
    };
    function getSearchparams() {
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObject = Object.fromEntries(searchParams.entries());
        return paramsObject;
    }
    const dateFormatter = (cell, cellContent) => {
        return (
            <Moment format="MMMM Do YYYY, h:mm:ss a">{new Date(cell)}</Moment>
        );
    };
    const columns = [
        {
            dataField: "Name",
            text: "USER INFO",
            sort: false,
            formatter: infoFormat,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                    "word-wrap": "break-word",
                };
            },
        },

        {
            dataField: "ClaimedByName",
            text: "CLAIMEDBY",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Remarks",
            text: "REMARKS",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "150px" };
            },
        },
        {
            dataField: "warehouseName",
            text: "WAREHOUSE ",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "150px" };
            },
        },
        {
            dataField: "Claim",
            text: "CLAIM",
            sort: false,
            formatter: (cell, cellConent) => {
                if (
                    cellConent.ClaimedByName == "NA" ||
                    cellConent.ClaimedByName == null
                ) {
                    // if (true) {
                    return (
                        <button
                            id={cellConent.USERID}
                            className={dashboardclasses["claim-btn"]}
                            onClick={calledStatus}>
                            {" "}
                            CLAIM
                        </button>
                    );
                } else {
                    return (
                        <button
                            className={dashboardclasses["claim-btn"]}
                            id={cellConent.USERID}
                            claimedBy={cellConent.ClaimedBy}
                            onClick={(e) => {
                                let id = e.currentTarget.getAttribute("id");
                                setShowClaim(true);
                                setSearchID(id);
                            }}
                            disabled={
                                cellConent.ClaimStatus == 3 ||
                                admindata.adminUserId != cellConent.ClaimedBy
                            }>
                            {" "}
                            Called
                        </button>
                    );
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "IsAddedToCart",
            text: "IS ADDED ",
            sort: false,
            formatter: (cell) => (
                <span style={{ textAlign: "center" }}>{cell}</span>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "created_at",
            text: "ACCOUNT REGISTERED ON",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "150px" };
            },
        },
        {
            dataField: "Search hit",
            text: "SEARCH HITS",
            sort: false,
            formatter: (cell, cellContent) => {
                return (
                    <NavLink
                        onClick={() => {
                            localStorage.setItem(
                                "searchhits",
                                JSON.stringify(cellContent)
                            );
                        }}
                        to={`/SearchHit?id=${cellContent.USERID}&type=users`}>
                        Search Hit
                    </NavLink>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const calledStatus = (e) => {
        let id = e.currentTarget.getAttribute("id");

        let method = JSON.stringify({
            ClaimedBy: admindata.adminUserId,
            ClaimStatus: 2,
        });

        Services.ClaimStatus("PUT", method, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    // getUsers(Number(params.get("page") - 1) * 10);
                    getUsers(0);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];

    // const searchByNumber = (skip, number) => {
    //     let body = {
    //         skip: skip,
    //         limit: 10,
    //         Search_Input: number,
    //     };
    //     if (selectedWarehouse != "all") {
    //         body = { ...body, warehouseId: selectedWarehouse };
    //     }
    //     Services.medbuzz_Users("POST", JSON.stringify(body), token)
    //         .then((response) => {
    //             setLoading(false);
    //             if (response.Status === 1) {
    //                 // setShowPagiate(false);
    //                 settableload(false);
    //                 setusers(response.Users);
    //                 if (response.Users.length == 0) {
    //                     setShowPagiate(false);
    //                 }
    //                 setpagesize(10);
    //                 setpageCount(
    //                     Math.ceil(Number(response?.Count) / Number(10))
    //                 );
    //             } else if (response.Status === 0) {
    //                 toast.error(`${response.Message}`, {
    //                     position: "top-center",
    //                     autoClose: 1000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //                 if (response.Message === "Token expired") {
    //                     Navigate("/");
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             // alert(err);
    //             alert("something went wrong please try again");
    //             console.log(err);
    //         });
    // };

    const namefilter = (e) => {
        settableload(true);
        setparams({ page: 1, type: "users" });
        setforcepage(0);
        setNamesearch(e.target.value);
        setShowPagiate(true);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        window.scrollTo({ top: 10, behavior: "instant" });
        setparams({
            ...getSearchparams(),
            page: selectedObject.selected + 1,
            type: "users",
        });
        setforcepage(selectedObject.selected);
        settableload(true);

        getUsers(selectedObject.selected * pagsize);
    };
    const navactivehandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        setNavactive(id);

        setparams({ ...getSearchparams(), page: 1, type: "users", active: id });
    };
    const warehousefilter = (e) => {
        settableload(true);

        setparams({
            ...getSearchparams(),
            page: 1,
            type: "users",
            warehouse: e.target.value,
        });
        setforcepage(0);
        setSelectedWarehouse(e.target.value);
        setShowPagiate(true);
    };

    const slotChange = (e) => {
        settableload(true);
        setparams({
            ...getSearchparams(),
            page: 1,
            type: "users",
            slot: e.target.value,
        });
        setforcepage(0);
        setSlot(e.target.value);
        setShowPagiate(true);
    };

    return (
        <>
            {showClaim && (
                <Claim
                    setmodal={setShowClaim}
                    searchID={searchID}
                    getUsers={getUsers}
                    page={params.get("page")}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="deals" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div className={dashboardclasses["internalTransfer"]}>
                            <h3>Medbuzz Users</h3>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Name, Email or phone "
                                        onChange={namefilter}
                                    />
                                    <div
                                        className={
                                            dashboardclasses[
                                                "warehouseFilter_all"
                                            ]
                                        }>
                                        <select
                                            onChange={warehousefilter}
                                            value={selectedWarehouse}
                                            defaultValue={""}>
                                            <option value={""} disabled>
                                                Select Warehouse
                                            </option>
                                            {warehousedata?.map((item) => (
                                                <option
                                                    value={item.warehouseId}>
                                                    {item.warehouseName}
                                                </option>
                                            ))}
                                        </select>
                                        {selectedWarehouse.length > 0 && (
                                            <button
                                                onClick={() => {
                                                    let allParams =
                                                        getSearchparams();
                                                    delete allParams.warehouse;
                                                    setparams(allParams);
                                                    setSelectedWarehouse("");
                                                }}>
                                                <IoClose />
                                            </button>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "warehouseFilter_all"
                                            ]
                                        }>
                                        <select
                                            onChange={slotChange}
                                            value={slot}
                                            defaultValue={""}>
                                            <option value={""} disabled>
                                                Filter By
                                            </option>
                                            <option value={"1"}>
                                                Registration Date
                                            </option>
                                        </select>
                                        {slot.length > 0 && (
                                            <button
                                                onClick={() => {
                                                    let allParams =
                                                        getSearchparams();
                                                    delete allParams.slot;
                                                    setparams(allParams);
                                                    setSlot("");
                                                }}>
                                                <IoClose />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="1"
                                        className={
                                            Navactive == "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        New
                                    </button>
                                    <button
                                        id="2"
                                        className={
                                            Navactive == "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Claimed
                                    </button>
                                    <button
                                        id="3"
                                        className={
                                            Navactive == "3" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Completed
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="category-table">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                data={users}
                                                columns={columns}
                                                defaultSorted={defaultSorted}
                                                wrapperClasses="table-responsive"
                                                noDataIndication={
                                                    emptyDataMessage
                                                }
                                            />
                                            {showPaginate && (
                                                <div
                                                    style={{
                                                        justifySelf: "right",
                                                    }}>
                                                    <ReactPaginate
                                                        pageCount={pageCount}
                                                        // pageRange={2}
                                                        forcePage={forcepage}
                                                        marginPagesDisplayed={2}
                                                        onPageChange={
                                                            handlePageChange
                                                        }
                                                        breakClassName={
                                                            "page-item"
                                                        }
                                                        breakLinkClassName={
                                                            "page-link"
                                                        }
                                                        containerClassName={
                                                            "pagination"
                                                        }
                                                        pageClassName={
                                                            "page-item"
                                                        }
                                                        pageLinkClassName={
                                                            "page-link"
                                                        }
                                                        previousClassName={
                                                            "page-item"
                                                        }
                                                        previousLinkClassName={
                                                            "page-link"
                                                        }
                                                        nextClassName={
                                                            "page-item"
                                                        }
                                                        nextLinkClassName={
                                                            "page-link"
                                                        }
                                                        activeClassName={
                                                            "active"
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
