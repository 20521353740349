import React, { useEffect, useState } from "react";
import TopNav from "../Navbar/topNav";
import SideNav from "../Navbar/sideNav";
import dashboardclasses from "./dashboard.module.css";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { css } from "@emotion/react";
import resolveClasses from "../dashboard/AvailabilityForm/resolve.module.css";
import FadeLoader from "react-spinners/FadeLoader";
import Modal from "../utils/error";
function Reports() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const [FromDateError, setfromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [modal, setModal] = useState(false);
    const [error, seterror] = useState("");
    const [warehouse, setWareHouse] = useState([]);

    const [OrderStatus, setOrderStatus] = useState([]);
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setWareHouse(response.warehouses);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        Services.Xbees_Order_Status("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    if (response?.Message?.length > 0) {
                        setOrderStatus(
                            response?.Message?.filter(
                                (item) => item.StatusCode != null
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        let timer = setTimeout(() => {
            setloading(false);
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const watchFormData = watch();
    const onSubmit = (data) => {
        if (data.Type === "Xbee_Order") {
            setloading(true);
            Services.Xbees_Orders(
                "GET",
                null,
                token,
                data?.StatusCode,
                data?.fromDate,
                data?.toDate
            )
                .then((res) => {
                    if (res.Status === 1) {
                        fetch(res.CsvFile).then((response) => {
                            response.blob().then((blob) => {
                                // Creating new object of PDF file
                                const fileURL =
                                    window.URL.createObjectURL(blob);
                                // Setting various property values
                                let alink = document.createElement("a");
                                alink.href = fileURL;
                                alink.download = `${data.Type}.xlsx`;
                                alink.click();
                                setloading(false);
                                reset();
                            });
                        });
                    } else if (res.Status === 0) {
                        setloading(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setloading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
            return;
        }
        let Body = {
            type: data.Type,
        };
        if (data.Type == "ProductReports") {
            Body = {
                ...Body,
                status: data.status,
            };
        }
        if (
            data.Type != "ProductReports" &&
            data.Type != "StockReports" &&
            data.Type != "ExpiredProducts" &&
            data.Type != "UnExpiredProducts" &&
            data.Type != "Records" &&
            data.Type != "ApprovedUsers" &&
            data.Type != "Coupons"
        ) {
            // console.log(data.FromDate);
            if (data.fromDate.length == 0) {
                setfromDateError(true);
                return;
            }
            if (data.toDate.length == 0) {
                setToDateError(true);
                return;
            }
            let fromdate = new Date(data.fromDate);
            let todate = new Date(data.toDate);
            if (fromdate > todate) {
                setModal(true);
                seterror("To Date should be greather than from Date");
                return;
            }
            Body = {
                ...Body,
                fromDate: data.fromDate,
                toDate: data.toDate,
            };
        }
        if (
            data.Type != "ProductReports" &&
            data.Type != "SupplierPayments" &&
            data.Type != "Records" &&
            data.Type != "ApprovedUsers"
        ) {
            Body = {
                ...Body,
                warehouseId: data.warehouseId,
            };
        }
        if (data.Type == "CartReports") {
            Body = {
                ...Body,
                CartItems: Number(data?.CartItems),
            };
        }
        setloading(true);
        Services.allReportsDownload("POST", JSON.stringify(Body), token)
            .then((res) => {
                console.log("1", res);
                if (res.Status === 1) {
                    fetch(res.CsvFile).then((response) => {
                        response.blob().then((blob) => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `${data.Type}.xlsx`;
                            alink.click();
                            setloading(false);
                            reset();
                        });
                    });
                } else if (res.Status === 0) {
                    setloading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                setloading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <>
            {modal && <Modal setmodal={setModal} errorMessage={error} />}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="Reports" />
                </div>

                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses["Container-report"]}>
                        <div className={dashboardclasses.CategoryWrapper}>
                            <h3>Reports</h3>
                        </div>
                        {loading ? (
                            <div className={resolveClasses["modal"]}>
                                <div className={resolveClasses["spinner"]}>
                                    <FadeLoader color="#36d7b7" />
                                </div>
                            </div>
                        ) : (
                            <div
                                className={
                                    dashboardclasses["report-form-wraper"]
                                }>
                                <form
                                    className={dashboardclasses["edit-profile"]}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "edit-profile-formControl"
                                            ]
                                        }>
                                        <label htmlFor="Report Type">
                                            Report Type
                                        </label>
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-input"
                                                ]
                                            }>
                                            <select
                                                {...register("Type", {
                                                    required:
                                                        "Report Type is Required!",
                                                    onChange: (e) => {
                                                        setfromDateError(false);
                                                        setToDateError(false);
                                                        // reset({
                                                        //     keepErrors: false,
                                                        // });
                                                    },
                                                })}
                                                defaultValue="">
                                                <option value="" disabled>
                                                    Select type
                                                </option>
                                                {/* <option value="GSTreports">
                                                    GST Reports
                                                </option> */}
                                                <option value="CODreports">
                                                    COD Charges
                                                </option>
                                                <option value="DeliveryCharges">
                                                    Delivery Charges
                                                </option>
                                                <option value="SupplierPayments">
                                                    Supplier Payments
                                                </option>
                                                <option value="ProductSales">
                                                    Product Sales
                                                </option>
                                                <option value="ProductReports">
                                                    Product Reports
                                                </option>
                                                <option value="StockReports">
                                                    Stock Reports
                                                </option>
                                                <option value="ExpiredProducts">
                                                    Expired Products
                                                </option>
                                                <option value="UnExpiredProducts">
                                                    UnExpired Products
                                                </option>
                                                <option value="Records">
                                                    Records
                                                </option>
                                                <option value="ApprovedUsers">
                                                    Approved Users
                                                </option>
                                                <option value="TaxReports">
                                                    Tax Reports
                                                </option>
                                                <option value="Coupons">
                                                    Coupon Reports
                                                </option>
                                                <option value="Xbee_Order">
                                                    Xbee Order
                                                </option>
                                                <option value="CartReports">
                                                    Cart Reports
                                                </option>
                                            </select>
                                            {errors?.Type && (
                                                <p
                                                    className={
                                                        dashboardclasses[
                                                            "error"
                                                        ]
                                                    }>
                                                    {errors?.Type?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {watchFormData.Type == "Xbee_Order" && (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-formControl"
                                                ]
                                            }>
                                            <label htmlFor="Report Type">
                                                Status code
                                            </label>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-input"
                                                    ]
                                                }>
                                                <select
                                                    {...register("StatusCode", {
                                                        required:
                                                            "Status Code is Required!",
                                                    })}
                                                    defaultValue="">
                                                    <option value="" disabled>
                                                        Select Status Code
                                                    </option>
                                                    {OrderStatus.map(
                                                        (status) => (
                                                            <option
                                                                value={
                                                                    status.StatusCode
                                                                }>
                                                                {
                                                                    status.StatusCode
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                {errors?.StatusCode && (
                                                    <p
                                                        className={
                                                            dashboardclasses[
                                                                "error"
                                                            ]
                                                        }>
                                                        {
                                                            errors?.StatusCode
                                                                ?.message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {watchFormData.Type == "CartReports" && (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-formControl"
                                                ]
                                            }>
                                            <label htmlFor="Report Type">
                                                Cart Items
                                            </label>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-input"
                                                    ]
                                                }>
                                                <input
                                                    type="number"
                                                    {...register("CartItems", {
                                                        required:
                                                            "Cart Items is Required!",
                                                    })}
                                                />
                                                {errors?.CartItems && (
                                                    <p
                                                        className={
                                                            dashboardclasses[
                                                                "error"
                                                            ]
                                                        }>
                                                        {
                                                            errors?.CartItems
                                                                ?.message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {watchFormData.Type != "ProductReports" &&
                                        watchFormData.Type != "StockReports" &&
                                        watchFormData.Type != "Coupons" &&
                                        watchFormData.Type != "Records" &&
                                        watchFormData.Type != "ApprovedUsers" &&
                                        watchFormData.Type != "Coupons" &&
                                        watchFormData.Type !=
                                            "UnExpiredProducts" &&
                                        watchFormData.Type !=
                                            "ExpiredProducts" &&
                                        watchFormData.Type && (
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-formControl"
                                                    ]
                                                }>
                                                <label htmlFor="From Date">
                                                    From Date
                                                </label>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "edit-profile-input"
                                                        ]
                                                    }>
                                                    <input
                                                        type="date"
                                                        disabled={
                                                            watchFormData.Type ==
                                                                "ProductReports" ||
                                                            watchFormData.Type ==
                                                                "StockReports"
                                                        }
                                                        {...register(
                                                            "fromDate",
                                                            {
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.target
                                                                            .value
                                                                            .length >
                                                                        0
                                                                    ) {
                                                                        setfromDateError(
                                                                            false
                                                                        );
                                                                    }
                                                                },
                                                            }
                                                        )}
                                                    />
                                                    {FromDateError && (
                                                        <p
                                                            className={
                                                                dashboardclasses[
                                                                    "error"
                                                                ]
                                                            }>
                                                            FromDate is
                                                            Required!
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {watchFormData.Type != "ProductReports" &&
                                        watchFormData.Type != "StockReports" &&
                                        watchFormData.Type != "Records" &&
                                        watchFormData.Type != "ApprovedUsers" &&
                                        watchFormData.Type != "Coupons" &&
                                        watchFormData.Type !=
                                            "UnExpiredProducts" &&
                                        watchFormData.Type !=
                                            "ExpiredProducts" &&
                                        watchFormData.Type && (
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-formControl"
                                                    ]
                                                }>
                                                <label htmlFor="toDate">
                                                    To Date
                                                </label>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "edit-profile-input"
                                                        ]
                                                    }>
                                                    <input
                                                        type="date"
                                                        disabled={
                                                            watchFormData.Type ==
                                                                "ProductReports" ||
                                                            watchFormData.Type ==
                                                                "StockReports"
                                                        }
                                                        {...register("toDate", {
                                                            onChange: (e) => {
                                                                if (
                                                                    e.target
                                                                        .value
                                                                        .length >
                                                                    0
                                                                ) {
                                                                    setToDateError(
                                                                        false
                                                                    );
                                                                }
                                                            },
                                                        })}
                                                    />
                                                    {toDateError && (
                                                        <p
                                                            className={
                                                                dashboardclasses[
                                                                    "error"
                                                                ]
                                                            }>
                                                            To Date is Required!
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                    {watchFormData.Type != "SupplierPayments" &&
                                        watchFormData.Type != "Records" &&
                                        watchFormData.Type != "ApprovedUsers" &&
                                        watchFormData.Type != "Coupons" &&
                                        watchFormData.Type != "TaxReports" &&
                                        watchFormData.Type != "Xbee_Order" &&
                                        watchFormData.Type != "CartReports" &&
                                        watchFormData.Type !=
                                            "ProductReports" && (
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-formControl"
                                                    ]
                                                }>
                                                <label htmlFor="Report Type">
                                                    Warehouse
                                                </label>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "edit-profile-input"
                                                        ]
                                                    }>
                                                    <select
                                                        {...register(
                                                            "warehouseId",
                                                            {
                                                                required:
                                                                    "Warehouse is Required!",
                                                            }
                                                        )}
                                                        defaultValue="">
                                                        <option
                                                            value=""
                                                            disabled>
                                                            Select Warehouse
                                                        </option>
                                                        {warehouse.map(
                                                            (house) => (
                                                                <option
                                                                    value={
                                                                        house.warehouseId
                                                                    }>
                                                                    {
                                                                        house.warehouseName
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {errors?.warehouseId && (
                                                        <p
                                                            className={
                                                                dashboardclasses[
                                                                    "error"
                                                                ]
                                                            }>
                                                            {
                                                                errors
                                                                    ?.warehouseId
                                                                    ?.message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {watchFormData.Type == "ProductReports" && (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-formControl"
                                                ]
                                            }>
                                            <label htmlFor="Report Type">
                                                Status
                                            </label>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "edit-profile-input"
                                                    ]
                                                }>
                                                <select
                                                    {...register("status", {
                                                        required:
                                                            "status is Required!",
                                                    })}
                                                    defaultValue="">
                                                    <option value="" disabled>
                                                        Select status
                                                    </option>
                                                    <option value="1">
                                                        Active
                                                    </option>
                                                    <option value="2">
                                                        Inative
                                                    </option>
                                                    <option value="0">
                                                        Deleted
                                                    </option>
                                                </select>
                                                {errors?.status && (
                                                    <p
                                                        className={
                                                            dashboardclasses[
                                                                "error"
                                                            ]
                                                        }>
                                                        {
                                                            errors?.status
                                                                ?.message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <input type={"submit"} value="Download" />
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reports;
