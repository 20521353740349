import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (props.editable) {
            Services.SupplierDetails("GET", null, token, props.id)
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        reset({
                            ...res.Suppliers,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const fileTypes = ["JPEG", "PNG"];

    const onSubmit = (data) => {
        console.log(data);
        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            Services.addSupplier("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.SupplierUpdate("PUT", method, token, props.id)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update Supplier"
                                : "Add Supplier"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update Supplier"
                                : "Add Supplier"}{" "}
                            necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="code">Code</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("code", {
                                    required: "code is required",
                                })}
                            />

                            {errors?.code && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.code?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="SupplierName">Supplier Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("SupplierName", {
                                    required: "Supplier Name is required",
                                })}
                            />

                            {errors?.SupplierName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.SupplierName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="city">City</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("city", {
                                    required: "city is required",
                                })}
                            />

                            {errors?.city && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.city?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="phone">Phone</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                {...register("phone", {
                                    required: "phone is required",
                                })}
                            />

                            {errors?.phone && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.phone?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="emailId">Email</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"email"}
                                {...register("emailId", {
                                    required: "emailId is required",
                                })}
                            />

                            {errors?.emailId && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.phone?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="address1">Address1</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("address1", {
                                    required: "address1 is required",
                                })}
                            />

                            {errors?.address1 && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.address1?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="address2">Address2</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("address2", {
                                    required: "address2 is required",
                                })}
                            />

                            {errors?.address2 && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.address2?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="state">State</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("state", {
                                    required: "state is required",
                                })}
                            />

                            {errors?.state && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.state?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="pincode">Pincode</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                {...register("pincode", {
                                    required: "pincode is required",
                                })}
                            />

                            {errors?.pincode && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.pincode?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="GSTIN">GSTIN</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("GSTIN", {
                                    required: "GSTIN is required",
                                })}
                            />

                            {errors?.GSTIN && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.GSTIN?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="supplierType">Supplier Type</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("supplierType", {
                                    required: "supplier Type is required",
                                })}
                            />

                            {errors?.supplierType && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.supplierType?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    {props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="status">Status</label>
                            <div className={dashboardclasses["select"]}>
                                <select
                                    {...register("status", {
                                        required: "status Is required",
                                    })}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                                {errors?.status && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.status?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Supplier"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
