import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const priority = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [file, setFile] = useState("");
    const [base64, setbase64] = useState("");
    const [mobilefile, setmobileFile] = useState("");
    const [mobilebase64, setmobilebase64] = useState("");

    const [categoryItems, setCategoryItems] = useState([]);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    useEffect(() => {
        Services.getCategories("GET", null, token)
            .then((res) => {
                // console.log(res);
                if (res.Status === 1) {
                    setCategoryItems(res.categories);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        if (props.editable) {
            Services.getBannerbyId("GET", null, token, props.id)
                .then((res) => {
                    // console.log(res);
                    if (res?.Status === 1) {
                        //res.categories.imageUrl
                        setFile(res.banners.imageUrl);
                        setmobileFile(res.banners.imageUrl_User);

                        reset({
                            bannerName: res.banners.bannerName,
                            priority: res.banners.priority,
                            status: res.banners.status,
                            categoryId: res.banners.categoryId,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const handleChange = (file) => {
        if (file.type === "image/png" || file.type === "image/jpeg") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setbase64(reader.result);
                setFile(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } else {
            alert("please upload image in PNG and JPEG format ");
        }
    };
    const mobilehandleChange = (file) => {
        if (file.type === "image/png" || file.type === "image/jpeg") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setmobilebase64(reader.result);
                setmobileFile(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } else {
            alert("please upload image in PNG and JPEG format ");
        }
    };

    const fileuploadDesign = (
        <div className={dashboardclasses["add-item"]}>
            <BsCloudUpload className={dashboardclasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const onSubmit = (data) => {
        // console.log(data);
        let image;
        if (base64.length > 0) {
            image = { imageUrl: base64 };
        }
        if (mobilebase64.length > 0) {
            image = { ...image, imageUrl_User: mobilebase64 };
        }
        let method = JSON.stringify({
            ...data,
            ...image,
        });

        if (!props.editable && file.length !== 0 && mobilefile.length !== 0) {
            props.tableload(true);
            Services.AddBanners("POST", method, token)
                .then((res) => {
                    props.tableload(false);
                    if (res.Status === 1) {
                        Navigate(`/banners?page=${1}`);
                        props.setpageCount(1);
                        props.setmodal(false);
                        props.getCategories();

                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    props.tableload(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateBanner("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (file.length === 0) {
            alert("please upload icon");
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update Banner" : "Add Banner"}
                        </h3>
                        <p>
                            {props.editable ? "Update Banner" : "Add Banner"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="Bannername">Banner Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("bannerName", {
                                    required: "Banner Name is required",
                                })}
                            />

                            {errors?.bannerName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.bannerName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="image">Web Banner Image</label>

                        <FileUploader
                            classes={dashboardclasses["upload-image"]}
                            multiple={false}
                            handleChange={handleChange}
                            name="file"
                            children={fileuploadDesign}
                            // types={fileTypes}
                        />
                    </div>
                    <div className={dashboardclasses["uploaded-img"]}>
                        <img src={file} alt="" width={"120px"} />
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="image">Mobile Banner Image</label>

                        <FileUploader
                            classes={dashboardclasses["upload-image"]}
                            multiple={false}
                            handleChange={mobilehandleChange}
                            name="file"
                            children={fileuploadDesign}
                            // types={fileTypes}
                        />
                    </div>
                    <div className={dashboardclasses["uploaded-img"]}>
                        <img src={mobilefile} alt="" width={"120px"} />
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" priority">priority</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("priority", {
                                    required: "priority Is required",
                                })}>
                                <option value="" disabled>
                                    Select Priority
                                </option>
                                {priority.map((item) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                            {errors?.priority && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.priority?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" Category">Category</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("categoryId", {
                                    required: "Category Is required",
                                })}>
                                <option value="" disabled>
                                    Select category
                                </option>
                                {categoryItems.map((item) => (
                                    <option value={item.categoryId}>
                                        {item.categoryName}
                                    </option>
                                ))}
                            </select>
                            {errors?.categoryId && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.categoryId?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" status">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "status Is required",
                                })}>
                                <option value="" disabled>
                                    Select status
                                </option>
                                <option value={1}>active</option>
                                <option value={2}>inactive</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Banner"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
