import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Addpromocodes from "./addPayment";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
function Warehouse() {
    const Navigate = useNavigate();
    const fromDateref = useRef(null);

    const [params] = useSearchParams();
    const [tableLoad, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [supplier, setsuppliers] = useState([]);
    const toDateref = useRef(null);
    const [promocodes, setpromocodes] = useState(false);
    const [suppliersearch, setsuppliersearch] = useState("");
    const [editable, setEditable] = useState(false);
    const [promoCodeItems, setPromoCodeItems] = useState([]);
    const [productSearch, setproductSearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [promocodeid, setpromocodeid] = useState();
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getsuppliers = () => {
        Services.getSupplier("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setsuppliers(res.Suppliers);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getpromocodes = () => {
        Services.getSupplierPayments("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setPromoCodeItems(res.supplierPayments);
                    setproductSearch(res.supplierPayments);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getpromocodes();
        getsuppliers();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [productSearch]);
    const edithandler = (e) => {
        setpromocodeid(e.currentTarget.getAttribute("promoid"));
        setEditable(true);
        setpromocodes(true);
    };
    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.SupplierPaymentdelete(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.status === 1) {
                        getpromocodes();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const searchHandler = (e) => {
        setSearchTerm(e.target.value);
        let search = promoCodeItems.filter((item) =>
            String(item.supplierPaymentId).includes(String(e.target.value))
        );
        // console.log(search);
        if (fromDateref.current.value && toDateref.current.value) {
            let fromDate = new Date(fromDateref.current.value);
            let toDate = new Date(toDateref.current.value);
            search = search.filter((item) => {
                let date = new Date(item?.updatedAt);
                return fromDate <= date && date <= toDate;
            });
        }
        setTableLoad(true);
        Navigate(`/supplierpayments?page=${1}`);
        setpageCount(1);
        setproductSearch(search);
    };
    console.log(promoCodeItems);
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button
                    onClick={edithandler}
                    promoid={cellcontent.supplierPaymentId}>
                    <FaRegEdit size={18} />
                </button>
                <button
                    onClick={deleteHandler}
                    id={cellcontent.supplierPaymentId}>
                    <MdDelete size={18} />
                </button>
            </div>
        );
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);
        const datestring = `${String(date.getDate()).padStart(2, "0")}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}-${date.getFullYear()}`;
        return (
            <div>
                <p style={{ margin: 0 }}>{datestring}</p>
            </div>
        );
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const columns = [
        {
            dataField: "SupplierName",
            text: "SUPPLIER NAME",
            sort: false,
            // formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionId",
            text: "TRANSACTION ID",
            sort: false,
            // formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "updatedAt",
            text: "TRANSACTION DATE",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "supplierPaymentId",
            text: "SUPPLIER PAYMENT ID",
            sort: false,
            // formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "invoiceNumber",
            text: "INVOICE NUMBER",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "chequeNumber",
            text: "CHEQUE NUMBER",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "amount",
            text: "AMOUNT",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/supplierpayments?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            // console.log("page", page);
            // console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const submitHandler = (e) => {
        e.preventDefault();
        let fromDate = new Date(fromDateref.current.value).setHours(0, 0, 0, 0);
        let toDate = new Date(toDateref.current.value).setHours(0, 0, 0, 0);

        if (!fromDateref.current.value) {
            alert("please enter from date");
            return;
        } else if (!toDateref.current.value) {
            alert("please enter to Date");
            return;
        } else if (fromDate > toDate) {
            alert("from Date should be less than to Date");
            return;
        } else {
            let filteredSupplier = promoCodeItems.filter((item) => {
                let date = new Date(item?.updatedAt).setHours(0, 0, 0, 0);
                return fromDate <= date && date <= toDate;
            });
            if (searchTerm.length > 0) {
                filteredSupplier = filteredSupplier.filter((item) =>
                    String(item.supplierPaymentId).includes(String(searchTerm))
                );
            }
            if (suppliersearch.length > 0) {
                filteredSupplier = filteredSupplier.filter(
                    (item) => item.supplierID == suppliersearch
                );
            }
            setproductSearch(filteredSupplier);

            Navigate("/supplierpayments?page=1");
            setpageCount(1);
            setTableLoad(true);
        }
    };
    const supplierhandler = (e) => {
        setsuppliersearch(e.target.value);
        let supplierfilteritems = promoCodeItems.filter(
            (item) => item.supplierID == e.target.value
        );
        if (
            fromDateref.current.value.length > 0 &&
            toDateref.current.value.length > 0
        ) {
            let fromDate = new Date(fromDateref.current.value).setHours(
                0,
                0,
                0,
                0
            );
            let toDate = new Date(toDateref.current.value).setHours(0, 0, 0, 0);
            supplierfilteritems = supplierfilteritems.filter((item) => {
                let date = new Date(item?.updatedAt).setHours(0, 0, 0, 0);
                return fromDate <= date && date <= toDate;
            });
        }
        setproductSearch(supplierfilteritems);
    };
    return (
        <>
            {promocodes && (
                <Addpromocodes
                    setmodal={setpromocodes}
                    editable={editable}
                    setEditable={setEditable}
                    getpromocodes={getpromocodes}
                    promocodeid={promocodeid}
                    setpageCount={setpageCount}
                    tableload={setTableLoad}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="supplierpayments" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Supplier Payments</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form-two"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Payment Id"
                                        onChange={searchHandler}
                                    />
                                    {/* <div></div> */}
                                    <button
                                        onClick={() => {
                                            setpromocodes(true);
                                        }}>
                                        + Add Supplier Payment
                                    </button>
                                    <div
                                        className={
                                            dashboardclasses["supplier-filter"]
                                        }>
                                        <select
                                            defaultValue=""
                                            onChange={supplierhandler}>
                                            <option value="" disabled>
                                                Select Supplier
                                            </option>
                                            {supplier.map((item) => (
                                                <option value={item.supplierId}>
                                                    {item.SupplierName}
                                                </option>
                                            ))}
                                        </select>
                                        <form
                                            onSubmit={submitHandler}
                                            className={
                                                dashboardclasses[
                                                    "supplier-payment-filters"
                                                ]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "form-control-add"
                                                    ]
                                                }>
                                                <label htmlFor="fromDate">
                                                    From Date
                                                </label>
                                                <input
                                                    type={"date"}
                                                    ref={fromDateref}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "form-control-add"
                                                    ]
                                                }>
                                                <label htmlFor="MRP">
                                                    to Date
                                                </label>
                                                <input
                                                    type={"date"}
                                                    ref={toDateref}
                                                />
                                            </div>
                                            <button type="submit">
                                                Filter
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={productSearch}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
