import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import { GrDocumentPdf } from "react-icons/gr";
import { BsCardImage } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from "react-moment";
import { GrFormView } from "react-icons/gr";
import Updatecustomers from "./updateCustomers";
// import Moment from "react-moment";
function Warehouse() {
    const Navigate = useNavigate();
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };

    const [category, setcategory] = useState(false);
    const [editable, setEditable] = useState(false);
    const [userOrder, setUserOrder] = useState([]);
    // const [userOrderFilter, setUserOrderFilter] = useState([]);
    const [Navactive, setNavactive] = useState("1");
    function isImage(url) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    const { id } = useParams();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    let [orderLoading, setOrderLOading] = useState(true);
    const [user, setUser] = useState([]);
    const getusers = () => {
        Services.Getuserbyid("GET", null, token, id)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setUser(res.User);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers();
        // eslint-disable-next-line
    }, []);
    const getOrders = () => {
        console.log("111");
        Services.orderByUserId("GET", null, token, id)
            .then((res) => {
                setOrderLOading(false);
                console.log(res);
                if (res.Status === 1) {
                    setUserOrder(res.Orders);
                    // setUserOrderFilter(res.Orders);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const Idformatter = (cell, cellcontent, index, formatExtraData) => {
        return (
            <div>
                <p className={dashboardclasses["idformatter"]}>{cell}</p>
            </div>
        );
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);
        return <Moment format="MMMM Do YYYY, hh:mm a">{date}</Moment>;
    };

    const priceFormatter = (cell) => {
        return (
            <div className={dashboardclasses["price-wrapper"]}>
                <p>₹ {cell}</p>
            </div>
        );
    };
    const statusFormatter = (cell) => {
        if (cell === 1) {
            return (
                <div className={dashboardclasses[`delivered-wrapper`]}>
                    <p className={dashboardclasses["delivered"]}>Delivered</p>
                </div>
            );
        }
        if (cell === 2) {
            return (
                <div className={dashboardclasses[`pending-wrapper`]}>
                    <p className={dashboardclasses["pending"]}>pending</p>
                </div>
            );
        }
        if (cell === 3) {
            return (
                <div className={dashboardclasses[`delivered-wrapper`]}>
                    <p className={dashboardclasses["delivered"]}>
                        Out for Delivery
                    </p>
                </div>
            );
        }
        if (cell === 4) {
            return (
                <div className={dashboardclasses[`delivered-wrapper`]}>
                    <p className={dashboardclasses["delivered"]}>
                        Order shipped
                    </p>
                </div>
            );
        }
        if (cell === 7 || cell === 5) {
            return (
                <div className={dashboardclasses[`cancel-wrapper`]}>
                    <p className={dashboardclasses["cancel"]}>cancelled </p>
                </div>
            );
        }
        if (cell === 6) {
            return (
                <div className={dashboardclasses[`cancel-wrapper`]}>
                    <p className={dashboardclasses["cancel"]}>Returned </p>
                </div>
            );
        }
    };

    const navactivehandler = (e) => {
        let status = e.currentTarget.getAttribute("id");
        setNavactive(status);
        if (status === String(2)) {
            getOrders();
        }
    };
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.view}>
                <button
                    onClick={viewOrderDetails}
                    orderid={cellcontent.orderId}>
                    <GrFormView
                        size={22}
                        color="grey"
                        id={cellcontent.userId}
                    />
                    {/* View */}
                </button>
            </div>
        );
    };
    const columns = [
        {
            dataField: "orderId",
            text: "ORDER ID",
            sort: false,
            // cell: (row, index) => index + 1,
            formatter: Idformatter,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        },
        {
            dataField: "name",
            text: "CUSTOMER NAME",
            sort: false,
            // cell: (row, index) => index + 1,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        },
        {
            dataField: "contactNumber",
            text: "MOBILE NUMBER",
            sort: false,
            // formatter: priceFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "createdAt",
            text: "ORDER DATE TIME",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        // {
        //     dataField: "paymentType",
        //     text: "METHOD",
        //     formatter: methodformatter,
        //     sort: false,
        //     headerStyle: (colum, colIndex) => {
        //         return { ...headerstyles };
        //     },
        // },

        {
            dataField: "grandTotal",
            text: "AMOUNT",
            sort: false,
            formatter: priceFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "orderStatus",
            text: "STATUS",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "ACTIONS",
            text: "ORDER DETAILS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
    ];

    const viewOrderDetails = (e) => {
        const id = e.currentTarget.getAttribute("orderid");
        Navigate(`/orders/${id}`);
    };

    return (
        <>
            {category && (
                <Updatecustomers
                    setmodal={setcategory}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    user={user}
                    getCategories={getusers}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="customers" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <button
                            className={dashboardclasses["backbutton"]}
                            onClick={() => {
                                Navigate(-1);
                            }}>
                            Back
                        </button>
                        <div className={dashboardclasses["add-product"]}>
                            <h3>Customer Details</h3>
                            <button
                                onClick={() => {
                                    setcategory(true);
                                    setEditable(true);
                                }}>
                                Edit
                            </button>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["user-details-nav"]
                                    }>
                                    <button
                                        id="1"
                                        className={
                                            Navactive === "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        customer Details
                                    </button>
                                    <button
                                        id="2"
                                        className={
                                            Navactive === "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Orders
                                    </button>
                                </div>
                                {Navactive === String(1) ? (
                                    <div
                                        className={
                                            dashboardclasses[
                                                "users-details-wrapper"
                                            ]
                                        }>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Name</h4>
                                            <h5>:</h5>
                                            <p>{user.ownerName}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Type</h4>
                                            <h5>:</h5>
                                            <p>{user.userType}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Mobile Number</h4>
                                            <h5>:</h5>
                                            <p>{user.mobileNumber}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Email ID</h4>
                                            <h5>:</h5>
                                            <p>{user.emailId}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Shop/Clinic Name</h4>
                                            <h5>:</h5>
                                            <p>{user.shopName}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Address</h4>
                                            <h5>:</h5>
                                            <p>{user.address}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>City</h4>
                                            <h5>:</h5>
                                            <p>{user.city}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>State</h4>
                                            <h5>:</h5>
                                            <p>{user.state}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Pincode</h4>
                                            <h5>:</h5>
                                            <p>{user.pincode}</p>
                                        </div>
                                        {user.userType === "DOCTOR" && (
                                            <>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "user-details"
                                                        ]
                                                    }>
                                                    <h4>
                                                        MCI Registartion Number
                                                    </h4>
                                                    <h5>:</h5>
                                                    <p>
                                                        {
                                                            user.mci_registrationNo
                                                        }
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "user-details"
                                                        ]
                                                    }>
                                                    <h4>Doctor Letter Head </h4>
                                                    <h5>:</h5>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "file-imgs"
                                                            ]
                                                        }>
                                                        {user.doc_letterheadURL
                                                            .length > 0 ? (
                                                            user.doc_letterheadURL.map(
                                                                (item) => {
                                                                    const image =
                                                                        isImage(
                                                                            item
                                                                        );
                                                                    return (
                                                                        <a
                                                                            href={
                                                                                item
                                                                            }
                                                                            rel="noreferrer"
                                                                            target={
                                                                                "_blank"
                                                                            }>
                                                                            {!image ? (
                                                                                <GrDocumentPdf />
                                                                            ) : (
                                                                                <BsCardImage />
                                                                            )}
                                                                        </a>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <p>
                                                                Files Not
                                                                Uploaded by User
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {user.userType === "CHEMIST" && (
                                            <>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "user-details"
                                                        ]
                                                    }>
                                                    <h4>Drug License Number</h4>
                                                    <h5>:</h5>
                                                    <p>{user.drugLicenseNo}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "user-details"
                                                        ]
                                                    }>
                                                    <h4> Drug License URL </h4>
                                                    <h5>:</h5>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "file-imgs"
                                                            ]
                                                        }>
                                                        {user.drugLicenseURL
                                                            .length > 0 ? (
                                                            user.drugLicenseURL.map(
                                                                (item) => {
                                                                    const image =
                                                                        isImage(
                                                                            item
                                                                        );
                                                                    return (
                                                                        <a
                                                                            href={
                                                                                item
                                                                            }
                                                                            rel="noreferrer"
                                                                            target={
                                                                                "_blank"
                                                                            }>
                                                                            {!image ? (
                                                                                <GrDocumentPdf />
                                                                            ) : (
                                                                                <BsCardImage />
                                                                            )}
                                                                        </a>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <p>
                                                                Files Not
                                                                Uploaded by User
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>GST Number</h4>
                                            <h5>:</h5>
                                            <p>{user.GSTNo}</p>
                                        </div>

                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>GST File</h4>
                                            <h5>:</h5>

                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "file-imgs"
                                                    ]
                                                }>
                                                {user.GSTFileURL.length > 0 ? (
                                                    //GSTFileURL
                                                    user.GSTFileURL.map(
                                                        (item) => {
                                                            const image =
                                                                isImage(item);
                                                            return (
                                                                <a
                                                                    href={item}
                                                                    rel="noreferrer"
                                                                    target={
                                                                        "_blank"
                                                                    }>
                                                                    {!image ? (
                                                                        <GrDocumentPdf />
                                                                    ) : (
                                                                        <BsCardImage />
                                                                    )}
                                                                </a>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <p>
                                                        Files Not Uploaded by
                                                        User
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Referral Code</h4>
                                            <h5>:</h5>
                                            <p>{user.referralCode}</p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>ReferredByCode</h4>
                                            <h5>:</h5>
                                            <p>{user.referredByCode}</p>
                                        </div>
                                        {user.userStatus == 1 && (
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "user-details"
                                                    ]
                                                }>
                                                <h4>Approve Comments</h4>
                                                <h5>:</h5>
                                                <p>
                                                    {user.approve_rejectMessage}
                                                </p>
                                            </div>
                                        )}
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>Created At </h4>
                                            <h5>:</h5>
                                            <p>
                                                {String(
                                                    new Date(
                                                        user.createdAt
                                                    ).getDate()
                                                ).padStart(2, "0")}
                                                -
                                                {String(
                                                    new Date(
                                                        user.createdAt
                                                    ).getMonth() + 1
                                                ).padStart(2, "0")}
                                                -
                                                {new Date(
                                                    user.createdAt
                                                ).getFullYear()}{" "}
                                                {/* {new Date(
                                                    user.createdAt
                                                ).getHours()}
                                                :
                                                {String(
                                                    new Date(
                                                        user.createdAt
                                                    ).getMinutes()
                                                ).padStart(2, "0")} */}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["user-details"]
                                            }>
                                            <h4>expiry Date</h4>
                                            <h5>:</h5>
                                            {user.rec_expiryDate
                                                .toString()
                                                .includes("0000-00-00") ? (
                                                <p>Na</p>
                                            ) : (
                                                <p>
                                                    {String(
                                                        new Date(
                                                            user.rec_expiryDate
                                                        ).getDate()
                                                    ).padStart(2, "0")}
                                                    -
                                                    {String(
                                                        new Date(
                                                            user.rec_expiryDate
                                                        ).getMonth() + 1
                                                    ).padStart(2, "0")}
                                                    -
                                                    {new Date(
                                                        user.rec_expiryDate
                                                    ).getFullYear()}{" "}
                                                    {/* {new Date(
                                                        user.expiryDate
                                                    ).getHours()}
                                                    :
                                                    {String(
                                                        new Date(
                                                            user.expiryDate
                                                        ).getMinutes()
                                                    ).padStart(2, "0")} */}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {orderLoading ? (
                                            <div
                                                className={
                                                    dashboardclasses["loader"]
                                                }>
                                                <ScaleLoader
                                                    color={"black"}
                                                    loading={true}
                                                    css={override}
                                                    size={150}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className="category-table"
                                                    style={{
                                                        background: "white",
                                                    }}>
                                                    <BootstrapTable
                                                        bootstrap4
                                                        keyField="id"
                                                        data={userOrder}
                                                        columns={columns}
                                                        defaultSorted={
                                                            defaultSorted
                                                        }
                                                        pagination={pagination}
                                                        wrapperClasses="table-responsive"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
