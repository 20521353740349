import React, { useEffect } from "react";
import poclasses from "./po.module.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
function ProductDetails(props) {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const selectHandler = (e) => {
        const supplierId = e.currentTarget.getAttribute("supplierId");
        const selectedsupplier = props.supplier.filter(
            (suppliers) => suppliers.supplierId == supplierId
        );
        props.setselectedSupplier(selectedsupplier[0]);
        props.setmodal(false);
        props.setsupplierInput(selectedsupplier[0].SupplierName);
        Services.getInvoicebyId("GET", null, token, supplierId)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    props.setSupplierInvoices(res.SupplierInvoice);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <div className={poclasses["modal"]} onClick={CancelHandler}>
            <div
                className={poclasses["modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <div className={poclasses["product-table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Supplier Name</th>
                                <th>City</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>State</th>
                                <th>GSTIN</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.supplier?.length > 0 ? (
                                props?.supplier?.map((item) => {
                                    return (
                                        <tr
                                            onClick={selectHandler}
                                            supplierId={item.supplierId}
                                            key={item.supplierId}>
                                            <td>{item?.SupplierName}</td>
                                            <td>{item?.city}</td>
                                            <td>{item?.phone}</td>
                                            <td>{item?.emailId}</td>
                                            <td>{item?.address1}</td>
                                            <td>{item?.state}</td>
                                            <td>{item?.GSTIN}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{ textAlign: "center" }}>
                                        No Supplier Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* productDetails */}
        </div>
    );
}

export default ProductDetails;
