import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Addusers from "./addAdminusers";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
function Warehouse() {
    const Navigate = useNavigate();
    const [addUser, setAddUser] = useState(false);
    const [id, setid] = useState();
    const [params] = useSearchParams();
    const [tableLoad, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [editable, setEditable] = useState(false);
    const [adminusers, setAdminUsers] = useState([]);
    const [searchTerm, setsearchTerm] = useState("");
    const [warehouseTerm, setwarehouseTerm] = useState("");
    const [searchAdminUsers, setSearchAdminUsers] = useState([]);
    const [warehousedata, setWarehouses] = useState([]);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const categoryHandler = () => {
        setAddUser((prev) => !prev);
    };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getAdminusers = () => {
        Services.getAdminusers("GET", null, token)
            .then((res) => {
                if (res.Status === 1) {
                    setAdminUsers(res.adminUsers);
                    setSearchAdminUsers(res.adminUsers);
                    Services.getwarehouses("GET", null, token).then(
                        (response) => {
                            if (response.Status === 1) {
                                setWarehouses(response.warehouses);
                                setLoading(false);
                            } else if (response.Status === 0) {
                                setLoading(false);
                                toast.error(`${res.Message}`, {
                                    position: "top-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                if (res.Message === "Token expired") {
                                    Navigate("/");
                                }
                            }
                        }
                    );
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAdminusers();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [searchAdminUsers]);
    const edithandler = (e) => {
        setid(e.currentTarget.getAttribute("id"));
        setEditable(true);
        setAddUser(true);
    };
    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.deleteAdmindata(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        getAdminusers();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button onClick={edithandler} id={cellcontent.adminUserId}>
                    <FaRegEdit size={18} />
                </button>
                <button onClick={deleteHandler} id={cellcontent.adminUserId}>
                    <MdDelete size={18} />
                </button>
            </div>
        );
    };
    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
        );
    };
    const wareHouseFormatter = (cell) => {
        const warehousename = warehousedata.filter(
            (item) => item.warehouseId === cell
        );
        // console.log(warehousedata);
        // console.log(warehousename);
        return (
            <p>
                {warehousename?.length > 0
                    ? warehousename[0]?.warehouseName
                    : "Not Assigned"}
            </p>
        );
    };
    const columns = [
        {
            dataField: "firstName",
            text: "NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "emailId",
            text: "EMAIL",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "warehouseId",
            text: "WareHouse",
            sort: false,
            formatter: wareHouseFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "roleName",
            text: "ROLE",
            sort: false,
            // formatter: iconformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "status",
            text: "STATUS",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant", // Optional if you want to skip the scrolling animation
            });
            Navigate(`/users?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {},
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const warehousefilter = (e) => {
        //searchAdminUsers
        setwarehouseTerm(e.target.value);
        let search = adminusers.filter(
            (item) => item.warehouseId === Number(e.target.value)
        );
        if (searchTerm) {
            search = search.filter((item) =>
                String(item.firstName.toLowerCase()).includes(
                    searchTerm.toLowerCase()
                )
            );
        }
        setSearchAdminUsers(search);
        setTableLoad(true);
        Navigate(`/users?page=${1}`);
        setpageCount(1);
    };
    const namefilter = (e) => {
        setsearchTerm(e.target.value);
        let search = adminusers.filter((item) =>
            String(item.firstName.toLowerCase()).includes(
                e.target.value.toLowerCase()
            )
        );
        if (warehouseTerm) {
            search = search.filter(
                (item) => item.warehouseId === Number(warehouseTerm)
            );
        }
        setSearchAdminUsers(search);
        setTableLoad(true);
        Navigate(`/users?page=${1}`);
        setpageCount(1);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {addUser && (
                <Addusers
                    setmodal={setAddUser}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    getAdminusers={getAdminusers}
                    setpageCount={setpageCount}
                    tableload={setTableLoad}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="user" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Admin users</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by name"
                                        onChange={namefilter}
                                    />

                                    <select
                                        defaultValue=""
                                        onChange={warehousefilter}>
                                        <option value="" disabled>
                                            warehouse
                                        </option>
                                        <option value="0">Not Assigned</option>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={categoryHandler}>
                                        + Add User
                                    </button>
                                </div>
                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchAdminUsers}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
