import create from "zustand";

const initialState = {
    showNavbar: true,
    showSeo: false,
    showMedbuzz: false,
    adminData: {},
};
const useStore = create((set) => ({
    ...initialState,
    setshowNavbar: (auth) =>
        set((state) => ({ ...state, showNavbar: !state.showNavbar })),
    setshowNSeo: (auth) =>
        set((state) => ({ ...state, showSeo: !state.showSeo })),
    setshowMedbuzz: (auth) =>
        set((state) => ({ ...state, showMedbuzz: !state.showMedbuzz })),
    setAdminData: (data) => set((state) => ({ ...state, adminData: data })),
}));
export default useStore;
