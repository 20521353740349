import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [catTypes, setCatTypes] = useState([]);
    const [categoryType, setCategoryType] = useState({
        categoryType: null,
        categoryTypeName: null,
    });
    const [getparams] = useSearchParams();
    let type = getparams.get("type");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const priority = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [file, setFile] = useState("");
    const [base64, setbase64] = useState("");

    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    useEffect(() => {
        Services.getCategoryTypes("GET", null, token)
            .then((res) => {
                if (res?.Status === 1) {
                    //res.categories.imageUrl
                    console.log(res);
                    setCatTypes(res.categoryTypes);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        if (props.editable) {
            Services.getCategoriesbyId("GET", null, token, props.id)
                .then((res) => {
                    if (res?.Status === 1) {
                        //res.categories.imageUrl
                        setFile(res.categories.imageUrl);

                        setCategoryType({
                            categoryType: res.categories.categoryType,
                            categoryTypeName: res.categories.categoryTypeName,
                        });
                        reset({
                            categoryName: res.categories.categoryName,
                            View_In_Home: res.categories.View_In_Home,
                            priority: res.categories.priority,
                            status: res.categories.status,
                            metaTitle: res.categories.metaTitle,
                            metaDescription: res.categories.metaDescription,
                            metaKeywords: res.categories.metaKeywords,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const fileTypes = ["JPEG", "PNG"];
    const handleChange = (file) => {
        // console.log(file.size);
        const size = file.size / 2097152;
        if (size > 2) {
            alert("image size should be less than 2MB");
        }
        if (file.type === "image/png" || file.type === "image/jpeg") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setbase64(reader.result);
                setFile(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } else {
            alert("please upload image in PNG and JPEG format ");
        }
    };

    const fileuploadDesign = (
        <div className={dashboardclasses["add-item"]}>
            <BsCloudUpload className={dashboardclasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const onSubmit = (data) => {
        let image;
        if (base64.length > 0) {
            image = { imageUrl: base64 };
        }
        let method = {
            ...data,
            ...image,
        };
        if (categoryType.categoryType !== null) {
            method = {
                ...method,
                ...categoryType,
            };
        }
        console.log(method);

        if (!props.editable && file.length !== 0) {
            props.tableload(true);
            Services.AddCategories("POST", JSON.stringify(method), token)
                .then((res) => {
                    if (res.Status === 1) {
                        Navigate(`/categories?page=${1}`);
                        setCategoryType(null);
                        props.setpageCount(1);
                        props.getCategories();
                        props.setmodal(false);
                        props.tableload(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        props.tableload(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    props.tableload(false);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updatecategories(
                "PUT",
                JSON.stringify(method),
                token,
                props.id
            )
                .then((res) => {
                    if (res.Status === 1) {
                        setCategoryType(null);
                        props.setmodal(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (file.length === 0) {
            alert("please upload icon");
        }
    };
    const categoryTypechange = (e) => {
        let type = e.target.value;
        let [selected] = catTypes.filter((item) => item.categoryType == type);
        setCategoryType(selected);
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update Category"
                                : "Add Category"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update Category"
                                : "Add Category"}{" "}
                            your Product category and necessary information from
                            here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    {!type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="categoryName">
                                    Category Name
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("categoryName", {
                                            required:
                                                "category Name is required",
                                        })}
                                    />

                                    {errors?.categoryName && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.categoryName?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="category">Category Icon</label>

                                <FileUploader
                                    classes={dashboardclasses["upload-image"]}
                                    multiple={false}
                                    handleChange={handleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                            <div className={dashboardclasses["uploaded-img"]}>
                                <img src={file} alt="" width={"120px"} />
                            </div>

                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor=" priority">Priority</label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        {...register("priority", {
                                            required: "priority is required",
                                        })}>
                                        <option value="" disabled>
                                            Select Priority
                                        </option>
                                        {priority.map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {errors?.priority && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.priority?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor=" Category Type">
                                    Category Type
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        onChange={categoryTypechange}
                                        value={categoryType?.categoryType}>
                                        <option value="" disabled>
                                            Select Type
                                        </option>
                                        {catTypes?.map((item) => (
                                            <option value={item.categoryType}>
                                                {item.categoryTypeName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="status">View in Home</label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        {...register("View_In_Home", {
                                            required: "this is required",
                                        })}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    {errors?.View_In_Home && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.View_In_Home?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {props.editable && (
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="status">Status</label>
                                    <div className={dashboardclasses["select"]}>
                                        <select
                                            {...register("status", {
                                                required: "status Is required",
                                            })}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        {errors?.status && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.status?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaTitle">metaTitle</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaTitle", {
                                            required: "metaTitle  is required",
                                        })}
                                    />

                                    {errors?.metaTitle && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaTitle?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaDescription">
                                    metaDescription
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <textarea
                                        rows="5"
                                        {...register(
                                            "metaDescription"
                                        )}></textarea>

                                    {errors?.metaDescription && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaDescription?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaKeywords">
                                    metaKeywords
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaKeywords", {
                                            required:
                                                "metaKeywords  is required",
                                        })}
                                    />

                                    {errors?.metaKeywords && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaKeywords?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Category"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
