import React, { useState, useRef, useEffect, useContext } from "react";
import TopNav from "../../Navbar/topNav";
import poclasses from "./po.module.css";
import Moment from "react-moment";
import SupplierDetails from "./supplierDetails";
import { Services } from "../../../Services";
import {
    useNavigate,
    useParams,
    UNSAFE_NavigationContext,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ProductDetails from "./ProductDetails";
import Batchinfo from "./BatchInfo";
import Addbatchinfo from "./AddBatch";
import { MdDelete } from "react-icons/md";
import FadeLoader from "react-spinners/FadeLoader";
import { AiFillEdit } from "react-icons/ai";
import { useBackListener } from "./custom";
function AddpurchaseOrder() {
    const { id } = useParams();
    let [count, setcount] = useState(1);
    const invoicedateref = useRef();
    const expiryref = useRef();
    const invoicenumberef = useRef();
    const invoiceamountref = useRef();
    const addedbyref = useRef();
    const productnameref = useRef();
    const unitsref = useRef();
    const Navigate = useNavigate();
    const [showaddbatch, setshowaddbatch] = useState(false);
    const [Freight, setFreight] = useState(0);
    const [roundOff, setroundOff] = useState(0);
    const [warehouse, setWareHouse] = useState([]);
    const [warehouseid, setWareHouseid] = useState([]);
    const [supplierinput, setsupplierInput] = useState("");
    const [showSupplier, setshowSupplier] = useState(false);
    const [supplier, setsupplier] = useState([]);
    const [selectedSupplier, setselectedSupplier] = useState({});
    const [supplierInvoices, setSupplierInvoices] = useState([]);
    const [allbatchinfo, setallbatchinfo] = useState([]);
    const [showProduct, setShowProduct] = useState(false);
    const [products, setproducts] = useState([]);
    const [selectedProducts, setselectedProducts] = useState();
    const admindata = JSON.parse(localStorage.getItem("admindata"));
    const [showbatch, setShowBatch] = useState();
    const [batchDetails, setBatchDetails] = useState();
    const [selectedBatch, setSelectedBach] = useState(undefined);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const navigation = useContext(UNSAFE_NavigationContext).navigator;
    const [calculations, setcalculations] = useState({
        grossAmount: 0,
        totalGST: 0,
        discountAmount: 0,
        marginamount: 0,
        net: 0,
        // marginPercentage: 0,
    });
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const [allproducts, setAllProducts] = useState([]);
    const [addload, setaddLoad] = useState(false);
    const supplierChangeHandler = (e) => {
        setsupplierInput(e.target.value);
    };
    useEffect(() => {
        if (!id) {
            let unlisten = navigation.listen((locationListener) => {
                if (locationListener.action === "POP") {
                    if (
                        window.confirm(
                            "are You sure you want to leave this page?"
                        )
                    ) {
                        Navigate("/purchaseorder?page=1");
                    } else {
                        Navigate("/AddpurchaseOrder");
                    }
                }
            });
            return () => {
                unlisten();
            };
        }
    }, []);
    useEffect(() => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setWareHouse(response.warehouses);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (!id) {
            let grossAmount = allbatchinfo.reduce(
                (prev, curr) =>
                    prev + Number(curr.purchasePrice) * Number(curr.quantity),
                0
            );
            //gstPercentage
            let gstAmount = allbatchinfo.reduce(
                (prev, curr) => prev + Number(curr.gstAmount),
                0
            );
            let discount = allbatchinfo.reduce((prev, curr) => {
                let totalcost = curr.purchasePrice * curr.quantity;
                return prev + (Number(curr.disc) / 100) * totalcost;
            }, 0);

            setcalculations({
                grossAmount: Number(grossAmount) + Number(gstAmount),
                totalGST: Number(gstAmount).toFixed(2),
                discountAmount: Number(discount),

                net:
                    Number(grossAmount) -
                    Number(discount) +
                    Number(gstAmount) +
                    Number(roundOff) +
                    Number(Freight),
            });
        }
    }, [allbatchinfo, roundOff, Freight]);
    const roundoffChange = (e) => {
        setroundOff(e.target.value);
    };
    useEffect(() => {
        if (id) {
            Services.getpoentryDetails("GET", null, token, id)
                .then((res) => {
                    if (res.Status === 1) {
                        setAllProducts(res.POentries.POitems);
                        Services.SupplierDetails(
                            "GET",
                            null,
                            token,
                            res.POentries.supplierId
                        )
                            .then((res) => {
                                if (res.Status === 1) {
                                    // setshowSupplier(true);

                                    setselectedSupplier(res.Suppliers);
                                } else if (res.Status === 0) {
                                    toast.error(`${res.Message}`, {
                                        position: "top-center",
                                        autoClose: 1000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    if (res.Message === "Token expired") {
                                        Navigate("/");
                                    }
                                }
                            })
                            .catch((err) => {
                                // alert(err);
                                alert("something went wrong please try again");
                                console.log(err);
                            });

                        invoicedateref.current.value = new Date(
                            res.POentries.invoiceDate
                        )
                            ?.toISOString()
                            ?.substring(0, 10);
                        invoicenumberef.current.value =
                            res.POentries.invoiceNumber;
                        invoiceamountref.current.value =
                            res.POentries.invoiceAmount;
                        setFreight(res.POentries.freightCharges);
                        setroundOff(res.POentries.roundoffCharges);

                        addedbyref.current.value = res.POentries.addedBy;
                        setsupplierInput(res.POentries.supplierName);
                        setcalculations({
                            grossAmount: res.POentries.grossAmount,
                            totalGST: res.POentries.totalGST,
                            discountAmount: res.POentries.discountAmount,

                            net: res.POentries.netAmount,
                        });
                        //POentries
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    }, [id]);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    const supplierSearchHandler = (e) => {
        // e.preventDefault();
        const keyCode = e.key;
        if (keyCode === "Enter") {
            Services.SupplierSearch("GET", null, token, e.target.value)
                .then((res) => {
                    if (res.Status === 1) {
                        setshowSupplier(true);
                        setsupplier(res.Suppliers);
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const productsearchHandler = (e) => {
        const keyCode = e.key;
        if (keyCode === "Enter") {
            reset({
                batchNumber: "",
                productName: "",
                units: "",
                quantity: "",
                purchasePrice: "",
                gstPercentage: "",
                gstAmount: "",
                sellingPrice: "",
                mrp: "",
                expiry: "",
            });
            let productbody = JSON.stringify({ productName: e.target.value });
            Services.ProductSearchpOST("POST", productbody, token)
                .then((res) => {
                    if (res.Status === 1) {
                        let filterData = res.Products?.filter(
                            (item) => item.status == 1
                        );
                        setproducts(filterData);
                        setShowProduct(true);
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const onSubmit = (formdetails) => {
        if (formdetails.batchNumber.length === 0) {
            alert("Batch Number is required");
            return;
        } else if (formdetails.quantity.length === 0) {
            alert(" quantity is required");
            return;
        } else if (formdetails.purchasePrice.length === 0) {
            alert(" purchase price is required");
            return;
        } else if (formdetails.gstPercentage.length === 0) {
            alert(" GST percentage is required");
            return;
        } else if (formdetails.gstAmount?.length === 0) {
            alert(" gst Amount is required");
            return;
        } else if (formdetails.disc.length === 0) {
            alert(" disc is required");
            return;
        } else if (formdetails.freeQty.length === 0) {
            alert(" Free is required");
            return;
        } else if (formdetails.expiry.length === 0) {
            alert(" expiry is required");
            return;
        } else if (supplierinput.length === 0) {
            alert(" please Add supplier");
            return;
        } else if (formdetails.quantity == 0 || formdetails.quantity < 0) {
            alert("enter a valid quantity");
            return;
        }
        let expiryParse = formdetails.expiry.split("/");
        if (expiryParse[1] < 2021) {
            alert("please select expiry year greater than 2021");
            return;
        }
        setSelectedBach(undefined);
        let identifier = count;
        setcount((prev) => prev + 1);
        let discountedAmount =
            (formdetails.disc / 100) *
            (Number(formdetails.purchasePrice) * Number(formdetails.quantity));

        setallbatchinfo((prev) => [
            ...prev,
            {
                ...formdetails,
                SupplierName: supplierinput,
                quantity: Number(formdetails.quantity),
                purchasePrice: Number(formdetails.purchasePrice),
                freeQty: Number(formdetails.freeQty),
                productName: selectedProducts.productName,
                units: selectedProducts.packing,
                productId: selectedProducts?.productId,
                gstAmount: (
                    (formdetails.gstPercentage / 100) *
                    (Number(formdetails.purchasePrice) *
                        Number(formdetails.quantity) -
                        discountedAmount)
                ).toFixed(2),
                localId: identifier,
            },
        ]);
        setAllProducts((prev) => [
            ...prev,
            {
                ...formdetails,
                SupplierName: supplierinput,
                quantity: Number(formdetails.quantity),
                purchasePrice: Number(formdetails.purchasePrice),
                freeQty: Number(formdetails.freeQty),
                productName: selectedProducts.productName,
                units: selectedProducts.packing,
                productId: selectedProducts?.productId,
                gstAmount: (
                    (formdetails.gstPercentage / 100) *
                    (Number(formdetails.purchasePrice) *
                        Number(formdetails.quantity) -
                        discountedAmount)
                ).toFixed(2),
                localId: identifier,
            },
        ]);

        unitsref.current.value = "";
        productnameref.current.value = "";
        reset({
            batchNumber: "",
            productName: "",
            units: "",
            quantity: "",
            purchasePrice: "",
            gstPercentage: "",
            gstAmount: "",
            sellingPrice: "",
            mrp: "",
            expiry: "",
            disc: 0,
            freeQty: 0,
        });

        setselectedProducts();
    };
    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };
    const submitHandler = () => {
        if (invoicedateref.current.value.length == 0) {
            alert("please enter a valid Date");
            return;
        } else if (invoicenumberef.current.value.length == 0) {
            alert("please enter a valid invoice number");
            return;
        } else if (invoiceamountref.current.value.length == 0) {
            alert("please enter a valid Amount");
            return;
        } else if (addedbyref.current.value.length == 0) {
            alert("please enter added by");
            return;
        } else if (selectedSupplier?.supplierId === undefined) {
            alert("please enter Supplier");
            return;
        } else if (allbatchinfo.length == 0) {
            alert("please add Products");
            return;
        } else if (warehouseid.length == 0 && !adminData?.warehouseId) {
            alert("please Select warehouse");
            return;
        } else if (Freight.length == 0) {
            alert("Freight is required");
            return;
        } else if (roundOff.length == 0) {
            alert("roundOff is required");
            return;
        }
        let grossAmount = allbatchinfo.reduce(
            (prev, curr) =>
                prev + Number(curr.purchasePrice) * Number(curr.quantity),
            0
        );
        let gstAmount = allbatchinfo.reduce(
            (prev, curr) => prev + Number(curr.gstAmount),
            0
        );
        let discount = allbatchinfo.reduce((prev, curr) => {
            let totalcost = curr.purchasePrice * curr.quantity;
            return prev + (Number(curr.disc) / 100) * totalcost;
        }, 0);

        let body = {
            invoiceNumber: invoicenumberef.current.value,
            invoiceDate: invoicedateref.current.value,
            invoiceAmount: Number(invoiceamountref.current.value),
            supplierName: selectedSupplier.SupplierName,
            addedBy: addedbyref.current.value,
            supplierId: Number(selectedSupplier.supplierId),
            warehouseId: !adminData?.warehouseId
                ? warehouseid
                : admindata.warehouseId,
            grossAmount: Number(grossAmount) + Number(gstAmount),
            // totalGST: Number(gstAmount),
            totalGST: Number(gstAmount).toFixed(2),
            discountAmount: Number(discount),
            netAmount:
                Number(grossAmount) -
                Number(discount) +
                Number(gstAmount) +
                Number(Freight) +
                Number(roundOff),
            adminUserId: admindata?.adminUserId,
            roundoffCharges: Number(roundOff),
            freightCharges: Number(Freight),
            POitems: allbatchinfo,
        };
        // return;
        setaddLoad(true);
        Services.Addpoentry("POST", JSON.stringify(body), token)
            .then((res) => {
                if (res.status === 1) {
                    Navigate("/purchaseorder?page=1");
                    setaddLoad(false);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.status === 0) {
                    setaddLoad(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        setaddLoad(false);
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setaddLoad(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const removeHandler = (e) => {
        let localId = e.currentTarget.getAttribute("btnid");
        if (window.confirm("Are you sure you want to delete the product")) {
            let newProducts = allproducts.filter(
                (item) => item.localId != localId
            );
            setAllProducts(newProducts);
            setallbatchinfo(newProducts);
        }
    };
    const editHandler = (e) => {
        let localId = e.currentTarget.getAttribute("btnid");
        const [editproduct] = allproducts.filter(
            (item) => item.localId == localId
        );

        reset({
            quantity: Number(editproduct.quantity),
            purchasePrice: Number(editproduct.purchasePrice),
            freeQty: Number(editproduct.freeQty),
            batchNumber: editproduct?.batchNumber,
            gstPercentage: editproduct?.gstPercentage,

            sellingPrice: editproduct?.sellingPrice,
            mrp: editproduct?.mrp,
            expiry: editproduct.expiry,
            disc: editproduct.disc,
        });
        unitsref.current.value = editproduct?.units;
        productnameref.current.value = editproduct.productName;
        setselectedProducts({
            productName: editproduct.productName,
            packing: editproduct.units,
            productId: editproduct?.productId,
        });
        const allproduct = allproducts.filter(
            (item) => item.localId != localId
        );
        const allbatch = allbatchinfo.filter((item) => item.localId != localId);
        setallbatchinfo(allbatch);
        setAllProducts(allproduct);
    };
    const FreightChange = (e) => {
        setFreight(e.target.value);
    };
    return (
        <>
            {addload && (
                <div className={poclasses["modal"]}>
                    <div className={poclasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            )}
            {showaddbatch && (
                <Addbatchinfo
                    batchmodal={setShowBatch}
                    setmodal={setshowaddbatch}
                    setBatchDetails={setBatchDetails}
                    selectedProducts={selectedProducts}
                />
            )}
            {showSupplier && (
                <SupplierDetails
                    supplier={supplier}
                    setselectedSupplier={setselectedSupplier}
                    setmodal={setshowSupplier}
                    setsupplierInput={setsupplierInput}
                    setSupplierInvoices={setSupplierInvoices}
                />
            )}
            {showProduct && (
                <ProductDetails
                    products={products}
                    setselectedProducts={setselectedProducts}
                    setmodal={setShowProduct}
                    setBatchDetails={setBatchDetails}
                    reset={reset}
                    productnameref={productnameref}
                    unitsref={unitsref}
                    showbatch={setShowBatch}
                />
            )}
            {showbatch && (
                <Batchinfo
                    setBatchDetails={setBatchDetails}
                    setmodal={setShowBatch}
                    reset={reset}
                    setSelectedBach={setSelectedBach}
                    batchDetails={batchDetails}
                    expiryref={expiryref}
                    setaddbatch={setshowaddbatch}
                    selectedProducts={selectedProducts}
                />
            )}
            <TopNav />
            <div className={poclasses["content-wrapper"]}>
                <button
                    className={poclasses["backbutton"]}
                    onClick={() => {
                        Navigate(-1);
                    }}>
                    Back
                </button>
                <div className={poclasses["form-wrapper"]}>
                    <div className={poclasses["left-side"]}>
                        <header>
                            <div className={poclasses["item"]}>
                                <p>Date :</p>
                                <h3>
                                    <Moment format="YYYY/MM/DD">
                                        {new Date()}
                                    </Moment>
                                </h3>
                            </div>
                        </header>
                        <div className={poclasses["supplier"]}>
                            <div className={poclasses["supplier-leftside"]}>
                                <div className={poclasses["supplier-name"]}>
                                    <input
                                        placeholder="supplier"
                                        type="text"
                                        value={supplierinput}
                                        disabled={id}
                                        onChange={supplierChangeHandler}
                                        onKeyDown={supplierSearchHandler}
                                        className={poclasses["form-control"]}
                                    />
                                </div>
                                <div className={poclasses["supplier-details"]}>
                                    <div
                                        className={
                                            poclasses["supplier-contact"]
                                        }>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>Phone</p>
                                            <h3>{selectedSupplier?.phone}</h3>
                                        </div>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>Email</p>
                                            <h3>{selectedSupplier.emailId}</h3>
                                        </div>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>GSTIN</p>
                                            <h3>{selectedSupplier.GSTIN}</h3>
                                        </div>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>FSTIN</p>
                                            <h3>test</h3>
                                        </div>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>DS1</p>
                                            <h3>test</h3>
                                        </div>
                                        <div
                                            className={
                                                poclasses["supplier-item"]
                                            }>
                                            <p>DS2</p>
                                            <h3>test</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={poclasses["supplier-right-side"]}>
                                <form>
                                    <div className={poclasses["form-control"]}>
                                        <label htmlFor="Invoice Number">
                                            Invoice number
                                        </label>
                                        {id ? (
                                            <input
                                                ref={invoicenumberef}
                                                disabled
                                            />
                                        ) : (
                                            <input ref={invoicenumberef} />
                                        )}
                                    </div>
                                    <div className={poclasses["form-control"]}>
                                        <label htmlFor="date">
                                            Invoice date
                                        </label>
                                        <input
                                            ref={invoicedateref}
                                            type={"date"}
                                            disabled={id}
                                        />
                                    </div>
                                    <div className={poclasses["form-control"]}>
                                        <label htmlFor="Invoice Number">
                                            Invoice Amount
                                        </label>
                                        <input
                                            ref={invoiceamountref}
                                            type={"number"}
                                            disabled={id}
                                        />
                                    </div>
                                    {!adminData?.warehouseId && !id && (
                                        <div
                                            className={
                                                poclasses["form-control"]
                                            }>
                                            <label htmlFor="Invoice Number">
                                                WareHouse
                                            </label>
                                            <select
                                                defaultValue=""
                                                onChange={(e) => {
                                                    setWareHouseid(
                                                        e.target.value
                                                    );
                                                }}>
                                                <option value="" disabled>
                                                    {" "}
                                                    Select warehouse
                                                </option>
                                                {warehouse.map((house) => (
                                                    <option
                                                        value={
                                                            house.warehouseId
                                                        }>
                                                        {house.warehouseName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    <div
                                        className={poclasses["form-control"]}
                                        style={{ gridColumn: "1/-1" }}>
                                        <label htmlFor="Invoice Number">
                                            Added By
                                        </label>
                                        <input
                                            disabled
                                            ref={addedbyref}
                                            value={admindata?.firstName}
                                            type={"text"}
                                            // disabled={id}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={poclasses["right-side"]}>
                        <header>
                            <p>Net Amount</p>
                            <p>₹ {Number(calculations?.net).toFixed(2)}</p>
                        </header>

                        <div className={poclasses["supplier-cost-item"]}>
                            <p>CR/DB notes</p>
                            <h3>₹ 0</h3>
                        </div>

                        <div className={poclasses["supplier-cost-item"]}>
                            <p>Total GST</p>
                            <h3>
                                {/* ₹ {Number(calculations?.totalGST).toFixed(2)} */}{" "}
                                ₹ {Number(calculations?.totalGST).toFixed(2)}
                            </h3>
                        </div>
                        <div className={poclasses["supplier-cost-item"]}>
                            <p>Total Discount</p>
                            <h3>
                                ₹{" "}
                                {Number(calculations?.discountAmount).toFixed(
                                    2
                                )}
                            </h3>
                        </div>
                        <div className={poclasses["supplier-cost-item"]}>
                            <p>Gross Amount</p>
                            <h3>
                                ₹ {Number(calculations?.grossAmount).toFixed(2)}
                            </h3>
                        </div>
                        <div className={poclasses["supplier-cost-item"]}>
                            <p>Round off Charges</p>
                            <input
                                type={"number"}
                                onChange={roundoffChange}
                                disabled={id}
                                value={roundOff}
                            />
                        </div>
                        <div className={poclasses["supplier-cost-item"]}>
                            <p>Freight Charges</p>
                            <input
                                type={"number"}
                                disabled={id}
                                onChange={FreightChange}
                                value={Freight}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={poclasses["content-table-data"]}>
                {!id && (
                    <form
                        className={poclasses["inputs"]}
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => checkKeyDown(e)}>
                        {/* <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Search</label>
                            <input
                                // placeholder="Code/search"
                                onKeyDown={productsearchHandler}
                                type={"text"}
                            />
                        </div> */}
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Product Name</label>
                            <input
                                style={{
                                    maxWidth: "210px",
                                    minWidth: "200px",
                                }}
                                onKeyDown={productsearchHandler}
                                type={"text"}
                                ref={productnameref}
                                // {...register("productName")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Units</label>
                            <input
                                ref={unitsref}
                                disabled
                                type={"text"}
                                // {...register("units")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Batch No</label>
                            <input
                                // placeholder="Batch number"
                                type={"text"}
                                {...register("batchNumber")}
                            />
                        </div>

                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Pur Rate</label>
                            <input
                                // placeholder="purchase Price(₹)"
                                type={"number"}
                                step={"0.01"}
                                {...register("purchasePrice")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="code">Qty</label>
                            <input
                                // placeholder="quantity"
                                type={"number"}
                                {...register("quantity")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="Free">Free</label>
                            <input
                                // placeholder="Free"
                                defaultValue="0"
                                type={"text"}
                                {...register("freeQty")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="MRP">Discount %</label>
                            <input
                                // placeholder="discount %"
                                defaultValue="0"
                                type={"number"}
                                {...register("disc")}
                            />
                        </div>

                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="gST">GST %</label>
                            <input
                                // placeholder="GST %"
                                disabled
                                type={"number"}
                                {...register("gstPercentage")}
                            />
                        </div>

                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="Expiry">Expiry</label>
                            <input
                                style={{
                                    maxWidth: "150px",
                                    minWidth: "150px",
                                }}
                                // ref={expiryref}
                                pattern="(0[1-9]|1[012])/[0-9]{4}"
                                placeholder="MM/YYYY"
                                {...register("expiry")}
                            />
                        </div>
                        <div className={poclasses["form-control-add"]}>
                            <label htmlFor="MRP">MRP</label>
                            <input disabled {...register("MRP-NR")} />
                        </div>
                        <button type="submit">Add</button>
                    </form>
                    // {expiryref.c}
                )}
                <div className={poclasses["table-wrapper"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Sr</th>
                                <th>product Name</th>
                                <th>Units</th>
                                <th>batch Number</th>
                                <th>purchase Price</th>
                                <th>quantity</th>
                                <th>Free</th>
                                <th>Discount</th>
                                <th>gst Percentage</th>
                                <th>GST Amount</th>

                                <th>Expiry</th>
                                <th>SupplierName</th>
                                <th>Total Amount</th>
                                {!id && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {allproducts?.length > 0 ? (
                                allproducts?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.productName}</td>
                                            <td>{item.units}</td>
                                            <td>{item.batchNumber}</td>

                                            <td>₹ {item.purchasePrice}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.freeQty}</td>
                                            <td>{item.disc}%</td>
                                            <td>{item.gstPercentage}%</td>
                                            <td>₹ {item?.gstAmount}</td>

                                            <td>{item?.expiry}</td>
                                            <td>{item?.SupplierName}</td>
                                            <td>
                                                ₹{" "}
                                                {Number(
                                                    item.purchasePrice *
                                                        item.quantity
                                                ) -
                                                    Number(item.disc / 100) *
                                                        Number(
                                                            item.purchasePrice *
                                                                item.quantity
                                                        )}
                                            </td>
                                            {!id && (
                                                <td>
                                                    <button
                                                        onClick={editHandler}
                                                        btnid={item?.localId}>
                                                        <AiFillEdit size={26} />
                                                    </button>
                                                    <button
                                                        onClick={removeHandler}
                                                        btnid={item?.localId}>
                                                        <MdDelete size={26} />
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={15}
                                        style={{ textAlign: "center" }}>
                                        No Products found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {!id && (
                <div className={poclasses["submitHandler"]}>
                    <button onClick={submitHandler} disabled={addload}>
                        Submit{" "}
                    </button>
                </div>
            )}
        </>
    );
}

export default AddpurchaseOrder;
