import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from "react-moment";
import { AiOutlineEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
// import Addpromocodes from "./addpurchaseOrder";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";

function Warehouse() {
    const Navigate = useNavigate();
    const warehouseref = useRef();
    const [forcepage, setforcepage] = useState();

    const [pagsize, setpagesize] = useState(1);
    const [promocodes, setpromocodes] = useState(false);
    const [editable, setEditable] = useState(false);
    const [params, setparams] = useSearchParams();
    const [promoCodeItems, setPromoCodeItems] = useState([]);
    const [productSearch, setproductSearch] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [promocodeid, setpromocodeid] = useState();
    const [warehousedata, setWarehouses] = useState([]);
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const [tableLoad, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [supplierfilterId, setsupplierfilterId] = useState();
    const [searchterm, setsearchterm] = useState("");
    const [warehouseSearch, setwarehouseSearch] = useState("");
    const [showPaginate, setShowPagiate] = useState(true);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const itemsPerPage = 20; // for example, you can change this as per your requirement
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const fetchData = () => {
        //searchPoentriy
        let body = { warehouseId: warehouseSearch };
        if (searchterm.length > 0) {
            body = { ...body, POentryId: Number(searchterm) };
        }
        if (supplierfilterId.length > 0) {
            body = { ...body, supplierId: supplierfilterId };
        }
        if (supplierfilterId.length == 0 && searchterm.length == 0) {
            getpromocodes(warehouseSearch, 0);
            setparams({ page: 1 });
            setforcepage(1);
            setShowPagiate(true);
            return;
        }
        setTableLoad(true);
        Services.searchPoentriy("POST", JSON.stringify(body), token)
            .then((res) => {
                console.log(res);
                setTableLoad(false);
                setparams({ page: 1 });
                if (res.Status === 1) {
                    setproductSearch(res.POentries);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        let timer = setTimeout(() => {
            fetchData();
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [searchterm, supplierfilterId]);
    const getSuppliers = () => {
        Services.getSupplier("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setSupplier(res.Suppliers);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getpromocodes = (id, skip) => {
        setwarehouseSearch(id);
        let body = {
            warehouseId: id,
            skip: skip,
        };

        Services.getPoentryById("POST", JSON.stringify(body), token)
            .then((res) => {
                setLoading(false);
                setTableLoad(false);
                console.log(res);
                if (res.Status === 1) {
                    setPromoCodeItems(res.POentries);
                    let items;
                    if (searchterm.length > 0) {
                        items = res.POentries.filter((item) =>
                            String(item.POentryId).includes(searchterm)
                        );
                    } else {
                        items = res.POentries;
                    }
                    setpagesize(20);
                    setpageCount(Math.ceil(Number(res?.Count) / Number(20)));
                    setproductSearch(items);
                } else if (res.Status === 0) {
                    console.log(res.Message);

                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                    getpromocodes(
                        response.warehouses[0].warehouseId,
                        Number(params.get("page") - 1) * 20
                    );
                    setwarehouseSearch(response.warehouses[0].warehouseId);
                    setforcepage(Number(params.get("page") - 1));
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        if (adminData?.warehouseId) {
            setWarehouses([
                {
                    warehouseid: adminData?.warehouseId,
                    warehouseName: adminData?.warehouseName,
                },
            ]);
            getpromocodes(adminData?.warehouseId, 0);
            return;
        }
        getwarehouses();
        getSuppliers();
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     let timer = setTimeout(() => {
    //         setTableLoad(false);
    //         console.log("11");
    //     }, 150);
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [productSearch]);
    const edithandler = (e) => {
        e.stopPropagation();
        setpromocodeid(e.currentTarget.getAttribute("promoid"));
        Navigate(
            `/purchaseOrderDetails/${e.currentTarget.getAttribute("promoid")}`
        );
        setEditable(true);
        setpromocodes(true);
    };

    const searchHandler = (e) => {
        // console.log(e.target.value);
        setsearchterm(e.target.value);

        setShowPagiate(false);
        Navigate("/purchaseorder?page=1");
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div
                className={dashboardclasses.tablebtns}
                style={{ marginLeft: "1rem" }}>
                <button onClick={edithandler} promoid={cellcontent.POentryId}>
                    <AiOutlineEye size={18} />
                </button>
            </div>
        );
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);
        const datestring = `${String(date.getDate()).padStart(2, "0")}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}-${date.getFullYear()}`;
        return (
            <div>
                <Moment format="DD-MM-YYYY">{new Date(cell)}</Moment>
            </div>
        );
    };

    const columns = [
        {
            dataField: "POentryId",
            text: "ENTRY",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "supplierName",
            text: "SUPPLIER NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "invoiceNumber",
            text: "INVOICE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "invoiceDate",
            text: "INV DATE",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "invoiceAmount",
            text: "INV AMT",
            sort: false,
            formatter: (cell) => {
                return <p style={{ margin: 0 }}>₹ {cell}</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "addedBy",
            text: "ADDED BY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "netAmount",
            text: "NET AMOUNT",
            sort: false,
            formatter: (cell) => {
                return <p style={{ margin: 0 }}>₹ {cell.toFixed(2)}</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 20,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/purchaseorder?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            // console.log("page", page);
            // console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    // const tableRowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //         Navigate(`/poentryDetails/${row.POentryId}`);
    //     },
    // };
    const warehousefilter = (e) => {
        let warehouseId = e.target.value;
        setwarehouseSearch(warehouseId);
        getpromocodes(warehouseId, 0);
    };
    const supplierHandler = (e) => {
        let id = e.target.value;
        setsupplierfilterId(id);
        setShowPagiate(false);
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        window.scrollTo({ top: 10, behavior: "instant" });
        // Navigate(`/purchaseorder?page=${selectedObject.selected + 1}`);
        setparams({ page: selectedObject.selected + 1 });
        setforcepage(selectedObject.selected);
        getpromocodes(warehouseSearch, selectedObject.selected * pagsize);
        setTableLoad(true);
    };
    return (
        <>
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="purchaseorder" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>purchase Order</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Entry"
                                        onChange={searchHandler}
                                    />

                                    <select
                                        onChange={warehousefilter}
                                        ref={warehouseref}>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={() => {
                                            // setpromocodes(true);
                                            Navigate("/AddpurchaseOrder");
                                        }}>
                                        + Add Poentry
                                    </button>
                                    <select
                                        onChange={supplierHandler}
                                        defaultValue="">
                                        <option value="" disabled>
                                            Select Supplier
                                        </option>
                                        {supplier.map((item) => (
                                            <option value={item.supplierId}>
                                                {item.SupplierName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={productSearch}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            wrapperClasses="table-responsive"
                                            // rowEvents={tableRowEvents}
                                        />
                                        {showPaginate && (
                                            <div
                                                style={{
                                                    justifySelf: "right",
                                                }}>
                                                <ReactPaginate
                                                    pageCount={pageCount}
                                                    // pageRange={2}
                                                    forcePage={forcepage}
                                                    marginPagesDisplayed={2}
                                                    onPageChange={
                                                        handlePageChange
                                                    }
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={
                                                        "page-link"
                                                    }
                                                    containerClassName={
                                                        "pagination"
                                                    }
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={
                                                        "page-link"
                                                    }
                                                    previousClassName={
                                                        "page-item"
                                                    }
                                                    previousLinkClassName={
                                                        "page-link"
                                                    }
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={
                                                        "page-link"
                                                    }
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
