import React, { useEffect, useState, useRef } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
function AddCategory(props) {
    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState(false);
    const pincoderef = useRef();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let states = State.getStatesOfCountry("IN");
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getWarehousebyId("GET", null, token, props.id)
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        setpincode(res.warehouses.pincode);
                        reset({
                            warehouseName: res.warehouses.warehouseName,
                            city: res.warehouses.city,
                            latitude: res.warehouses.latitude,
                            longitude: res.warehouses.longitude,
                            GSTNumber: res.warehouses.GSTNumber,
                            PANNumber: res.warehouses.PANNumber,
                            pincode: res.warehouses.pincode,
                            status: res.warehouses.status,
                            address1: res.warehouses.address1,
                            address2: res.warehouses.address2,
                            state: res.warehouses.state,
                        });
                    } else if (res?.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    const pincodeChange = (e) => {
        if (e.target.value.length <= 6) {
            setpincodeError(false);
            setpincode(e.target.value);
        }
    };
    const onSubmit = (data) => {
        console.log(data);
        if (pincode.length < 6) {
            pincoderef.current.focus();
            setpincodeError(true);
            return;
        }
        let method = JSON.stringify({
            pincode: pincode,
            ...data,
        });
        if (!props.editable) {
            props.tableload(true);
            Services.AddWarehouse("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        Navigate(`/Warehouse?page=${1}`);
                        props.setpageCount(1);
                        props.setmodal(false);
                        props.getCategories();
                        props.tableload(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        props.tableload(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    props.tableload(false);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateWarehouse("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update warehouse"
                                : "Add warehouse"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update warehouse"
                                : "Add warehouse"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Warehouse Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("warehouseName", {
                                    required: "warehouse Name  is required",
                                })}
                            />

                            {errors?.warehouseName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.warehouseName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Address1</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("address1", {
                                    required: "address1  is required",
                                })}
                            />

                            {errors?.address1 && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.address1?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Address2</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("address2")} />
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">City</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("city", {
                                    required: "city is required",
                                })}
                            />

                            {errors?.city && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.city?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">State</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                {...register("state", {
                                    required: "State is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.length > 0 &&
                                    states.map((item) => (
                                        <option value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>

                            {errors?.state && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.state?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="pincode">Pincode</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                ref={pincoderef}
                                type={"number"}
                                onChange={pincodeChange}
                                value={pincode}
                                pattern="^[0-9\b]+$"
                            />

                            {pincodeError && (
                                <p className={dashboardclasses["error"]}>
                                    Enter a valid pincode
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="latitude">Latitude</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                step={"any"}
                                {...register("latitude", {
                                    required: "latitude is required",
                                })}
                            />

                            {errors?.latitude && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.latitude?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="longitude">Longitude</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                step={"any"}
                                {...register("longitude", {
                                    required: "longitude is required",
                                })}
                            />

                            {errors?.latitude && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.longitude?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="PANNumber">PANNumber</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"TEXT"}
                                step={"any"}
                                {...register("PANNumber", {
                                    required: "PAN Number is required",
                                })}
                            />

                            {errors?.PANNumber && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.PANNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="GSTNumber">GSTNumber</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"TEXT"}
                                step={"any"}
                                {...register("GSTNumber", {
                                    required: "GST Number is required",
                                })}
                            />

                            {errors?.GSTNumber && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.GSTNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" status">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "status Is required",
                                })}>
                                <option value="" disabled>
                                    Select status
                                </option>
                                <option value={1}>active</option>
                                <option value={2}>inactive</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Warehouse"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
