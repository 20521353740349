import React, { useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddProducts(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getpromocodeByID("GET", null, token, props.promocodeid)
                .then((response) => {
                    console.log(response);
                    if (response.status === 1) {
                        // setCategoryItems(response.categories);
                        const date = (date) => {
                            const startdate = new Date(date);
                            const startdatestring = `${startdate.getFullYear()}-${String(
                                startdate.getMonth() + 1
                            ).padStart(2, "0")}-${String(
                                startdate.getDate()
                            ).padStart(2, "0")}`;
                            return startdatestring;
                        };
                        const startDate = date(response.promoCode.startDate);
                        const endDate = date(response.promoCode.endDate);
                        reset({
                            description: response.promoCode.description,
                            endDate: endDate,
                            maxDiscount: response.promoCode.maxDiscount,
                            offerValue: response.promoCode.offerValue,
                            promoCode: response.promoCode.promoCode,
                            promoCodeId: response.promoCode.promoCodeId,
                            minOrdervalue: response.promoCode.minOrdervalue,
                            startDate: startDate,
                            status: response.promoCode.status,
                            type: response.promoCode.type,
                        });
                    } else if (response.status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (response.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const openhandler = (e) => {
        e.stopPropagation();
    };
    const onSubmit = (data) => {
        console.log(data);
        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            props.tableload(true);
            Services.addPromoCode("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setpageCount(1);
                        props.setmodal(false);
                        props.getpromocodes();
                        props.tableload(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        props.tableload(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    props.tableload(false);
                    alert("something went wrong please try again");

                    console.log(err);
                });
        } else if (props.editable) {
            Services.updatepromocode("PUT", method, token, props.promocodeid)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getpromocodes();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update promocode"
                                : "Add promocode"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update promocode"
                                : "Add promocode"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">product Code</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type="text"
                                {...register("promoCode", {
                                    required: "promoCode is required",
                                })}
                            />

                            {errors?.promoCode && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.promoCode?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="startDate">start Date</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"date"}
                                {...register("startDate", {
                                    required: "startDate is required",
                                })}
                            />

                            {errors?.startDate && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.startDate?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="endDate">End Date</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type="date"
                                {...register("endDate", {
                                    required: "endDate is required",
                                })}
                            />

                            {errors?.endDate && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.endDate?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" type">type</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("type", {
                                    required: "type is required",
                                })}>
                                <option value="" disabled>
                                    Select type
                                </option>
                                <option value="1">percentage </option>
                                <option value="2">amount</option>
                            </select>
                            {errors?.categoryId && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.categoryId?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="offerValue">offer Value</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                step="0.00001"
                                {...register("offerValue", {
                                    required: "offerValue is required",
                                })}
                            />

                            {errors?.offerValue && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.offerValue?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="offerValue">Min order value</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                step="0.00001"
                                {...register("minOrdervalue", {
                                    required: "Min Order Value is required",
                                })}
                            />

                            {errors?.minOrdervalue && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.minOrdervalue?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="maxDiscount">maxDiscount</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                step="0.00001"
                                {...register("maxDiscount", {
                                    required: "maxDiscount is required",
                                })}
                            />

                            {errors?.maxDiscount && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.maxDiscount?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" type">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "Status is required",
                                })}>
                                <option value="" disabled>
                                    Status
                                </option>
                                <option value="1">Active </option>
                                <option value="2">InActive</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="Description">Description</label>
                        <div className={dashboardclasses["select"]}>
                            <textarea
                                rows="5"
                                {...register("description")}></textarea>

                            {errors?.description && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.description?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add promocode"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
