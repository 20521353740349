import React from "react";

import logoutClasses from "./logout.module.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function Modal(props) {
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const onSubmit = (data) => {
        localStorage.clear();
        Navigate("/");
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            <div className={logoutClasses["modal"]}>
                <div
                    className={logoutClasses["modal-content"]}
                    onClick={openhandler}>
                    <div className={logoutClasses["header"]}>
                        <p>Logout </p>
                        <button
                            className={logoutClasses["close-btn"]}
                            onClick={CancelHandler}>
                            <IoClose size={27} />
                        </button>
                    </div>
                    <p>Are you sure you want to logout ?</p>
                    <div className={logoutClasses["control-btns"]}>
                        <button
                            className={logoutClasses["delete-btn"]}
                            type="button"
                            onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            className={logoutClasses["submit-btn"]}
                            onClick={onSubmit}>
                            logout
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
