import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import { Services } from "../../../Services";
import { useForm } from "react-hook-form";
import pushClasses from "./push.module.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
function Modal(props) {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const onSubmit = (data) => {
        setloading(true);
        console.log(data);
        let url;
        let method;
        if (props.notificationType == 8) {
            method = {
                ...data,
                type: props.notificationType,
                isNotified: 1,
            };
            url = Services.bulkNotifications(
                "POST",
                JSON.stringify(method),
                token
            );
        } else {
            method = {
                ...data,
                userId: props.userid,
                type: props.notificationType,
                isNotified: 1,
            };
            url = Services.updateuser(
                "PUT",
                JSON.stringify(method),
                token,
                props.userid
            );
        }

        url.then((res) => {
            console.log("22");
            if (res.Status === 1) {
                props.setmodal(false);
                toast.success(`${res.Message}`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (res.Status === 0) {
                setloading(false);
                toast.error(`${res.Message}`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (res.Message === "Token expired") {
                    Navigate("/");
                }
            }
        }).catch((err) => {
            setloading(false);
            // alert(err);
            alert("something went wrong please try again");
            console.log(err);
        });
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            {loading ? (
                <div className={pushClasses["modal"]}>
                    <div className={pushClasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            ) : (
                <div className={pushClasses["modal"]}>
                    <div
                        className={pushClasses["modal-content"]}
                        onClick={openhandler}>
                        <div className={pushClasses["header"]}>
                            <p>Push Notify </p>
                            <button
                                className={pushClasses["close-btn"]}
                                onClick={CancelHandler}>
                                <IoClose size={27} />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={pushClasses["form-control"]}>
                                <label htmlFor="title">Title </label>
                                <input
                                    placeholder="title"
                                    rows={4}
                                    {...register("title", {
                                        required: "Title is required!",
                                    })}
                                />
                                {errors?.title && (
                                    <p className={pushClasses.error}>
                                        {errors?.title?.message}
                                    </p>
                                )}
                            </div>
                            <div className={pushClasses["form-control"]}>
                                <label htmlFor="message">Body</label>
                                <textarea
                                    placeholder="message"
                                    rows={4}
                                    {...register("message", {
                                        required: "message is required!",
                                    })}
                                />
                                {errors?.message && (
                                    <p className={pushClasses.error}>
                                        {errors?.message?.message}
                                    </p>
                                )}
                            </div>
                            <div className={pushClasses["control-btns"]}>
                                <button
                                    className={pushClasses["delete-btn"]}
                                    type="button"
                                    onClick={CancelHandler}>
                                    Cancel
                                </button>
                                <button
                                    className={pushClasses["submit-btn"]}
                                    type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
