import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Addproducts from "./Addproducts";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Moment from "react-moment";
import { useNavigate, useSearchParams } from "react-router-dom";
function Warehouse() {
    const Navigate = useNavigate();
    const [products, setProducts] = useState(false);
    const [getparams] = useSearchParams();
    let type = getparams.get("type");
    const [editable, setEditable] = useState(false);
    const [productsItems, setproductsItems] = useState([]);
    const [productSearch, setproductSearch] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    // filters
    const [activefilter, setactivefilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [genericFilter, setgenericFilter] = useState("");
    const [manufactureFilter, setmanufactureFilter] = useState("");
    const [searchFilter, setSearchfilter] = useState("");
    const [fromDate, setfromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [companies, setcompanies] = useState([]);
    const [productid, setproductid] = useState();
    const [molecules, setMolecules] = useState([]);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const company = () => {
        Services.getCompany("GET", null, token)
            .then((res) => {
                setLoading(false);

                if (res.Status === 1) {
                    setcompanies(res.Companies);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getmolecules = () => {
        Services.getMolecules("GET", null, token)
            .then((res) => {
                setLoading(false);

                if (res.Status === 1) {
                    setMolecules(res.MoleculeComposition);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getproducts = () => {
        Services.getProducts("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setproductsItems(res.Products);
                    setproductSearch(res.Products);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        company();
        getmolecules();
        Services.getCategories("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setCategoryItems(res.categories);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        getproducts();
        // eslint-disable-next-line
    }, []);

    const edithandler = (e) => {
        console.log(e.currentTarget.getAttribute("id"));
        setproductid(e.currentTarget.getAttribute("prodid"));
        setEditable(true);
        setProducts(true);
    };

    const searchHandler = (e) => {
        setSearchfilter(e.target.value);
        let search = productsItems.filter((item) =>
            item.productName
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
        );
        if (typeFilter.length !== 0) {
            search = search.filter(
                (item) => item.categoryId === Number(typeFilter)
            );
        }
        if (activefilter.length !== 0) {
            search = search.filter(
                // eslint-disable-next-line
                (item) => item.status == Number(activefilter)
            );
        }
        if (genericFilter.length !== 0) {
            search = search.filter((item) => item.genericName == genericFilter);
        }
        if (manufactureFilter.length !== 0) {
            search = search.filter(
                (item) => item.manufacturedBy == manufactureFilter
            );
        }
        if (fromDate.length > 0 && toDate.length > 0) {
            const from = new Date(fromDate).setHours(0, 0, 0, 0);
            const to = new Date(toDate).setHours(0, 0, 0, 0);
            if (from < to) {
                search = search.filter((item) => {
                    let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

                    return dat <= to && dat >= from;
                });
            }
        }
        console.log(search);
        setproductSearch(search);
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button
                    onClick={edithandler}
                    id={cellcontent.categoryId}
                    prodid={cellcontent.productId}>
                    <FaRegEdit size={18} />
                </button>
                {/* <button onClick={deleteHandler} id={cellcontent.categoryId}>
                    <MdDelete size={18} />
                </button> */}
            </div>
        );
    };
    // const statushandler = (e) => {
    //     let status = e.currentTarget.getAttribute("status");
    //     let prodid = e.currentTarget.getAttribute("prodid");
    //     let method = JSON.stringify({
    //         status: status == 1 ? 2 : 1,
    //     });
    //     Services.updateProduct("PUT", method, token, prodid)
    //         .then((res) => {
    //             if (res.Status === 1) {
    //                 getproducts();
    //                 toast.success(`${res.Message}`, {
    //                     position: "top-center",
    //                     autoClose: 1000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             } else if (res.Status === 0) {
    //                 toast.error(`${res.Message}`, {
    //                     position: "top-center",
    //                     autoClose: 1000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //                 if (res.Message === "Token expired") {
    //                     Navigate("/");
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             // alert(err);
    //             alert("something went wrong please try again");
    //             console.log(err);
    //         });
    // };
    // const statusButton = (cell, cellContent) => {
    //     return (
    //         <div className={dashboardclasses["role-slider"]}>
    //             <label className={dashboardclasses["switch"]}>
    //                 <input
    //                     onChange={statushandler}
    //                     type="checkbox"
    //                     status={cellContent.status}
    //                     defaultChecked={cellContent.status == 1}
    //                     prodid={cellContent.productId}
    //                 />
    //                 <span
    //                     className={`${dashboardclasses["slider"]} ${dashboardclasses["round"]}`}></span>
    //             </label>
    //         </div>
    //         // </div>
    //     );
    // };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return (
            <Moment format="DD/MM/YYYY" subtract={{ hours: 5, minutes: 30 }}>
                {date}
            </Moment>
        );
    };
    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
        );
    };
    const nameformatter = (cell, cellContent) => {
        return (
            <p>
                {cell}
                <br />
                {cellContent.manufacturedBy}
            </p>
        );
    };
    let columns = [
        {
            dataField: "productName",
            text: "PRODUCT NAME",
            sort: false,
            formatter: nameformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "200px" };
            },
        },
        {
            dataField: "genericName",
            text: "GENERIC NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "250px" };
            },
        },

        {
            dataField: "packing",
            text: "packing",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "price",
            text: "MRP",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "gst",
            text: "GST",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "genifast_sellingPrice",
            text: "GENIFAST PRICE",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "genifast_usersellingPrice",
            text: "GENIFAST USER PRICE",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "medbuzz_sellingPrice",
            text: "MEDBUZZ SELLING PRICE",
            sort: false,
            formatter: (cell) => {
                return `₹ ${cell}`;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "130px" };
            },
        },
        {
            dataField: "is_available_in_medbuzz",
            text: "MEDBUZZ AVAILABILITY",
            sort: false,
            formatter: (cell) => {
                if (cell === 1) {
                    return "AVAILABLE";
                } else {
                    return "NOT AVAILABLE";
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "110px" };
            },
        },
        {
            dataField: "createdAt",
            text: "CREATED",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "50px" };
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
    ];
    if (type == "products-seo") {
        columns = columns.filter(
            (item) =>
                item.dataField != "price" && item.dataField != "sellingPrice"
        );
    }
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 20,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            // console.log("page", page);
            // console.log("sizePerPage", sizePerPage);
            window.scrollTo({ top: 10, behavior: "instant" });
        },
        onSizePerPageChange: function (page, sizePerPage) {
            // console.log("page", page);
            // console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const filtercategoryhandler = (e) => {
        setTypeFilter(e.target.value);
        let search = productsItems.filter(
            (item) => item.categoryId === Number(e.target.value)
        );

        if (searchFilter.length !== 0) {
            search = search.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            );
        }
        if (activefilter.length !== 0) {
            search = search.filter(
                // eslint-disable-next-line
                (item) => item.status == Number(activefilter)
            );
        }
        if (genericFilter.length !== 0) {
            search = search.filter((item) => item.genericName == genericFilter);
        }
        if (manufactureFilter.length !== 0) {
            search = search.filter(
                (item) => item.manufacturedBy == manufactureFilter
            );
        }
        if (fromDate.length > 0 && toDate.length > 0) {
            const from = new Date(fromDate).setHours(0, 0, 0, 0);
            const to = new Date(toDate).setHours(0, 0, 0, 0);
            if (from < to) {
                search = search.filter((item) => {
                    let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

                    return dat <= to && dat >= from;
                });
            }
        }
        setproductSearch(search);
    };
    const filtetbystatus = (e) => {
        setactivefilter(e.target.value);

        let search = productsItems.filter(
            // eslint-disable-next-line
            (item) => item.status == Number(e.target.value)
        );

        if (searchFilter.length !== 0) {
            search = search.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            );
        }
        if (typeFilter.length !== 0) {
            search = search.filter(
                (item) => item.categoryId === Number(typeFilter)
            );
        }
        if (genericFilter.length !== 0) {
            search = search.filter((item) => item.genericName == genericFilter);
        }
        if (manufactureFilter.length !== 0) {
            search = search.filter(
                (item) => item.manufacturedBy == manufactureFilter
            );
        }
        if (fromDate.length > 0 && toDate.length > 0) {
            const from = new Date(fromDate).setHours(0, 0, 0, 0);
            const to = new Date(toDate).setHours(0, 0, 0, 0);
            if (from < to) {
                search = search.filter((item) => {
                    let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

                    return dat <= to && dat >= from;
                });
            }
        }
        setproductSearch(search);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const CompaniesHanlder = (e) => {
        let companyName = e.target.value;
        setmanufactureFilter(companyName);
        let search = productsItems.filter(
            (item) => item.manufacturedBy == companyName
        );

        if (searchFilter.length !== 0) {
            search = search.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            );
        }
        if (typeFilter.length !== 0) {
            search = search.filter(
                (item) => item.categoryId === Number(typeFilter)
            );
        }
        if (genericFilter.length !== 0) {
            search = search.filter((item) => item.genericName == genericFilter);
        }
        if (activefilter.length !== 0) {
            search = search.filter(
                (item) => item.status == Number(activefilter)
            );
        }
        if (fromDate.length > 0 && toDate.length > 0) {
            const from = new Date(fromDate).setHours(0, 0, 0, 0);
            const to = new Date(toDate).setHours(0, 0, 0, 0);
            if (from < to) {
                search = search.filter((item) => {
                    let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

                    return dat <= to && dat >= from;
                });
            }
        }
        setproductSearch(search);
    };
    const genericHandler = (e) => {
        let genericName = e.target.value;
        setgenericFilter(genericName);

        let search = productsItems.filter((item) =>
            String(item.genericName)
                .toLowerCase()
                .includes(genericName.toLowerCase())
        );
        if (e.target.value.length === 0) {
            search = productsItems;
        }
        if (searchFilter.length !== 0) {
            search = search.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            );
        }
        if (typeFilter.length !== 0) {
            search = search.filter(
                (item) => item.categoryId === Number(typeFilter)
            );
        }
        if (manufactureFilter.length !== 0) {
            search = search.filter(
                (item) => item.manufacturedBy == manufactureFilter
            );
        }
        if (activefilter.length !== 0) {
            search = search.filter(
                (item) => item.status == Number(activefilter)
            );
        }
        if (fromDate.length > 0 && toDate.length > 0) {
            const from = new Date(fromDate).setHours(0, 0, 0, 0);
            const to = new Date(toDate).setHours(0, 0, 0, 0);
            if (from < to) {
                search = search.filter((item) => {
                    let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

                    return dat <= to && dat >= from;
                });
            }
        }
        setproductSearch(search);
    };

    const datefilter = (e) => {
        e.preventDefault();
        const from = new Date(fromDate).setHours(0, 0, 0, 0);
        const to = new Date(toDate).setHours(0, 0, 0, 0);
        if (from > to) {
            alert("from Date cannot be greater than to date ");
            return;
        }
        let search = [];
        search = productsItems.filter((item) => {
            let dat = new Date(item?.createdAt).setHours(0, 0, 0, 0);

            return dat <= to && dat >= from;
        });
        if (genericFilter.length > 0) {
            search = search.filter((item) =>
                String(item.genericName)
                    .toLowerCase()
                    .includes(genericFilter.toLowerCase())
            );
        }
        if (searchFilter.length !== 0) {
            search = search.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            );
        }
        if (typeFilter.length !== 0) {
            search = search.filter(
                (item) => item.categoryId === Number(typeFilter)
            );
        }
        if (manufactureFilter.length !== 0) {
            search = search.filter(
                (item) => item.manufacturedBy == manufactureFilter
            );
        }
        if (activefilter.length !== 0) {
            search = search.filter(
                (item) => item.status == Number(activefilter)
            );
        }

        setproductSearch(search);
    };
    return (
        <>
            {products && (
                <Addproducts
                    setmodal={setProducts}
                    editable={editable}
                    setEditable={setEditable}
                    getproducts={getproducts}
                    productid={productid}
                    productsItems={productsItems}
                    setSearchfilter={setSearchfilter}
                    setTypeFilter={setTypeFilter}
                    setactivefilter={setactivefilter}
                    setmanufactureFilter={setmanufactureFilter}
                    setgenericFilter={setgenericFilter}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="products" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div className={dashboardclasses["add-product"]}>
                            <h3>Master Products</h3>
                            <button
                                onClick={() => {
                                    setEditable(false);
                                    setProducts(true);
                                }}>
                                Add Product
                            </button>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by product"
                                        onChange={searchHandler}
                                        value={searchFilter}
                                    />

                                    <select
                                        defaultValue=""
                                        onChange={filtercategoryhandler}
                                        value={typeFilter}>
                                        <option value="" disabled>
                                            type
                                        </option>
                                        {categoryItems.map((item) => (
                                            <option value={item.categoryId}>
                                                {item.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        defaultValue=""
                                        onChange={filtetbystatus}
                                        value={activefilter}>
                                        <option value="" disabled>
                                            Status
                                        </option>
                                        <option value="1">Active</option>
                                        <option value="2">inActive</option>
                                        <option value="3">No stock</option>
                                    </select>
                                    <input
                                        type="text"
                                        list="genericitems"
                                        placeholder=" generic name"
                                        onChange={genericHandler}
                                    />
                                    <datalist
                                        id="genericitems"
                                        onClick={genericHandler}>
                                        {molecules.map((item) => (
                                            <option
                                                value={item.compositionName}>
                                                {item.compositionName}
                                            </option>
                                        ))}
                                    </datalist>

                                    <select
                                        defaultValue=""
                                        onChange={CompaniesHanlder}
                                        value={manufactureFilter}>
                                        <option value="" disabled>
                                            manufactured by
                                        </option>
                                        {companies.map((item) => (
                                            <option value={item.companyName}>
                                                {item.companyName}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <button onClick={productHandler}>
                                        + Add Product
                                    </button> */}
                                    <form
                                        onSubmit={datefilter}
                                        className={
                                            dashboardclasses[
                                                "master-product-datefilter"
                                            ]
                                        }>
                                        <div>
                                            <input
                                                type="Date"
                                                onChange={(e) =>
                                                    setfromDate(e.target.value)
                                                }
                                            />
                                            <label htmlFor="" title="date">
                                                From Date
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="Date"
                                                onChange={(e) =>
                                                    setToDate(e.target.value)
                                                }
                                            />
                                            <label
                                                title="date"
                                                htmlFor="todate">
                                                To Date
                                            </label>
                                        </div>
                                        <button>Submit</button>
                                    </form>
                                </div>

                                <div className="category-table">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={productSearch}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        pagination={pagination}
                                        wrapperClasses="table-responsive"
                                        noDataIndication={emptyDataMessage}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
