import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import UpdateAvaiableProducts from "./UpdateAvailableProduct";
function Warehouse() {
    const [category, setCategory] = useState(false);
    const [poId, setPoId] = useState("");
    const { warehouseidParam, productid } = useParams();
    const [params, setparams] = useSearchParams();
    const [tableLoad, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [qty, setqty] = useState();
    const Navigate = useNavigate();
    const [id, setid] = useState();
    const [editable, setEditable] = useState(false);

    const [categoryItems, setCategoryItems] = useState([]);
    const [categorysearchItems, setCategorySearchItems] = useState([]);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const categoryHandler = () => {
        setCategory((prev) => !prev);
    };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getcategories = () => {
        Services.productDetails("GET", null, token, productid, warehouseidParam)
            .then((res) => {
                setLoading(false);

                if (res.Status === 1) {
                    setCategoryItems(res.ledger);
                    setCategorySearchItems(res.ledger);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getcategories();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [categorysearchItems]);
    const iconformatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses["cat-tablewrapper"]}>
                <div className={dashboardclasses["cat-tableicon"]}>
                    <img src={cell} alt="table-icon" />
                </div>
            </div>
        );
    };
    const edithandler = (e) => {
        // console.log(e.currentTarget.getAttribute("id"));
        setid(e.currentTarget.getAttribute("id"));
        setEditable(true);
        setCategory(true);
    };
    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.Deletecategories(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        getcategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button onClick={edithandler} id={cellcontent.categoryId}>
                    <FaRegEdit size={18} />
                </button>
                <button onClick={deleteHandler} id={cellcontent.categoryId}>
                    <MdDelete size={18} />
                </button>
            </div>
        );
    };
    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
        );
    };

    const columns = [
        {
            dataField: "SupplierName",
            text: "SUPPLIER NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },
        {
            dataField: "batchNumber",
            text: "BATCH NUMBER",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },

        {
            dataField: "availableQty",
            text: "AVAILABLE QTY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },
        {
            dataField: "totalQty",
            text: "TOTAL QTY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },

        {
            dataField: "expiry",
            text: "EXPIRY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },
        {
            dataField: "freeQty",
            text: "FREE QTY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },

        {
            dataField: "createdAt",
            text: "CREATED AT",
            sort: false,
            formatter: (cell) => <Moment format={"DD-MM-YYYY"}>{cell}</Moment>,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },
        {
            dataField: "ACTIONS",
            text: "ACTION",
            sort: false,
            formatter: (cell, cellContent) => (
                <button
                    style={{
                        color: "white",
                        background: "green",
                        padding: "0.5rem",
                        border: "none",
                    }}
                    onClick={() => {
                        setCategory(true);
                        setPoId(cellContent.POitemId);
                        setqty(cellContent.availableQty);
                    }}>
                    Adjust
                </button>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "400px" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            let urlparams = { page: page };

            if (params.get("type")) {
                urlparams = { type: params.get("type"), ...urlparams };
            }
            setparams(urlparams);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        setTableLoad(true);
        let urlparams = { page: 1 };

        if (params.get("type")) {
            urlparams = { type: params.get("type"), ...urlparams };
        }
        setparams(urlparams);
        setpageCount(1);
        const search = categoryItems.filter((item) =>
            item.categoryName
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
        );
        // console.log(search);

        setCategorySearchItems(search);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {category && (
                <UpdateAvaiableProducts
                    prodid={poId}
                    setmodal={setCategory}
                    categoryHandler={categoryHandler}
                    getcategories={getcategories}
                    qty={qty}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="categories" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>{localStorage.getItem("productName")}</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={categorysearchItems}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
