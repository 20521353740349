import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [warehousedata, setWarehouses] = useState([]);
    const [warehouseid, setwarehouseId] = useState();
    const [productSearch, setProductSearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedProducted, setSelectedProduct] = useState({
        name: "",
        value: "",
    });
    const [show, setshow] = useState(false);
    const [productError, setproductError] = useState(false);

    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);

                    setwarehouseId(response.warehouses[0].warehouseId);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const warehouseproductsearch = (name) => {
        let body = JSON.stringify({
            productName: name,
            warehouseId: warehouseid,
            skip: 0,
        });
        Services.searchByProductName("POST", body, token)
            .then((response) => {
                if (response.Status === 1) {
                    setProductSearch(
                        response.Products.map((item) => {
                            return {
                                name: item.productName,
                                value: item.productId,
                            };
                        })
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getwarehouses();
        if (props.editable) {
            reset({ View_In_Home: props.id.View_In_Home });
        }
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    useEffect(() => {
        let timer;
        if (searchTerm.length > 0 && show) {
            timer = setTimeout(() => {
                warehouseproductsearch(searchTerm);
            }, 300);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm]);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
        if (e.currentTarget.getAttribute("id") !== "productSearch") {
            setProductSearch([]);
        }
    };

    const onSubmit = (data) => {
        if (!props.editable) {
            let method = JSON.stringify({
                productId: selectedProducted.value,
                warehouseId: warehouseid,
                View_In_Home: data.View_In_Home,
                HomeSectionId: props.sectionid,
            });
            console.log(method);

            if (selectedProducted.value.length == 0) {
                setproductError(true);
                return;
            }
            Services.AddHomePageSectionProducts("POST", method, token)
                .then((res) => {
                    props.tableload(false);
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getwarehouseproducts(props.warehouseid);
                        Navigate(`/sections/${props.sectionid}?page=1`);
                        props.setpageCount(1);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    props.tableload(true);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            // updateHomePageProducts
            Services.updateHomePageProducts(
                "PUT",
                JSON.stringify({ View_In_Home: data.View_In_Home }),
                token,
                props.id.id
            )
                .then((res) => {
                    props.tableload(false);
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getwarehouseproducts(props.warehouseid);
                        Navigate(`/sections/${props.sectionid}?page=1`);
                        props.setpageCount(1);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    props.tableload(true);
                    // alert(err);
                    alert("something went wrong please try again");
                    // console.log(err);
                });
        }
    };
    const searchhandler = (e) => {
        setSearchTerm(e.target.value);
        setshow(true);
    };
    const buttonHandler = (e) => {
        setproductError(false);
        setSelectedProduct({
            name: e.currentTarget.getAttribute("name"),
            value: e.currentTarget.getAttribute("id"),
        });
        setshow(false);
        setSearchTerm(e.currentTarget.getAttribute("name"));
        setProductSearch([]);
    };
    const warehousefilter = (e) => {
        setwarehouseId(e.target.value);
        setSelectedProduct({
            name: "",
            value: "",
        });
        setSearchTerm("");
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update product" : "Add product"}
                        </h3>
                        <p>
                            {props.editable ? "Update product" : "Add product"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    {!props.editable && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productId">Warehouse</label>
                                <div
                                    className={`${dashboardclasses["select"]} ${dashboardclasses["product-search"]}`}>
                                    <select
                                        defaultValue={
                                            warehousedata[0]?.warehouseId
                                        }
                                        onChange={warehousefilter}>
                                        {warehousedata?.map((item) => (
                                            <option value={item?.warehouseId}>
                                                {item?.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productId">Product</label>
                                <div
                                    className={`${dashboardclasses["select"]} ${dashboardclasses["product-search"]}`}>
                                    <input
                                        type="text"
                                        onChange={searchhandler}
                                        value={searchTerm}
                                    />
                                    {productSearch.length > 0 && (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "product-items"
                                                ]
                                            }
                                            id="productSearch">
                                            {productSearch.map((item) => {
                                                return (
                                                    <button
                                                        onClick={buttonHandler}
                                                        id={item.value}
                                                        name={item.name}
                                                        type={"button"}>
                                                        {item.name}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {productError && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            Product is required!
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">View in Home</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                {...register("View_In_Home", {
                                    required: "View_In_Home is required",
                                })}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>

                            {errors?.View_In_Home && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.View_In_Home?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Product"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
