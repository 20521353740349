import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
import AddRole from "./addroles";
import { menuItems } from "../../Navbar/config";
function Warehouse() {
    const Navigate = useNavigate();
    const [editable, setEditable] = useState(false);
    let [loading, setLoading] = useState(true);
    const [showRole, setShowRole] = useState(false);
    const [adminroles, setadminroles] = useState([]);
    const [active, setactive] = useState();
    const [accessItems, setAccessItems] = useState([]);

    const [loadingaccesitem, setloadingaccessitems] = useState(true);
    const [roleid, setroleId] = useState();
    const [rolename, setrolename] = useState();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    // const userdata = JSON.parse(localStorage.getItem("admindata"));

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getAdminroles = () => {
        Services.getAdminroles("GET", null, token)
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.Status === 1) {
                    setadminroles(res.AdminRoles);
                    setactive(String(res.AdminRoles[0].roleName));
                    setroleId(String(res.AdminRoles[0].adminRoleId));
                    setAccessItems(res.AdminRoles[0].accessItems);
                    setloadingaccessitems(false);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAdminroles();
    }, []);

    const checkStatus = (role) => {
        let [activeroleData] = adminroles.filter(
            (item) => item.roleName == active
        );
        let name = role;
        let index = activeroleData?.accessItems?.findIndex(
            (item) => item == name
        );
        if (index !== -1) {
            return true;
        } else return false;
    };
    const activeHandler = (e) => {
        let rolename = e.currentTarget.getAttribute("rolename");
        let [activeroleData] = adminroles.filter((item) => {
            setloadingaccessitems(true);
            return item.roleName == rolename;
        });
        setTimeout(() => {
            setloadingaccessitems(false);
        }, 1000);
        setAccessItems(activeroleData.accessItems);
        setactive(rolename);
        setroleId(String(activeroleData.adminRoleId));
    };
    const statushandler = (e) => {
        let itemName = e.currentTarget.getAttribute("navi");
        console.log(itemName);
        if (e.target.checked) {
            setAccessItems((prev) => {
                console.log([...prev, itemName]);
                return [...prev, itemName];
            });
        } else {
            let newAccessItems = accessItems.filter(
                (item) => item !== itemName
            );
            console.log(newAccessItems);
            setAccessItems(newAccessItems);
        }
    };
    const updateHandler = () => {
        console.log(accessItems);
        let body = JSON.stringify({ accessItems: accessItems });
        Services.UpdateAdminroles("PUT", body, token, roleid)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    window.alert("re-login to reflect changes");
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const DeleteHandler = () => {
        // let body = JSON.stringify({ accessItems: { accessItems } });
        if (window.confirm("Are you sure you want to delete role")) {
            Services.DeleteAdminroles("Delete", null, token, roleid)
                .then((res) => {
                    console.log(res);
                    getAdminroles();
                    if (res.Status === 1) {
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        window.alert("re-login to reflect changes");
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    return (
        <>
            {showRole && (
                <AddRole
                    setmodal={setShowRole}
                    setadminroles={setadminroles}
                    editable={editable}
                    setEditable={setEditable}
                    getAdminroles={getAdminroles}
                    roleid={roleid}
                    adminroles={adminroles}
                    active={active}
                    setactive={setactive}
                    rolename={rolename}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="roles" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Roles</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["roles-wrapper"]
                                    }>
                                    <div
                                        className={
                                            dashboardclasses["roles-leftside"]
                                        }>
                                        <header>
                                            <h3>Available Roles</h3>
                                            <button
                                                onClick={() => {
                                                    setShowRole(true);
                                                }}>
                                                Add Role
                                            </button>
                                        </header>
                                        {adminroles.length > 0 ? (
                                            adminroles.map((item) => {
                                                return (
                                                    <div key={item.adminRoleId}>
                                                        <button
                                                            onClick={
                                                                activeHandler
                                                            }
                                                            className={`${
                                                                active ===
                                                                String(
                                                                    item.roleName
                                                                )
                                                                    ? dashboardclasses[
                                                                          "admin-btn-active"
                                                                      ]
                                                                    : dashboardclasses[
                                                                          "admin-btn-inactive"
                                                                      ]
                                                            } ${
                                                                dashboardclasses[
                                                                    "role-btn"
                                                                ]
                                                            }`}
                                                            roleid={
                                                                item.adminRoleId
                                                            }
                                                            rolename={
                                                                item.roleName
                                                            }>
                                                            {item.roleName}
                                                        </button>
                                                        <button
                                                            className={
                                                                dashboardclasses[
                                                                    "edit-btn"
                                                                ]
                                                            }
                                                            rolename={
                                                                item.roleName
                                                            }
                                                            onClick={(e) => {
                                                                activeHandler(
                                                                    e
                                                                );
                                                                setShowRole(
                                                                    true
                                                                );
                                                                setEditable(
                                                                    true
                                                                );
                                                            }}
                                                            roleid={
                                                                item.adminRoleId
                                                            }>
                                                            Edit
                                                        </button>
                                                        <button
                                                            className={
                                                                dashboardclasses[
                                                                    "del-btn"
                                                                ]
                                                            }
                                                            roleid={
                                                                item.adminRoleId
                                                            }
                                                            onClick={
                                                                DeleteHandler
                                                            }>
                                                            Delete
                                                        </button>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No Roles Found</p>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses["roles-rightside"]
                                        }>
                                        <header>
                                            <h3>{active}</h3>
                                        </header>
                                        {loadingaccesitem ? (
                                            <div
                                                className={
                                                    dashboardclasses["loader"]
                                                }>
                                                <ScaleLoader
                                                    color="black"
                                                    loading={true}
                                                    css={override}
                                                    size={150}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "role-operations"
                                                    ]
                                                }>
                                                <h3>Permissions</h3>

                                                {menuItems.map((menu) => (
                                                    <div
                                                        className={`${dashboardclasses["role-operation"]} ${dashboardclasses["grey-bg"]}`}>
                                                        <p>{menu.name}</p>
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "role-slider"
                                                                ]
                                                            }>
                                                            <label
                                                                className={
                                                                    dashboardclasses[
                                                                        "switch"
                                                                    ]
                                                                }>
                                                                <input
                                                                    navi={
                                                                        menu.name
                                                                    }
                                                                    onChange={
                                                                        statushandler
                                                                    }
                                                                    type="checkbox"
                                                                    defaultChecked={checkStatus(
                                                                        menu.name
                                                                    )}
                                                                />
                                                                <span
                                                                    className={`${dashboardclasses["slider"]} ${dashboardclasses["round"]}`}></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                                <button onClick={updateHandler}>
                                                    Update
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
