import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./Components/Login/login";
// import Signup from "./Components/Login/signup";
import ForgotPassword from "./Components/Login/forgotPassword";
import Warehouse from "./Components/dashboard/Warehouse/Warehouse";
import Category from "./Components/dashboard/Category/Category";
import Customers from "./Components/dashboard/Customers/Customers";
import Products from "./Components/dashboard/Products/Products";
import Orders from "./Components/dashboard/orders/Orders";
import Promocodes from "./Components/dashboard/promocode/Promocodes";
import Settings from "./Components/dashboard/settings";
import Banners from "./Components/dashboard/banners/banners";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDetails from "./Components/dashboard/Customers/userdetails";
import Adminuser from "./Components/dashboard/adminusers/Adminusers";
import OrderDetails from "./Components/dashboard/orders/OrderDetails";
import FranchiseEnquiry from "./Components/dashboard/Franchise/Franchise";
import Callbackforms from "./Components/dashboard/callBackForm/callbackform";
import Availability from "./Components/dashboard/AvailabilityForm/availability";
import Notify from "./Components/dashboard/notifyProducts/notify";
import Warehouseproducts from "./Components/dashboard/warehouseproducts/products";
import Supplier from "./Components/dashboard/Suppliers/Supplier";
import PurchaseOrder from "./Components/dashboard/purchaseOrder/purchaseOrder";
import Details from "./Components/dashboard/purchaseOrder/details";
import AddpurchaseOrder from "./Components/dashboard/purchaseOrder/addpurchaseOrder";
import Roles from "./Components/dashboard/roles/roles";
import SupplierPayments from "./Components/dashboard/supplierPayment/payment";
import Ledger from "./Components/dashboard/ledger/ledger";
import Stock from "./Components/dashboard/Stockvalue/stock";
import Dashboard from "./Components/dashboard/Dashboard/dashboard";
import Molecule from "./Components/dashboard/Molecule/molecule";
import Companies from "./Components/dashboard/Company/company";
import Invoice from "./Components/dashboard/invoice/invoice";
import DealsofTheDay from "./Components/dashboard/DealsOfTheDay/products";
import InternalTransfer from "./Components/dashboard/warehouseproducts/InternalTransfer";
import Reports from "./Components/dashboard/Reports";
import ScrollToTop from "./Components/utils/scrollToTop";
import NewTransfer from "./Components/dashboard/warehouseproducts/NewTransfer";
// import useScrollToTop from "./Components/utils/scrollToTop";
import TransferDetails from "./Components/dashboard/warehouseproducts/transfer-details";
import Config from "./Components/dashboard/configData/config";
import MoleculeMg from "./Components/dashboard/mgmolecule/molecule.jsx";
import CategoryTypes from "./Components/dashboard/cateogryTypes/Category";
import HomepageSections from "./Components/dashboard/HomepageSections/sections"
import Userinfo from './Components/dashboard/DealsOfTheDay/userinfo'
import FailedOrders from './Components/dashboard/DealsOfTheDay/failedOrders'
import Users from './Components/dashboard/MebuzzUsers/medbuzz'
import SearchHit from "./Components/dashboard/MebuzzUsers/searchHit"
import ProductDetails from './Components/dashboard/warehouseproducts/ProductDetails'
function App() {
    return (
        <>
            <ToastContainer />

            <ScrollToTop>
                <Routes>
                    <Route exaact path="/" element={<Login />} />
                    {/* <Route exaact path="/createaccount" element={<Signup />} /> */}
                    <Route
                        exaact
                        path="/forgotpassword"
                        element={<ForgotPassword />}
                    />
                    <Route path="/mgmolecules" element={<MoleculeMg />} />
                    <Route exact path="/Warehouse" element={<Warehouse />} />
                    <Route exact path="/categories" element={<Category />} />
                    <Route
                        exact
                        path="/categories/seo"
                        element={<Category />}
                    />
                    <Route exact path="/customers" element={<Customers />} />
                    <Route
                        exact
                        path="/customers/:id"
                        element={<UserDetails />}
                    />
                    <Route exact path="/banners" element={<Banners />} />
                    <Route exact path="/products" element={<Products />} />
                    <Route exact path="/orders" element={<Orders />} />
                    <Route
                        exaact
                        path="/orders/:id"
                        element={<OrderDetails />}
                    />
                    <Route exact path="/promocodes" element={<Promocodes />} />
                    <Route exact path="/users" element={<Adminuser />} />
                    <Route exact path="/product-details/:productid/:warehouseidParam" element={<ProductDetails />} />
                    <Route
                        exact
                        path="/internalTransfer"
                        element={<InternalTransfer />}
                    />

                    <Route
                        exact
                        path="/FranchiseEnquiry"
                        element={<FranchiseEnquiry />}
                    />
                    <Route
                        exact
                        path="/Callbackforms"
                        element={<Callbackforms />}
                    />
                    <Route
                        exact
                        path="/availabilityForms"
                        element={<Availability />}
                    />
                    <Route exact path="/notifyproducts" element={<Notify />} />
                    <Route
                        exact
                        path="/warehouseproducts"
                        element={<Warehouseproducts />}
                    />
                    <Route exact path="/suppliers" element={<Supplier />} />
                    <Route
                        exact
                        path="/purchaseorder"
                        element={<PurchaseOrder />}
                    />
                    <Route
                        exact
                        path="/AddpurchaseOrder"
                        element={<AddpurchaseOrder />}
                    />
                    <Route
                        exact
                        path="/user-info"
                        element={<Userinfo />}
                    />
                    <Route
                        exact
                        path="/failedorders"
                        element={<FailedOrders />}
                    />
                    <Route
                        exact
                        path="/purchaseOrderDetails/:id"
                        element={<AddpurchaseOrder />}
                    />
                    <Route exact path="/roles" element={<Roles />} />
                    <Route
                        exact
                        path="/poentryDetails/:id"
                        element={<Details />}
                    />
                    <Route
                        exact
                        path="/supplierpayments"
                        element={<SupplierPayments />}
                    />
                    <Route exact path="/config" element={<Config />} />
                    <Route
                        exact
                        path="/ledger/:prodid/:warehouseid"
                        element={<Ledger />}
                    />
                    <Route exact path="/Dashboard" element={<Dashboard />} />
                    <Route exact path="/Molecules" element={<Molecule />} />
                    <Route exact path="/Companies" element={<Companies />} />
                    <Route
                        exact
                        path="/sections/:sectionid"
                        element={<DealsofTheDay />}
                    />
                    <Route exact path="/Reports" element={<Reports />} />
                    <Route exact path="/Invoice" element={<Invoice />} />
                    <Route exact path="/stockvalue" element={<Stock />} />
                    <Route
                        exact
                        path="/newtransfer"
                        element={<NewTransfer />}
                    />
                    <Route path="/mo?page=1" element={<MoleculeMg />} />
                    <Route
                        exact
                        path="/transfer-details/:id"
                        element={<TransferDetails />}
                    />
                    <Route exaact path="/settings" element={<Settings />} />
                    <Route
                        exaact
                        path="/category-types"
                        element={<CategoryTypes />}
                    />
                    <Route
                        exaact
                        path="/homepage-sections"
                        element={<HomepageSections />}
                    />
                    <Route
                        exaact
                        path="/medbuzz-users"
                        element={<Users />}
                    />
                    <Route
                        exaact
                        path="/SearchHit"
                        element={<SearchHit />}
                    />
                </Routes>
            </ScrollToTop>
        </>
    );
}

export default App;
