import { FaWarehouse } from "react-icons/fa";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CgShoppingBag } from "react-icons/cg";
import {
    AiOutlineCompass,
    AiOutlineGift,
    AiOutlineStock,
    AiOutlineForm,
} from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { GiNewspaper } from "react-icons/gi";
import { FiUsers } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import {
    MdNotes,
    MdPayment,
    MdOutlineSpaceDashboard,
    MdOutlineCorporateFare,
} from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import { GiDatabase, GiMolecule } from "react-icons/gi";
import { FaSupple } from "react-icons/fa";
import { BiPurchaseTag } from "react-icons/bi";
import { SiMoleculer } from "react-icons/si";
import { GrUserAdmin } from "react-icons/gr";
import { FaFileInvoice } from "react-icons/fa";
import { FaIdeal } from "react-icons/fa";
import uuid from "react-uuid";
import { GrNotes, GrConfigure } from "react-icons/gr";
import { IoLogoBuffer } from "react-icons/io";
import { BsIntersect } from 'react-icons/bs'
export const menuItems = [
    {
        id: uuid(),
        name: "Warehouse",
        menuLink: "/Warehouse?page=1",
        active: "warehouse",
        icon: FaWarehouse,
    },
    {
        id: uuid(),
        name: "Dashboard",
        menuLink: "/Dashboard",
        active: "dashboard",
        icon: MdOutlineSpaceDashboard,
    },
    {
        id: uuid(),
        name: "Categories",
        menuLink: "/categories?page=1",
        active: "categories",
        icon: AiOutlineUnorderedList,
    },
    {
        id: uuid(),
        name: "Category Types",
        menuLink: "/category-types?page=1",
        active: "categories-type",
        icon: IoLogoBuffer,
    },
    {
        id: uuid(),
        name: "Customers",
        menuLink: "/customers?customer-type=2&page=1",
        active: "customers",
        icon: BsPeople,
    },
    {
        id: uuid(),
        name: "Suppliers",
        menuLink: "/suppliers?page=1",
        active: "suppliers",
        icon: FaSupple,
    },
    {
        id: uuid(),
        name: "Banners",
        menuLink: "/banners?page=1",
        active: "banners",
        icon: GiNewspaper,
    },
    {
        id: uuid(),
        name: "Master Products",
        menuLink: "/products",
        active: "products",
        icon: CgShoppingBag,
    },
    {
        id: "SEO",
        name: "SEO",
        menuLink: "/products",
        active: "a",
        icon: CgShoppingBag,
    },
    {
        id: uuid(),
        name: "Warehouse Products",
        menuLink: "/warehouseproducts?page=1",
        active: "warehouseproducts",
        icon: GiDatabase,
    },
    {
        id: uuid(),
        name: "Orders",
        menuLink: "/orders?order-type=2&page=1",
        active: "orders",
        icon: AiOutlineCompass,
    },
    {
        id: uuid(),
        name: "Promocode",
        menuLink: "/promocodes?page=1",
        active: "promocodes",
        icon: AiOutlineGift,
    },
    {
        id: uuid(),
        name: "Franchise Enquiry",
        menuLink: "/FranchiseEnquiry?enquiry-type=1&page=1",
        active: "FranchiseEnquiry",
        icon: AiOutlineForm,
    },
    {
        id: uuid(),
        name: "Callback Forms",
        menuLink: "/Callbackforms?enquiry-type=1&page=1",
        active: "Callbackforms",
        icon: CgNotes,
    },
    {
        id: uuid(),
        name: "Availability Form",
        menuLink: "/availabilityForms?enquiry-type=1&page=1",
        active: "availabilityForm",
        icon: MdNotes,
    },
    {
        id: uuid(),
        name: "Notfiy Products",
        menuLink: "/notifyproducts?page=1",
        active: "notifyproducts",
        icon: RiProductHuntLine,
    },
    {
        id: uuid(),
        name: "Purchase Order",
        menuLink: "/purchaseorder?page=1",
        active: "purchaseorder",
        icon: BiPurchaseTag,
    },
    {
        id: uuid(),
        name: "Supplier Payments",
        menuLink: "/supplierpayments?page=1",
        active: "supplierpayments",
        icon: MdPayment,
    },
    {
        id: 'Medbuzz',
        name: "Medbuzz",
        menuLink: "/homepage-sections?page=1",
        active: "deals",
        icon: BsIntersect,
    },
    {
        id: uuid(),
        name: "Admin Users",
        menuLink: "/users?page=1",
        active: "user",
        icon: FiUsers,
    },
    // {
    //     id: uuid(),
    //     name: "Stock",
    //     menuLink: "/stockvalue",
    //     active: "Stock",
    //     icon: AiOutlineStock,
    // },
    {
        id: uuid(),
        name: "Invoice",
        menuLink: "/Invoice?page=1",
        active: "Invoice",
        icon: FaFileInvoice,
    },
    {
        id: uuid(),
        name: "Molecules",
        menuLink: "/Molecules?page=1",
        active: "Molecules",
        icon: SiMoleculer,
    },
    {
        id: uuid(),
        name: "One MG Molecule",
        menuLink: "/mgmolecules?page=1",
        active: "oneMG",
        icon: GiMolecule,
    },
    {
        id: uuid(),
        name: "Reports",
        menuLink: "/Reports",
        active: "Reports",
        icon: GrNotes,
    },
    {
        id: uuid(),
        name: "Companies",
        menuLink: "/Companies?page=1",
        active: "Companies",
        icon: MdOutlineCorporateFare,
    },
    {
        id: uuid(),
        name: "Roles",
        menuLink: "/roles",
        active: "roles",
        icon: GrUserAdmin,
    },
    {
        id: uuid(),
        name: "Config",
        menuLink: "/config?page=1",
        active: "Config",
        icon: GrConfigure,
    },
    {
        id: uuid(),
        name: "Settings",
        menuLink: "/settings",
        active: "settings",
        icon: IoSettingsOutline,
    },
];
