import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import UpdateCallBack from "./update";
import { FaRegEdit } from "react-icons/fa";
import PushModal from "./pushmodal";
function Warehouse() {
    const Navigate = useNavigate();
    const [Navactive, setNavactive] = useState("1");
    const [id, setid] = useState();
    const [callbackitems, setcallbackitems] = useState([]);
    const [modal, setmodal] = useState(false);
    const [userid, setuserid] = useState("");
    const [callbackSearchItmes, setcallbackSearchItmes] = useState([]);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [pushmodal, setpushmodal] = useState(false);
    const [params] = useSearchParams();
    const [tableload, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const type = params.get("enquiry-type");
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getCallbackForm = () => {
        Services.getCallbackForm("GET", null, token)
            .then((res) => {
                setLoading(false);
                // console.log(res);
                if (res.Status === 1) {
                    setcallbackitems(res.CallBackForms);
                    setcallbackSearchItmes(
                        res.CallBackForms.filter(
                            (item) => item.status === Number(type)
                        )
                    );
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getCallbackForm();
        setNavactive(params.get("enquiry-type"));
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [callbackSearchItmes]);
    const edithandler = (e) => {
        setid(e.currentTarget.getAttribute("id"));
        setmodal(true);
    };
    const radioformatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtnsview}>
                {cellcontent.status == 2 ? (
                    <p
                        style={{
                            margin: 0,
                            padding: 0,
                            backgroundColor: "lightgreen",
                            color: "white",
                            padding: "0.5rem",
                        }}>
                        Resolved
                    </p>
                ) : (
                    <button
                        onClick={edithandler}
                        id={cellcontent.callBackId}
                        style={{
                            display: "flex",
                            gap: "0.5rem",
                        }}>
                        <p style={{ margin: "0", textDecoration: "underLine" }}>
                            {" "}
                            Resolve
                        </p>
                    </button>
                )}
            </div>
        );
    };
    const pushHandler = (e) => {
        let userid = e.currentTarget.getAttribute("userid");
        let id = e.currentTarget.getAttribute("id");
        setid(id);
        setuserid(userid);
        setpushmodal(true);
    };
    const pushformatter = (cell, cellcontent) => {
        // console.log(typeof cellcontent.status);
        return (
            <div className={dashboardclasses.tablebtnsview}>
                <button
                    onClick={pushHandler}
                    id={cellcontent.callBackId}
                    userid={cellcontent.userId}
                    style={{
                        display: "flex",
                        gap: "0.5rem",
                    }}>
                    <FaRegEdit size={18} />
                    <p style={{ margin: "0" }}> Push </p>
                </button>
            </div>
        );
    };
    const detailsFormatter = (cell, cellContent) => {
        return (
            <p>
                {cellContent.name} <br />
                {cellContent.emailId} <br />
                {cellContent.contactNumber} <br />
            </p>
        );
    };
    let columns = [
        {
            dataField: "name",
            text: "USER",
            sort: false,
            formatter: detailsFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "reason",
            text: "REASON",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "description",
            text: "DESCRIPTION",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "resolutionComments",
            text: "RESOLUTION COMMENTS",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "status",
            text: "STATUS",
            sort: false,
            formatter: radioformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Notify",
            text: "",
            sort: false,
            formatter: pushformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    if (type != 2) {
        columns = columns.filter(
            (item) => item.dataField != "resolutionComments"
        );
    }
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/Callbackforms?enquiry-type=${Navactive}&page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        const search = callbackitems.filter((item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        // console.log(search);

        setTableLoad(true);
        Navigate(`/Callbackforms?enquiry-type=${Navactive}&page=${1}`);
        setpageCount(1);
        setcallbackSearchItmes(search);
    };

    const navactivehandler = (e) => {
        let status = e.currentTarget.getAttribute("id");
        setNavactive(status);
        Navigate(`/Callbackforms?enquiry-type=${status}&page=1`);
        setcallbackSearchItmes(
            callbackitems.filter((item) => item.status === Number(status))
        );
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {modal && (
                <UpdateCallBack
                    // status={}
                    setmodal={setmodal}
                    getCallbackForm={getCallbackForm}
                    id={id}
                    setNavactive={setNavactive}
                />
            )}
            {pushmodal && (
                <PushModal setmodal={setpushmodal} userid={userid} id={id} />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="Callbackforms" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Callback Form</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["customer-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by name"
                                        onChange={searchHandler}
                                    />
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="1"
                                        className={
                                            Navactive === "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Pending
                                    </button>
                                    <button
                                        id="2"
                                        className={
                                            Navactive === "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Past
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={callbackSearchItmes}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
