import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [warehousedata, setWarehouses] = useState([]);
    const [file, setFile] = useState("");
    const [productSearch, setProductSearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedProducted, setSelectedProduct] = useState({
        name: "",
        value: "",
    });
    const [products, setproducts] = useState([]);
    const [productError, setproductError] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        console.log("ss");
        e.stopPropagation();
        if (e.currentTarget.getAttribute("id") !== "productSearch") {
            setProductSearch([]);
        }
    };
    console.log(props.TransferDetails);
    const onSubmit = (data) => {
        Services.stockUpdateTransfer(
            "PUT",
            JSON.stringify({
                status: Number(data.status),
                FromWarehouseName: props.TransferDetails.fromWarehouse,
                fromWarehouseId: props.TransferDetails.fromWarehouseId,
                ToWarehouseName: props.TransferDetails.toWarehouse,
                toWarehouseId: props.TransferDetails.toWarehouseId,
                transferProducts: props.TransferDetails?.products,
                initiatedUserID: props.TransferDetails.initiatedUserID,
            }),
            token,
            props.transferId
        )
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getStockTransferbyID(props.warehouseId);
                    Navigate(`/internalTransfer?transfer-type=${1}&page=1`);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update product" : "Add product"}
                        </h3>
                        <p>
                            {props.editable ? "Update product" : "Add product"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="status">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "status is required",
                                })}>
                                <option value={""} disabled>
                                    Status
                                </option>
                                {/* <option value={1}>In Process</option> */}
                                <option value={1}>Complete</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
