import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../App.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GrFormView } from "react-icons/gr";
import Addshipment from "./CreateShipment";
import Shipment from "./shipmentDetails";
function Products() {
    const [editable, setEditable] = useState(false);
    const SelectedRef = useRef();
    const Navigate = useNavigate();
    const [shipmentDetails, setshipmentDetails] = useState(false);
    const [shipmentForm, setShipmentForm] = useState(false);
    const [warehousedata, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWareHouse] = useState("");
    const [tableLoad, setTableLoad] = useState(true);
    const [params] = useSearchParams();
    let [loading, setLoading] = useState(true);
    const [Navactive, setNavactive] = useState("2");
    const [pageCount, setpageCount] = useState(1);
    const [orders, setOrders] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState({});
    const type = params.get("order-type");
    const [ordersSearch, setOrdersSearch] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let adminData = JSON.parse(localStorage.getItem("admindata"));
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const addshipmentHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        let number = e.currentTarget.getAttribute("number");
        setSelectedOrderDetails({ id, name, number });
        setShipmentForm((prev) => !prev);
    };

    const getorders = (id) => {
        Services.getOrdersById("GET", null, token, id)
            .then((res) => {
                console.log(res);
                setLoading(false);
                // setTabloader(false);
                setTableLoad(false);

                if (res.Status === 1) {
                    setOrders(res.Orders);
                    if (type == 4) {
                        setOrdersSearch(
                            res.Orders.filter(
                                (item) =>
                                    item.orderStatus === 7 ||
                                    item.orderStatus === 5
                            )
                        );
                    } else {
                        setOrdersSearch(
                            res.Orders.filter(
                                (item) => item.orderStatus === Number(type)
                            )
                        );
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        if (adminData?.warehouseId) {
            setWarehouses([
                {
                    warehouseid: adminData?.warehouseId,
                    warehouseName: adminData?.warehouseName,
                },
            ]);
            getorders(adminData?.warehouseId);
            setSelectedWareHouse(adminData?.warehouseId);
            return;
        }
        Services.getwarehouses("GET", null, token)

            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                    getorders(response.warehouses[0].warehouseId);
                    setSelectedWareHouse(response.warehouses[0].warehouseId);
                    setLoading(false);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setNavactive(params.get("order-type"));

        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [ordersSearch]);
    const Idformatter = (cell, cellcontent, index, formatExtraData) => {
        return (
            <div>
                <p className={dashboardclasses["idformatter"]}>{cell}</p>
            </div>
        );
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return <Moment format="MMMM Do YYYY, hh:mm a">{date}</Moment>;
    };
    const viewOrderDetails = (e) => {
        const id = e.currentTarget.getAttribute("orderid");
        Navigate(`/orders/${id}?order-type=${params.get("order-type")}`);
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.view}>
                <button
                    onClick={viewOrderDetails}
                    orderid={cellcontent.orderId}>
                    <GrFormView
                        size={22}
                        color="grey"
                        id={cellcontent.userId}
                    />
                    {/* View */}
                </button>
            </div>
        );
    };
    const priceFormatter = (cell) => {
        //className={dashboardclasses["priceformatter"]}
        return (
            <div className={dashboardclasses["price-wrapper"]}>
                <p>₹ {cell}</p>
            </div>
        );
    };
    const shiprocketDetails = (e) => {
        let trackingurl = e.currentTarget.getAttribute("trackingurl");
        let ShiprocketOrderID =
            e.currentTarget.getAttribute("ShiprocketOrderID");
        setshipmentDetails(true);
        setSelectedOrderDetails({ trackingurl, ShiprocketOrderID });
    };
    const Shipmentformatter = (cell, cellContent) => {
        return cellContent.isShipmentCreated == 0 ? (
            <button
                className={dashboardclasses["add-shipment"]}
                onClick={addshipmentHandler}
                id={cellContent.orderId}
                name={cellContent.name}
                number={cellContent.contactNumber}>
                +Add Shipment
            </button>
        ) : (
            <button
                className={dashboardclasses["add-shipment"]}
                ShiprocketOrderID={cellContent.ShiprocketOrderID}
                trackingurl={cellContent.trackingUrl}
                onClick={shiprocketDetails}>
                {" "}
                Shipment Details
            </button>
        );
    };
    const Invoice = (cell) => {
        return cell == "NA" ? (
            "NA"
        ) : (
            <a href={cell} color="transparent" target="_blank">
                Invoice
            </a>
        );
    };
    const columns = [
        {
            dataField: "orderNumber",
            text: "ORDER NUMBER",
            sort: false,
            // cell: (row, index) => index + 1,
            formatter: Idformatter,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        },
        {
            dataField: "name",
            text: "CUSTOMER NAME",
            sort: false,
            // cell: (row, index) => index + 1,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        },
        {
            dataField: "contactNumber",
            text: "MOBILE NUMBER",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "paymentType",
            text: "PAYMENT TYPE",
            sort: false,
            formatter: (cell) => {
                if (cell == 1) {
                    return "COD";
                } else {
                    return "ONLINE";
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "createdAt",
            text: "ORDER DATE TIME",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "grandTotal",
            text: "AMOUNT",
            sort: false,
            formatter: priceFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "invoiceFile",
            text: "Invoice",
            sort: false,
            formatter: Invoice,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ORDER DETAILS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
        {
            dataField: "SHIPMENT",
            text: "SHIPMENT",
            sort: false,
            formatter: Shipmentformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/orders?order-type=${Navactive}&page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        const search = orders.filter((item) =>
            String(item.contactNumber).includes(e.target.value)
        );
        setOrdersSearch(search);
        if (e.target.value.length === 0) {
            setOrdersSearch(orders.filter((item) => item.orderStatus === 2));
        }
    };

    const navactivehandler = (e) => {
        let status = e.currentTarget.getAttribute("id");
        setNavactive(status);

        Navigate(`/orders?order-type=${status}&page=1`);
        if (status === String(4)) {
            setOrdersSearch(
                orders.filter(
                    (item) => item.orderStatus === 7 || item.orderStatus === 5
                )
            );
            return;
        }
        setOrdersSearch(
            orders.filter((item) => item.orderStatus === Number(status))
        );
    };
    const warehousefilter = (e) => {
        setTableLoad(true);
        setSelectedWareHouse(e.target.value);
        getorders(e.target.value);
        Navigate(`/orders?order-type=2&page=1`);
        setNavactive("2");
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {shipmentDetails && (
                <Shipment
                    setmodal={setshipmentDetails}
                    id={selectedOrderDetails?.ShiprocketOrderID}
                    trackurl={selectedOrderDetails?.trackingUrl}
                />
            )}
            {shipmentForm && (
                <Addshipment
                    setmodal={setShipmentForm}
                    editable={editable}
                    setEditable={setEditable}
                    id={selectedOrderDetails.id}
                    getorders={getorders}
                    selectedWarehouse={selectedWarehouse}
                    number={selectedOrderDetails.number}
                    name={selectedOrderDetails.name}
                    warehouses={warehousedata}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="orders" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Orders</h3>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Mobile number"
                                        onChange={searchHandler}
                                    />

                                    <select
                                        ref={SelectedRef}
                                        defaultValue={
                                            warehousedata[0].warehouseId
                                        }
                                        onChange={warehousefilter}>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="2"
                                        className={
                                            Navactive == "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        New
                                    </button>
                                    <button
                                        id="3"
                                        className={
                                            Navactive == "3" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Need Delivery
                                    </button>
                                    <button
                                        id="1"
                                        className={
                                            Navactive == "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Delivered
                                    </button>
                                    <button
                                        id="4"
                                        className={
                                            Navactive == "4" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Cancel
                                    </button>
                                    <button
                                        id="6"
                                        className={
                                            Navactive == "6" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Returned Orders
                                    </button>
                                </div>

                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={ordersSearch}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;
