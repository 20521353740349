import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GrFormView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import AddTransfer from "./NewTransfer";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
// import { BsThreeDotsVertical } from "react-icons/bs";
import TransferEdit from "./updateTransfer";
function Warehouse() {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [params] = useSearchParams();
    const statusRef = useRef();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    const [StockiIternalTransfer, setStockiIternalTransfer] = useState({
        products: [],
    });
    const [tableload, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);

    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };

    const getStockTransferbyID = (id) => {
        Services.stockDetailsbyId("GET", null, token, id)
            .then((res) => {
                console.log(res);

                setLoading(false);
                setTableLoad(false);
                if (res.Status === 1) {
                    setStockiIternalTransfer(res.StockiIternalTransfer);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getStockTransferbyID(id);

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [StockiIternalTransfer]);
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return <Moment format="MMMM Do YYYY, hh:mm a">{date}</Moment>;
    };
    const columns = [
        {
            dataField: "productId",
            text: "PRODUCT ID",
            sort: false,
            style: {
                width: "15%",
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "productName",
            text: "PRODUCT NAME",
            sort: false,
            style: {
                width: "15%",
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "TotalSelectedQuantity",
            text: "TRANSFER QTY",
            sort: false,
            style: {
                width: "15%",
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "",
            text: "",
            sort: false,
            style: {
                width: "70%",
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });

            Navigate(`/transfer-details/${id}?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];

    const emptyDataMessage = () => {
        return "No Data to Display";
    };

    const handleRowExpand = (row, isExpand) => {
        console.log(row, isExpand ? "expanded" : "collapsed");
    };

    //sub table

    // Define a function to render the expandable row content
    const expandRowContent = (row) => {
        const expandedColumns = [
            { dataField: "POitemId", text: "POITEM ID" },
            { dataField: "batchNumber", text: "BATCH NUMBER" },
            { dataField: "SupplierName", text: "SUPPLIER NAME" },
            { dataField: "SelectedQuantity", text: "QUANTITY" },
            { dataField: "expiry", text: "EXPIRY" },
            // Add more columns if needed
        ];

        return (
            <BootstrapTable
                keyField="id"
                data={row.PoItems}
                columns={expandedColumns}
            />
        );
    };

    return (
        <>
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="warehouseproducts" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div
                            className={
                                dashboardclasses["internal-transfer-heading"]
                            }>
                            <h3>
                                {StockiIternalTransfer?.fromWarehouse}
                                {`=>${StockiIternalTransfer?.toWarehouse}`}
                            </h3>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                {/* <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                </div> */}

                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`${"category-table"} ${"internal-transfer"}`}>
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={
                                                StockiIternalTransfer?.products
                                            }
                                            expandRow={{
                                                renderer: expandRowContent,
                                                onExpand: handleRowExpand,
                                            }}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            // rowEvents={tableRowEvents}
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
