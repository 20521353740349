import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GrFormView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Approve from "./Approve";
import Pushmodal from "./pushmodal";
import { FaRegEdit } from "react-icons/fa";
import Moment from "react-moment";
import Swal from "sweetalert";
function Warehouse() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const statusRef = useRef();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [searchusers, setSearchusers] = useState([]);
    const [Navactive, setNavactive] = useState("2");
    const [tableload, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [userid, setUserid] = useState("");
    const [commentModal, setCommentModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [status, setStatus] = useState("");
    // const [userid,set]
    const [pushModal, setPushModal] = useState(false);
    const [notificationType, setNotificationType] = useState(7);
    const type = params.get("customer-type");
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };

    const getusers = () => {
        Services.getUsers("GET", null, token)
            .then((res) => {
                setLoading(false);
                setTableLoad(false);
                // console.log(res);
                if (res.Status === 1) {
                    const compareDates = (a, b) => {
                        // Convert the UTC date strings to date objects.
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);

                        // Convert the date objects from UTC to IST.
                        dateA.setHours(dateA.getHours() + 5);
                        dateA.setMinutes(dateA.getMinutes() + 30);
                        dateB.setHours(dateB.getHours() + 5);
                        dateB.setMinutes(dateB.getMinutes() + 30);

                        // Compare the date objects in descending order.
                        return dateB - dateA;
                    };
                    setUsers(res.Users.sort(compareDates));

                    let pendingUsers = res.Users.filter(
                        (item) => item.userStatus == type
                    );

                    let sortedData = pendingUsers.sort(compareDates);
                    setSearchusers(sortedData);
                    console.log(sortedData);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers();
        setNavactive(params.get("customer-type"));

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [searchusers]);
    const viewuserdetails = (e) => {
        Navigate(`/customers/${e.currentTarget.getAttribute("id")}`);
    };
    const DeleteUser = (userId) => {
        Swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this user!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setTableLoad(true);
                Services.deleteUser("DELETE", null, token, userId)
                    .then((response) => {
                        if (response.Status === 1) {
                            setTableLoad(false);
                            Swal({
                                title: "user Deleted successfully",
                                icon: "success",
                            });
                            getusers();
                        }
                    })
                    .catch((err) => {
                        toast.error("error in Deleting Data ", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    });
            }
        });
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div
                className={dashboardclasses.tablebtns}
                style={{ marginLeft: "1rem" }}>
                <button>
                    <GrFormView
                        size={22}
                        color="grey"
                        id={cellcontent.userId}
                        onClick={viewuserdetails}
                    />
                </button>
            </div>
        );
    };

    const radioformatter = (cell, cellcontent) => {
        return (
            <div>
                <select
                    style={{ height: "2rem" }}
                    onChange={clickHandler}
                    id={cellcontent.userId}
                    status={cellcontent.userStatus}
                    defaultValue={cellcontent.userStatus}
                    ref={statusRef}>
                    <option value={2} disabled>
                        pending
                    </option>
                    <option value={1}>Approve</option>
                    <option value={3}>Block</option>
                    <option value={4}>Delete</option>
                </select>
            </div>
        );
    };
    const clickHandler = (e) => {
        const userid = e.currentTarget.getAttribute("id");
        setStatus(e.currentTarget.getAttribute("status"));

        setUserid(userid);
        let status = e.target.value;
        console.log(status);
        if (status == 1) {
            setCommentModal(true);
            return;
        }
        if (status == 4) {
            DeleteUser(userid);
            return;
        }
        setTableLoad(true);
        const updatedbody = JSON.stringify({ userStatus: status });
        Services.updateuser("PUT", updatedbody, token, userid)
            .then((res) => {
                // console.log(res);
                if (res.Status === 1) {
                    getusers();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const pushHandler = (e) => {
        let userid = e.currentTarget.getAttribute("userId");
        setPushModal(true);
        setUserid(userid);
        setNotificationType(7);
    };
    const bulknotifyHandler = (e) => {
        setPushModal(true);
        setNotificationType(8);
    };
    const pushformatter = (cell, cellcontent) => {
        // console.log(typeof cellcontent.status);
        return (
            <div className={dashboardclasses.tablebtnsview}>
                <button
                    onClick={pushHandler}
                    // id={cellcontent.notifyId}
                    userid={cellcontent.userId}
                    style={{
                        display: "flex",
                        gap: "0.5rem",
                    }}>
                    <FaRegEdit size={18} />
                    <p style={{ margin: "0" }}> Push </p>
                </button>
            </div>
        );
    };
    const nameformatter = (cell, cellContent) => {
        return (
            <p>
                {cellContent.ownerName}

                <br />
                {cellContent.emailId}
                <br />
                {cellContent.mobileNumber}
            </p>
        );
    };
    const dateFormatter = (cell, cellContent) => {
        return (
            <Moment format="MMMM Do YYYY, h:mm:ss a">{new Date(cell)}</Moment>
        );
    };
    const columns = [
        {
            dataField: "ownerName",
            text: "OWNER NAME",
            sort: false,
            formatter: nameformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "shopName",
            text: "SHOP NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "userType",
            text: "USER TYPE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "createdAt",
            text: "CREATED AT",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "userStatus",
            text: "STATUS",
            sort: false,
            formatter: radioformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
        {
            dataField: "Notify",
            text: "",
            sort: false,
            formatter: pushformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/customers?customer-type=${Navactive}&page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    // const defaultSorted = [
    //     {
    //         dataField: "createdAt",
    //         order: "desc",
    //     },
    // ];
    const searchHandler = (e) => {
        setSearchTerm(e.target.value);
        const search = users
            .filter(
                (item) =>
                    item.ownerName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    item.mobileNumber
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    item.emailId
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
            )
            .filter((item) => item.userStatus == type);
        setTableLoad(true);
        Navigate(`/customers?customer-type=${Navactive}&page=${1}`);
        setpageCount(1);
        setSearchusers(search);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };

    const navactivehandler = (e) => {
        let status = e.currentTarget.getAttribute("id");

        setNavactive(status);
        let newUsers = users.filter((item) => item.userStatus == status);
        if (searchTerm.length > 0) {
            newUsers = newUsers.filter(
                (item) =>
                    item.ownerName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    item.mobileNumber
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    item.emailId
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
            );
        }
        const compareDates = (a, b) => {
            // Convert the UTC date strings to date objects.
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            // Convert the date objects from UTC to IST.
            dateA.setHours(dateA.getHours() + 5);
            dateA.setMinutes(dateA.getMinutes() + 30);
            dateB.setHours(dateB.getHours() + 5);
            dateB.setMinutes(dateB.getMinutes() + 30);

            // Compare the date objects in descending order.
            return dateB - dateA;
        };
        let sortedData = newUsers.sort(compareDates);
        setSearchusers(sortedData);
        console.log(sortedData);
        Navigate(`/customers?customer-type=${status}&page=1`);
    };

    return (
        <>
            {pushModal && (
                <Pushmodal
                    setmodal={setPushModal}
                    userid={userid}
                    notificationType={notificationType}
                />
            )}
            {commentModal && (
                <Approve
                    setmodal={setCommentModal}
                    userid={userid}
                    setTableLoad={setTableLoad}
                    tableload={tableload}
                    currentref={statusRef}
                    status={status}
                    getusers={getusers}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="customers" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Customers</h3>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form-two"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Name/email/phone"
                                        onChange={searchHandler}
                                    />
                                    <button onClick={bulknotifyHandler}>
                                        Bulk Notifications
                                    </button>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="2"
                                        className={
                                            Navactive === "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Pending for Approval
                                    </button>
                                    <button
                                        id="1"
                                        className={
                                            Navactive === "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Approved Customers
                                    </button>
                                    <button
                                        id="3"
                                        className={
                                            Navactive === "3" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Blocked Customers
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchusers}
                                            columns={columns}
                                            // defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
