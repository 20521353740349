import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [warehousedata, setWarehouses] = useState([]);
    const [file, setFile] = useState("");
    const [base64, setbase64] = useState("");
    const [productSearch, setProductSearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedProducted, setSelectedProduct] = useState({
        name: "",
        value: "",
    });
    const [products, setproducts] = useState([]);
    const [productError, setproductError] = useState(false);
    useEffect(() => {
        Services.getwarehouses("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setWarehouses(res.warehouses);
                    Services.getProducts("GET", null, token).then(
                        (response) => {
                            if (response.Status === 1) {
                                setproducts(
                                    response.Products.map((item) => {
                                        return {
                                            name: item.productName,
                                            value: item.productId,
                                        };
                                    })
                                );
                            } else if (response.Status === 0) {
                                toast.error(`${response.Message}`, {
                                    position: "top-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }
                    );
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        if (props.editable) {
            Services.getWarehouseProductsbyid(
                "GET",
                null,
                token,
                props.id.warehouseid,
                props.id.productid
            )
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        reset({
                            ...res.Product,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message == "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        console.log("ss");
        e.stopPropagation();
        console.log(e.currentTarget.getAttribute("id"));
        if (e.currentTarget.getAttribute("id") !== "productSearch") {
            setProductSearch([]);
        }
    };

    const onSubmit = (data) => {
        console.log(data);

        let method = JSON.stringify({
            ...data,
            productId: selectedProducted.value,
        });
        if (!props.editable) {
            if (selectedProducted.name.length === 0) {
                setproductError(true);
                return;
            }
            Services.addWrehouseProducts("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getwarehouses(props.warehouseid);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateWarehouseProducts(
                "PUT",
                JSON.stringify({ ...data }),
                token,
                props.id.warehouseid,
                props.id.productid
            )
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getwarehouses(props.warehouseid);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (file.length == 0) {
            alert("please upload icon");
        }
    };
    const searchhandler = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 0) {
            const items = products.filter((item) =>
                `${item.name}`
                    .toLowerCase()
                    .includes(`${e.target.value}`.toLowerCase())
            );
            console.log(items);

            setProductSearch(items);
        } else {
            setSelectedProduct({ name: "", value: "" });
        }
    };
    const buttonHandler = (e) => {
        setproductError(false);
        setSelectedProduct({
            name: e.currentTarget.getAttribute("name"),
            value: e.currentTarget.getAttribute("id"),
        });
        setSearchTerm(e.currentTarget.getAttribute("name"));
        setProductSearch([]);
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update product" : "Add product"}
                        </h3>
                        <p>
                            {props.editable ? "Update product" : "Add product"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    {!props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor=" Role">Warehouse</label>
                            <div className={dashboardclasses["select"]}>
                                <select
                                    defaultValue=""
                                    {...register("warehouseId", {
                                        required: "Warehouse Is required",
                                    })}>
                                    <option value="" disabled>
                                        warehouse
                                    </option>
                                    {warehousedata?.map((item) => (
                                        <option value={item.warehouseId}>
                                            {item.warehouseName}
                                        </option>
                                    ))}
                                </select>
                                {errors?.warehouseId && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.warehouseId?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {!props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="productId">Product</label>
                            <div
                                className={`${dashboardclasses["select"]} ${dashboardclasses["product-search"]}`}>
                                <input
                                    type="text"
                                    onChange={searchhandler}
                                    value={searchTerm}
                                />
                                {productSearch.length > 0 && (
                                    <div
                                        className={
                                            dashboardclasses["product-items"]
                                        }
                                        id="productSearch">
                                        {productSearch.map((item) => {
                                            return (
                                                <button
                                                    onClick={buttonHandler}
                                                    id={item.value}
                                                    name={item.name}
                                                    type={"button"}>
                                                    {item.name}
                                                </button>
                                            );
                                        })}
                                    </div>
                                )}
                                {productError && (
                                    <p className={dashboardclasses["error"]}>
                                        Product is required!
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="status">status</label>
                            <div className={dashboardclasses["select"]}>
                                <select
                                    defaultValue=""
                                    {...register("status", {
                                        required: "status is required",
                                    })}>
                                    <option value={""} disabled>
                                        Status
                                    </option>
                                    <option value={1}>active</option>
                                    <option value="2">inactive</option>
                                </select>
                                {errors?.status && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.status?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Product"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
