import React, { useEffect } from "react";
import poclasses from "./po.module.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function ProductDetails(props) {
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const batchinfobyid = (id) => {
        Services.batchinfobyProductid("GET", null, token, id)
            .then((res) => {
                console.log(res);
                props.setmodal(false);
                if (res.Status === 1) {
                    props.setBatchDetails(res.POitems.slice(0, 3));
                    props.showbatch(true);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const selectHandler = (e) => {
        const productId = e.currentTarget.getAttribute("productId");
        const selectedProducts = props.products.filter(
            (suppliers) => suppliers.productId == productId
        );
        console.log(selectedProducts, "products");
        props.setselectedProducts(selectedProducts[0]);
        props.productnameref.current.value = selectedProducts[0].productName;
        props.unitsref.current.value = selectedProducts[0].packing;
        props.reset({
            // productCode: selectedProducts[0].productCode,
            gstPercentage: selectedProducts[0].gst,
            "MRP-NR": selectedProducts[0].price,
        });
        batchinfobyid(selectedProducts[0]?.productId);
        //  100000
        // selectedProducts[0]?.productCode
        //productId
    };
    return (
        <div className={poclasses["modal"]} onClick={CancelHandler}>
            <div
                className={poclasses["modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <div className={poclasses["product-table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>product Name</th>
                                <th>Packing units</th>
                                <th>Manufacturer</th>
                                {/* <th>packing</th> */}
                                <th>price</th>
                                <th>selling Price</th>
                                <th>gst</th>
                                <th>Length</th>
                                <th>weight</th>

                                {/* <th>height</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {props?.products?.length > 0 ? (
                                props?.products?.map((item) => {
                                    return (
                                        <tr
                                            onClick={selectHandler}
                                            productId={item.productId}
                                            key={item.productId}>
                                            <td>{item?.productName}</td>
                                            <td>{item?.manufacturedBy}</td>
                                            <td>{item?.packing}</td>
                                            <td>{item?.price}</td>
                                            <td>
                                                {item?.genifast_sellingPrice}
                                            </td>
                                            <td>{item?.gst}</td>
                                            <td>{item["length"]}</td>
                                            <td>{item?.weight}</td>
                                            {/* <td>{item?.supplierType}</td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{ textAlign: "center" }}>
                                        No Products Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* productDetails */}
        </div>
    );
}

export default ProductDetails;
