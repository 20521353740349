import React from "react";
import poclasses from "./po.module.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
function ProductDetails(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.batchmodal(true);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    const onSubmit = (data) => {};
    return (
        <div className={poclasses["modal"]} onClick={CancelHandler}>
            <div
                className={poclasses["modalContent-addbatch"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <h3>Add BatchInfo</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="batchNumber">batch number</label>
                        <input
                            type="text"
                            {...register("batchNumber", {
                                required: "Batch number is required",
                            })}
                        />

                        {errors?.batchNumber && (
                            <p className={poclasses["error"]}>
                                {errors?.batchNumber?.message}
                            </p>
                        )}
                    </div>
                </form>
            </div>
            {/* productDetails */}
        </div>
    );
}

export default ProductDetails;
