import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const [getparams] = useSearchParams();
    let type = getparams.get("type");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    useEffect(() => {
        console.log(props.id);
        if (props.editable) {
            Services.CategoryTypeDetail("GET", null, token, props.id)
                .then((res) => {
                    if (res?.Status === 1) {
                        reset({
                            categoryTypeName:
                                res.categoryTypes.categoryTypeName,
                            Status: res.categoryTypes.Status,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const fileTypes = ["JPEG", "PNG"];

    const onSubmit = (data) => {
        let method = data;
        if (!props.editable) {
            props.tableload(true);
            Services.AddCategoryTypes("POST", JSON.stringify(method), token)
                .then((res) => {
                    if (res.Status === 1) {
                        Navigate(`/category-types?page=${1}`);

                        props.setpageCount(1);
                        props.getCategories();
                        props.setmodal(false);
                        props.tableload(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        props.tableload(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    props.tableload(false);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateCategoryTypes(
                "PUT",
                JSON.stringify(method),
                token,
                props.id
            )
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update Category"
                                : "Add Category"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update Category"
                                : "Add Category"}{" "}
                            your Product category and necessary information from
                            here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    {!type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="categoryName">
                                    Category Name
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("categoryTypeName", {
                                            required:
                                                "category Name is required",
                                        })}
                                    />

                                    {errors?.categoryName && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.categoryName?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            {props.editable && (
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="status">Status</label>
                                    <div className={dashboardclasses["select"]}>
                                        <select
                                            {...register("Status", {
                                                required: "status is required",
                                            })}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        {errors?.Status && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.status?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaTitle">metaTitle</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaTitle", {
                                            required: "metaTitle  is required",
                                        })}
                                    />

                                    {errors?.metaTitle && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaTitle?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaDescription">
                                    metaDescription
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <textarea
                                        rows="5"
                                        {...register(
                                            "metaDescription"
                                        )}></textarea>

                                    {errors?.metaDescription && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaDescription?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaKeywords">
                                    metaKeywords
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaKeywords", {
                                            required:
                                                "metaKeywords  is required",
                                        })}
                                    />

                                    {errors?.metaKeywords && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaKeywords?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Category"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
