import React from "react";
import poclasses from "../purchaseOrder/po.module.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function ProductDetails(props) {
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const changeInputHandler = (e) => {
        let selectedPoitemId = e.currentTarget.getAttribute("POitemId");
        let Poitems = [...props?.selectedProduct?.PoItems];
        let Index = Poitems.findIndex(
            (item) => item.POitemId == selectedPoitemId
        );

        if (Index != -1) {
            Poitems[Index].SelectedQuantity = Number(e.target.value);
            props.setCurrentSelectedProducts((prev) => ({
                ...prev,
                PoItems: Poitems,
            }));
        }
    };
    const submitHandler = () => {
        let TotalSelectedQuantity = props.selectedProduct.PoItems.reduce(
            (accumulator, curr) => accumulator + curr.SelectedQuantity,
            0
        );
        if (TotalSelectedQuantity <= 0) {
            props.seterrorMessage(
                "Total Transfer Quanity Cannot be less than 1"
            );
            props.setErrorModal(true);
            return;
        }
        let error = false;
        props.selectedProduct.PoItems.forEach((item) => {
            if (
                Number(item.SelectedQuantity) > item.availableQty ||
                item.SelectedQuantity < 0
            ) {
                let message = `Selected Quantity  is Greater than Available Quantity for PoItemId:${item.POitemId}`;
                if (item.SelectedQuantity < 0) {
                    message = `selected Quantity Cannot be less than zero for PoItem::${item.POitemId}`;
                }
                props.seterrorMessage(message);
                props.setErrorModal(true);
                error = true;
                return false;
            }
        });
        if (error) {
            return;
        }
        props.setSelectedProductDetails((prev) => [
            ...prev,
            {
                ...props.selectedProduct,
                TotalSelectedQuantity,
            },
        ]);
        props.productnameref.current.value = "";
        props.setmodal(false);
    };
    return (
        <div className={poclasses["modal"]} onClick={CancelHandler}>
            <div
                className={poclasses["modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <div className={poclasses["product-table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>PO ITEM ID</th>
                                <th>BATCH NUMBER</th>
                                <th>SUPPLIER NAME</th>
                                <th>AVAILABLE QTY</th>
                                <th>PURCHASE PRICE</th>
                                <th>FREE QTY</th>
                                <th>EXPIRY</th>
                                <th>SELECTED QTY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.selectedProduct?.PoItems?.length > 0 ? (
                                <>
                                    {props?.selectedProduct?.PoItems?.map(
                                        (item) => {
                                            return (
                                                <tr
                                                    POitemId={item.POitemId}
                                                    key={item.POitemId}>
                                                    <td>{item.POitemId}</td>
                                                    <td>{item?.batchNumber}</td>
                                                    <td>
                                                        {item?.SupplierName}
                                                    </td>
                                                    <td>
                                                        {item?.availableQty}
                                                    </td>
                                                    <td>
                                                        Rs {item?.purchasePrice}
                                                    </td>
                                                    <td>{item?.freeQty}</td>
                                                    <td>{item?.expiry}</td>
                                                    <td>
                                                        <input
                                                            style={{
                                                                border: "1px solid #ccc",
                                                                width: "5rem",
                                                                outline: "none",
                                                                padding:
                                                                    "0.5rem",
                                                            }}
                                                            POitemId={
                                                                item.POitemId
                                                            }
                                                            onChange={
                                                                changeInputHandler
                                                            }
                                                            type="number"
                                                            value={
                                                                item.SelectedQuantity
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{ textAlign: "center" }}>
                                        No Products Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {props?.selectedProduct?.PoItems?.length > 0 && (
                    <button
                        className={poclasses["add-item-btn"]}
                        onClick={submitHandler}>
                        Submit
                    </button>
                )}
            </div>
            {/* productDetails */}
        </div>
    );
}

export default ProductDetails;
