import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { css } from "@emotion/react";
function AddCategory(props) {
    const Navigate = useNavigate();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const [priceLoading, setpriceloading] = useState(false);
    const [showPrices, setShowPrices] = useState(false);
    const [formData, setFormData] = useState();
    const [courierid, setcourierid] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    // useEffect(() => {
    //     if (props.editable) {
    //     }
    // }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const fileTypes = ["JPEG", "PNG"];
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const onSubmit = (data) => {
        let method = JSON.stringify({
            weight: data.weight,
            orderId: props.id,
        });
        setFormData(data);
        setpriceloading(true);
        if (!props.editable) {
            Services.getPrice("POST", method, token)
                .then((res) => {
                    console.log(res);
                    setShowPrices(true);
                    setpriceloading(false);
                    if (res.Status === 1) {
                        setDeliveryPrice(res.Delivery_Pricings);
                        // props.setmodal(false);
                        // props.getorders();
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // else if (props.editable) {
        //
        // }
    };
    const backhandler = (e) => {
        setShowPrices(false);
        reset(formData);
    };
    const addShipmentHandler = (e) => {
        if (courierid.length == 0) {
            alert("please select courier");
            return;
        }
        let method = JSON.stringify({
            ...formData,
            orderId: props.id,
            Courier_Company_Id: courierid,
        });

        Services.AddShipmentData("POST", method, token, props.id)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getorders(props.selectedWarehouse);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const courierChangeHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        setcourierid(id);
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            Create shipment Order for <b>#{props.id}</b>
                        </h3>
                        <p>
                            {props.editable
                                ? "Update shipment"
                                : "Add shipment"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <div className={dashboardclasses["customer-info"]}>
                    <h3>Customer Info</h3>
                    <div>
                        <p>Customer Name : {props.name}</p>
                        <p>Contact Number : {props.number}</p>
                    </div>
                    {showPrices && <button onClick={backhandler}>Back</button>}
                </div>
                {!showPrices ? (
                    <form
                        className={dashboardclasses["add-items"]}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor=" length">Length</label>
                            <div className={dashboardclasses["select"]}>
                                <input
                                    type="number"
                                    placeholder="cm"
                                    step={0.001}
                                    {...register("length", {
                                        required: "length is required",
                                    })}
                                />

                                {errors?.Length && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.Length?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor=" height">Height</label>
                            <div className={dashboardclasses["select"]}>
                                <input
                                    type="number"
                                    placeholder="cm"
                                    step={0.001}
                                    {...register("height", {
                                        required: "height is required",
                                    })}
                                />

                                {errors?.height && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.height?.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor=" breadth">Breadth</label>
                            <div className={dashboardclasses["select"]}>
                                <input
                                    type="number"
                                    step={0.001}
                                    placeholder="cm"
                                    {...register("breadth", {
                                        required: "breadth is required",
                                    })}
                                />

                                {errors?.breadth && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.breadth?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor=" weight">Weight</label>
                            <div className={dashboardclasses["select"]}>
                                <input
                                    type="number"
                                    placeholder="kg"
                                    step={0.001}
                                    {...register("weight", {
                                        required: "weight is required",
                                    })}
                                />

                                {errors?.weight && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.weight?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={dashboardclasses["add-btn"]}>
                            <button type="button" onClick={CancelHandler}>
                                Cancel
                            </button>
                            {!priceLoading ? (
                                <button
                                    type="submit"
                                    className={
                                        dashboardclasses["add-category-btn"]
                                    }>
                                    check Prices
                                </button>
                            ) : (
                                <div
                                    className={
                                        dashboardclasses["loader-price"]
                                    }>
                                    <ClipLoader
                                        color={"red"}
                                        loading={priceLoading}
                                        cssOverride={override}
                                        size={20}
                                    />
                                    <p>Checking Price</p>
                                </div>
                            )}
                        </div>
                    </form>
                ) : (
                    <div className={dashboardclasses["courier-info"]}>
                        <h3>Courier Info</h3>
                        <table>
                            <thead>
                                <th></th>
                                <th>Courier Name</th>
                                <th>Rate</th>
                                <th>Etd</th>
                            </thead>
                            <tbody>
                                {deliveryPrice.length > 0 &&
                                    deliveryPrice.map((item) => (
                                        <tr>
                                            <td>
                                                <input
                                                    onClick={
                                                        courierChangeHandler
                                                    }
                                                    type="radio"
                                                    name="optradio"
                                                    id={item.Courier_Company_Id}
                                                />
                                            </td>
                                            <td>{item.Courier_Company_Name}</td>
                                            <td>{item.Rate}</td>
                                            <td>{item.ETA}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <div className={dashboardclasses["add-btn"]}>
                            <button
                                onClick={addShipmentHandler}
                                className={
                                    dashboardclasses["add-category-btn"]
                                }>
                                Add shipment
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddCategory;
