import React, { useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddProducts(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getitemDetails("GET", null, token, props.promocodeid)
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        reset({
                            ...response.POitems,
                        });
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (response.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const onSubmit = (data) => {
        console.log(data);
        let method = JSON.stringify({
            POentryId: props.promocodeid,
            ...data,
        });
        if (!props.editable) {
            Services.AddItem("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.getpromocodes();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");

                    console.log(err);
                });
        } else if (props.editable) {
            Services.UpdatePoitem("PUT", method, token, props.promocodeid)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getpromocodes();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update poitem" : "Add poitem"}
                        </h3>
                        <p>
                            {props.editable ? "Update poitem" : "Add poitem"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="startDate">Code</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("Code", {
                                    required: "Code is required",
                                })}
                            />

                            {errors?.Code && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Code?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="startDate">POitem Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("POitemName", {
                                    required: "POitem Name is required",
                                })}
                            />

                            {errors?.POitemName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.POitemName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="startDate">City</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("City", {
                                    required: "City is required",
                                })}
                            />

                            {errors?.City && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.City?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="endDate">Phone</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type="number"
                                {...register("Phone", {
                                    required: "Phone is required",
                                })}
                            />

                            {errors?.Phone && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Phone?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="EmailID">EmailID</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"email"}
                                {...register("EmailID", {
                                    required: "Email is required",
                                })}
                            />

                            {errors?.EmailID && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.EmailID?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="maxDiscount">Address1</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("Address1", {
                                    required: "Address1 is required",
                                })}
                            />

                            {errors?.Address1 && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Address1?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="maxDiscount">Address2</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("Address2", {
                                    required: "Address2 is required",
                                })}
                            />

                            {errors?.Address2 && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Address2?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="State">State</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"tex"}
                                {...register("State", {
                                    required: "State is required",
                                })}
                            />

                            {errors?.State && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.State?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="State">pincode</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                {...register("pincode", {
                                    required: "pincode is required",
                                })}
                            />

                            {errors?.pincode && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.pincode?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="State">GSTIN</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("GSTIN", {
                                    required: "GSTIN is required",
                                })}
                            />

                            {errors?.GSTIN && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.GSTIN?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="State">Supplier Type</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("SupplierType", {
                                    required: "SupplierType is required",
                                })}
                            />

                            {errors?.SupplierType && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.SupplierType?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {/* <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" type">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "Status is required",
                                })}>
                                <option value="" disabled>
                                    Status
                                </option>
                                <option value="1">Active </option>
                                <option value="2">InActive</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div> */}

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add poitem"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
