import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopNav from "../../Navbar/topNav";
import Dashboardclasses from "../dashboard.module.css";
import uuid from "react-uuid";
import { Services } from "../../../Services";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import ProductDetails from "./productSearch";
import Error from "../../utils/error";
import Warning from "../../utils/warning";
import PoItems from "./PoItems";
function NewTransfer() {
    const Navigate = useNavigate();
    const productnameref = useRef();
    const [warehouse, setwarehouse] = useState([]);
    const [showPoItem, setShowPoItem] = useState(false);
    const [showProducts, setshowProducts] = useState(false);
    const [searchproducts, setSearchproducts] = useState([]);
    // const [selectedproducts] = useState([]);
    const [selectedProductDetals, setSelectedProductDetails] = useState([]);
    const [currentSelectedProduct, setCurrentSelectedProducts] = useState({});
    const [selectedwarehouse, setselectedWarehouse] = useState({
        prev: "",
        curr: "",
        next: "",
    });
    const admindata = JSON.parse(localStorage.getItem("admindata"));
    const [warning, setwarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [errorMessage, seterrorMessage] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    const watchData = watch();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setwarehouse(response.warehouses);
                } else if (response.Status === 0) {
                    // setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    const productsearchHandler = (e) => {
        const keyCode = e.key;
        console.log(watchData.fromWarehouseId.length == 0);
        if (keyCode === "Enter") {
            if (selectedwarehouse.curr == 0) {
                seterrorMessage("please select warehouse");
                setErrorModal(true);

                return;
            }
            Services.warehouseproductSearch(
                "GET",
                null,
                token,
                selectedwarehouse.curr,
                e.target.value
            )
                .then((res) => {
                    console.log(res);
                    if (res.Status === 1) {
                        setshowProducts(true);
                        setSearchproducts(res.Products);
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const onSubmit = (formdetails, e) => {
        const keyCode = e.key;
        // console.log(watchData.fromWarehouseId.length == 0);
        if (keyCode === "Enter") {
            return;
        }
        if (formdetails.toWarehouseId == selectedwarehouse.curr) {
            seterrorMessage(
                "from warehouse and to ware house need to be different"
            );
            setErrorModal(true);
            return;
        }
        if (currentSelectedProduct.PoEntries.length == 0) {
            setErrorModal(true);
            seterrorMessage("NO POITEMS FOUND");
            return;
        }

        let productExist = selectedProductDetals.findIndex(
            (item) => item.productId == currentSelectedProduct.productId
        );

        if (productExist != -1) {
            seterrorMessage("selected Product already exist in transfer Items");
            setErrorModal(true);
            return;
        }

        reset({ quantity: "" });
        productnameref.current.value = "";
        setCurrentSelectedProducts({});
    };
    const removeHandler = (e) => {
        let prodid = e.currentTarget.getAttribute("prodid");
        let UpdatedSelectedProductsDetails = selectedProductDetals.filter(
            (item) => item.productId != prodid
        );
        setSelectedProductDetails(UpdatedSelectedProductsDetails);
    };
    const changeHandler = (e) => {
        if (selectedProductDetals.length > 0) {
            setWarningMessage(
                "Selected Products will be removed if the warehouse is changed. Do you want to proceed?"
            );
            setwarning(true);
            setselectedWarehouse((prev) => {
                return {
                    prev: prev.curr,
                    next: e.target.value,
                    curr: prev.curr,
                };
            });
        } else {
            setselectedWarehouse((prev) => {
                return { prev: "", next: "", curr: e.target.value };
            });
        }
    };
    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };
    const proceedHandler = () => {
        setselectedWarehouse((prev) => {
            return { prev: "", next: "", curr: prev.next };
        });
        setwarning(false);
        setCurrentSelectedProducts({});

        setSelectedProductDetails([]);
    };
    const transfersubmit = () => {
        if (selectedwarehouse.curr.length == 0) {
            seterrorMessage("please select from warehouse");
            setErrorModal(true);
            return;
        } else if (watchData.toWarehouseId.length == 0) {
            seterrorMessage("please select To warehouse");
            setErrorModal(true);
            return;
        } else if (watchData.toWarehouseId == selectedwarehouse.curr) {
            seterrorMessage(
                "from warehouse and to warehouse need to be different "
            );
            setErrorModal(true);
            return;
        } else if (selectedProductDetals.length == 0) {
            seterrorMessage("please Add products to transfer");
            setErrorModal(true);
            return;
        }
        let body = {
            FromWarehouseName: warehouse.find(
                (item) => item.warehouseId == selectedwarehouse.curr
            ).warehouseName,
            ToWarehouseName: warehouse.find(
                (item) => item.warehouseId == watchData.toWarehouseId
            ).warehouseName,
            fromWarehouseId: Number(selectedwarehouse.curr),
            toWarehouseId: Number(watchData.toWarehouseId),
            initiatedUserID: admindata.adminUserId,
            transferProducts: selectedProductDetals.map((item) => ({
                productId: item.productDetails.productId,
                TotalSelectedQuantity: item.TotalSelectedQuantity,
                PoItems: item?.PoItems?.filter(
                    (item) => Number(item.SelectedQuantity) > 0
                )?.map((Poitem) => ({
                    SelectedQuantity: Poitem.SelectedQuantity,
                    batchNumber: Poitem.batchNumber,
                    POitemId: Poitem.POitemId,
                    expiry: Poitem.expiry,
                    gstPercentage: Poitem.gstPercentage,
                    units: Poitem.units,
                    purchasePrice: Poitem.purchasePrice,
                })),
            })),
        };

        Services.stockTransfer("POST", JSON.stringify(body), token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    Navigate(-1);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <>
            {warning && (
                <Warning
                    setmodal={setwarning}
                    warningMessage={warningMessage}
                    proceedHandler={proceedHandler}
                />
            )}
            {errorModal && (
                <Error setmodal={setErrorModal} errorMessage={errorMessage} />
            )}
            {showPoItem && (
                <PoItems
                    setErrorModal={setErrorModal}
                    seterrorMessage={seterrorMessage}
                    setmodal={setShowPoItem}
                    setCurrentSelectedProducts={setCurrentSelectedProducts}
                    setSelectedProductDetails={setSelectedProductDetails}
                    selectedProduct={currentSelectedProduct}
                    productnameref={productnameref}
                />
            )}
            {showProducts && (
                <ProductDetails
                    setmodal={setshowProducts}
                    setCurrentSelectedProducts={setCurrentSelectedProducts}
                    products={searchproducts}
                    productnameref={productnameref}
                    setShowPoItem={setShowPoItem}
                    warehouseId={selectedwarehouse.curr}
                    seterrorMessage={seterrorMessage}
                    setErrorModal={setErrorModal}
                    reset={reset}
                    // productnameref={productnameref}
                />
            )}
            <TopNav />
            <div className={Dashboardclasses["transfer-wrapper"]}>
                <button
                    className={Dashboardclasses["backbutton"]}
                    onClick={() => {
                        Navigate("/internalTransfer?transfer-type=1&page=1");
                    }}>
                    Back
                </button>
                <form
                    className={Dashboardclasses["inputs"]}
                    // className={Dashboardclasses["inputs"]}
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}>
                    <div className={Dashboardclasses["form-control-add"]}>
                        <label htmlFor="from warehouse">From warehouse</label>
                        <select
                            defaultValue=""
                            value={selectedwarehouse.curr}
                            {...register("fromWarehouseId", {
                                onChange: (e) => {
                                    changeHandler(e);
                                },
                            })}>
                            <option value="" disabled>
                                Select warehouse
                            </option>
                            {warehouse?.map((item) => (
                                <option value={item.warehouseId}>
                                    {item.warehouseName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={Dashboardclasses["form-control-add"]}>
                        <label htmlFor="To warehouse">To warehouse</label>
                        <select defaultValue="" {...register("toWarehouseId")}>
                            <option value="" disabled>
                                Select warehouse
                            </option>
                            {warehouse?.map((item) => (
                                <option value={item.warehouseId}>
                                    {item.warehouseName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={Dashboardclasses["form-control-add"]}>
                        <label htmlFor="code">Product Name</label>
                        <input
                            style={{
                                maxWidth: "210px",
                                minWidth: "200px",
                            }}
                            onKeyDown={productsearchHandler}
                            type={"text"}
                            ref={productnameref}
                            // {...register("productName")}
                        />
                    </div>
                    {/*   <div className={Dashboardclasses["form-control-add"]}>
                        <label htmlFor="qty">Quantity</label>
                        <input
                            type={"number"}
                            {...register("quantity")}
                            // {...register("productName")}
                        />
                    </div> */}

                    {/* <button type="submit">Add</button> */}
                </form>
                <div className={Dashboardclasses["table-wrapper"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Product ID</th>
                                <th>product Name</th>
                                <th>generic Name</th>
                                <th>ManufacturedBy</th>
                                <th>purchase Price</th>
                                <th>Selling Price</th>
                                {/* <th>Expiry</th> */}
                                <th>Transfer qty</th>
                                {/* <th>SupplierName</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedProductDetals?.length > 0 ? (
                                selectedProductDetals?.map((item) => {
                                    return (
                                        <tr>
                                            <td>
                                                {item.productDetails.productId}
                                            </td>
                                            <td>
                                                {
                                                    item.productDetails
                                                        .productName
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.productDetails
                                                        .genericName
                                                }
                                            </td>

                                            <td>
                                                {
                                                    item.productDetails
                                                        .manufacturedBy
                                                }
                                            </td>
                                            <td>{item.productDetails.price}</td>
                                            <td>
                                                {
                                                    item.productDetails
                                                        .sellingPrice
                                                }
                                            </td>

                                            {/* <td>{item?.expiry}</td> */}
                                            <td>
                                                {item?.TotalSelectedQuantity}
                                            </td>
                                            {/* <td>{item?.SupplierName}</td> */}

                                            <td>
                                                <button
                                                    onClick={removeHandler}
                                                    prodid={
                                                        item.productDetails
                                                            ?.productId
                                                    }>
                                                    <MdDelete size={26} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={15}
                                        style={{ textAlign: "center" }}>
                                        No Products found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <button
                    className={Dashboardclasses["transfer-submit"]}
                    onClick={transfersubmit}>
                    Submit
                </button>
            </div>
        </>
    );
}

export default NewTransfer;
