import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import AddMolecule from "./addMolecule";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
function Warehouse() {
    const Navigate = useNavigate();
    const [category, setCategory] = useState(false);

    const [params] = useSearchParams();
    const [tableLoad, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [categorysearchItems, setCategorySearchItems] = useState([]);
    const [composition, setcomposition] = useState();
    const [editable, setEditable] = useState(false);
    const [categoryItems, setCategoryItems] = useState([]);
    const [search, setSearch] = useState(null);
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const categoryHandler = () => {
        setCategory((prev) => !prev);
    };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getcategories = () => {
        Services.uniqueCompositions("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setCategoryItems(res.MgCompositions);
                    if (search) {
                        let compositions = res.MgCompositions.filter((item) =>
                            item.mgComposition
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        );
                        setCategorySearchItems(compositions);
                    } else {
                        setCategorySearchItems(res.MgCompositions);
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getcategories();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [categorysearchItems]);

    const mappingformatter = (cell, cellContent) => {
        return (
            <button
                onClick={() => {
                    setcomposition(cellContent.mgId);
                    console.log(cellContent.mgId);
                    setCategory(true);
                }}
                className={dashboardclasses["map-btn"]}>
                Map
            </button>
        );
    };
    const columns = [
        {
            dataField: "mgComposition",
            text: "MG COMPOSITION",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "genifast_Moleculename",
            text: "GENIFAST MOLECULE NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Mapping",
            text: "Mapping",
            sort: false,
            formatter: mappingformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            //Molecules
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/mgmolecules?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        setTableLoad(true);
        setpageCount(1);
        setSearch(e.target.value);
        const search = categoryItems.filter((item) =>
            item.mgComposition
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
        );
        console.log(search);
        setCategorySearchItems(search);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {category && (
                <AddMolecule
                    setmodal={setCategory}
                    getCategories={getcategories}
                    setpageCount={setpageCount}
                    tableload={setTableLoad}
                    composition={composition}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="oneMG" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Molecules</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form-two"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Composition name"
                                        onChange={searchHandler}
                                    />
                                </div>
                                {tableLoad ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={categorysearchItems}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            noDataIndication={emptyDataMessage}
                                            wrapperClasses="table-responsive"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
