import dashboardclasses from "../dashboard.module.css";
import sectionClasses from "./section.module.css";
import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import AddSection from "./addSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Services } from "../../../Services";
import Moment from "react-moment";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
function Sections() {
    const [loading, setLoading] = useState(true);
    const [editable, setEditable] = useState(false);
    const [modal, setModal] = useState(false);
    const [sections, setSections] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [id, setId] = useState("");
    const [params] = useSearchParams();
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const Navigate = useNavigate();
    let darkColors = [
        "Black",
        "DarkBlue",
        "DarkCyan",
        "DarkGoldenRod",
        "DarkGray",
        "DarkGreen",
        "DarkMagenta",
        "DarkOliveGreen",
        "DarkOrange",
        "DarkOrchid",
    ];
    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
        );
    };
    const gethomepagescreens = () => {
        setLoading(true);
        Services.gethomepagesections("GET", null, token)
            .then((res) => {
                setLoading(false);
                if (res.Status === 1) {
                    setSections(res.HomepageSections);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        gethomepagescreens();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    // function selectColor(colors) {
    //     return colors[Math.floor(Math.random() * colors.length)];
    // }
    const edithandler = (e) => {
        e.stopPropagation();
        setId(e.currentTarget.getAttribute("id"));
        setEditable(true);
        setModal(true);
    };
    const deleteHandler = (e) => {
        e.stopPropagation();
        if (window.confirm("are you sure you want to delete ")) {
            Services.deleteHomePageSection(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        gethomepagescreens();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button onClick={edithandler} id={cellcontent.HomeSectionId}>
                    <FaRegEdit size={18} />
                </button>
                <button onClick={deleteHandler} id={cellcontent.HomeSectionId}>
                    <MdDelete size={18} />
                </button>
            </div>
        );
    };
    const columns = [
        {
            dataField: "HomeSectionId",
            text: "ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Name",
            text: "TITLE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "created_at",
            text: "CREATED AT",
            formatter: (cell) => {
                return <Moment format="DD-MM-YYYY HH:MM a">{cell}</Moment>;
            },
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Status",
            text: "STATUS",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "100px" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/homepage-sections?page=${page}`);
            // setpageCount(pageCount);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            e.stopPropagation();
            localStorage.setItem("section-name", row.Name);
            Navigate(`/sections/${row.HomeSectionId}?page=1`);
        },
    };
    return (
        <>
            {modal && (
                <AddSection
                    modal={modal}
                    setmodal={setModal}
                    editable={editable}
                    setEditable={setEditable}
                    setpagecount={setpageCount}
                    gethomepagescreens={gethomepagescreens}
                    setLoading={setLoading}
                    id={id}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="deals" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses.CategoryWrapper}>
                        <div className={dashboardclasses["add-product"]}>
                            <h3>Home Page sections</h3>
                            <button
                                onClick={() => {
                                    setModal(true);
                                }}>
                                Add Section
                            </button>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <div className="category-table">
                                <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    data={sections}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    pagination={pagination}
                                    wrapperClasses="table-responsive"
                                    noDataIndication={emptyDataMessage}
                                    rowEvents={tableRowEvents}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sections;
