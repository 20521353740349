import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import image from "../../../Assets/image.png";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";

import { css } from "@emotion/react";
function OrdersReturnModal(props) {
    const Navigate = useNavigate();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const [shipmentData, setShipmentData] = useState({});
    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    useEffect(() => {
        Services.shopmentOrdersDetailsById("GET", null, token, props.id)
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.Status === 1) {
                    setShipmentData(res.Orders);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    function download(url) {
        window.open(url, "_blank");
    }
    return (
        <div
            className={dashboardclasses["return-modal"]}
            onClick={CancelHandler}>
            <div
                className={dashboardclasses["shipment-modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <div className={dashboardclasses["shipment-Details"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Shipment ID</th>
                                <th>Courier</th>
                                <th>AWB</th>
                                <th>height</th>
                                <th>length</th>
                                <th>weight</th>
                                <th>breadth</th>
                                <th> AMOUNT</th>
                                {/* <th> invoice</th> */}
                                <th> Track</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}>
                                        <ClipLoader
                                            color={"red"}
                                            loading={loading}
                                            cssOverride={override}
                                            size={20}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{shipmentData?.shipments?.order_id}</td>
                                    <td>{shipmentData?.shipments?.courier}</td>
                                    <td>{shipmentData?.shipments?.awb}</td>
                                    <td>{shipmentData?.shipments?.height}cm</td>
                                    <td>{shipmentData?.shipments?.length}cm</td>
                                    <td>{shipmentData?.shipments?.weight}kg</td>
                                    <td>
                                        {shipmentData?.shipments?.breadth}cm
                                    </td>
                                    <td>{shipmentData?.total}</td>

                                    <td>
                                        <button
                                            onClick={() => {
                                                download(props.trackurl);
                                            }}>
                                            Track
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrdersReturnModal;
