import React, { useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getMoleculesDetails("GET", null, token, props.id)
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        reset({ ...res.MoleculeComposition });
                    } else if (res?.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        console.log(data);
        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            props.tableload(true);
            Services.AddMolecules("POST", method, token)
                .then((res) => {
                    props.tableload(false);
                    if (res.status === 1) {
                        Navigate(`/Molecules?page=${1}`);
                        props.setpageCount(1);
                        props.setmodal(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    props.tableload(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.UpdateMolecules("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update Molecule"
                                : "Add Molecule"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update Molecule"
                                : "Add Molecule"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="compositionName">
                            Composition Name
                        </label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("compositionName", {
                                    required: "Composition Name  is required",
                                })}
                            />

                            {errors?.compositionName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.compositionName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {/* <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="isPrescriptionRequired">
                            Prescription Required
                        </label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("isPrescriptionRequired", {
                                    required: "this field is required",
                                })}>
                                <option value={0}>Not Required</option>
                                <option value={1}>Required</option>
                            </select>

                            {errors?.isPrescriptionRequired && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.isPrescriptionRequired?.message}
                                </p>
                            )}
                        </div>
                    </div> */}
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="metaTitle">MetaTitle</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("metaTitle")} />

                            {errors?.metaTitle && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.metaTitle?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="metaURL">MetaURL</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("metaURL")} />
                            {errors?.metaURL && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.metaURL?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="metaKeyword">MetaKeyword</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("metaKeyword")} />

                            {errors?.metaKeyword && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.metaKeyword?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="metaDescription">MetaDescription</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("metaDescription")} />

                            {errors?.metaDescription && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.metaDescription?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="metaDescription">tags</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("tags", {
                                    required: "tags is required",
                                })}
                            />

                            {errors?.tags && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.tags?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="description">Description</label>
                        <div className={dashboardclasses["select"]}>
                            <textarea
                                {...register("description", {
                                    required: "description is required",
                                })}></textarea>

                            {errors?.description && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.description?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Molecule"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
