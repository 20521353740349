import React, { useState, useEffect, useRef } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
function AddProducts(props) {
    const Navigate = useNavigate();
    const [getparams] = useSearchParams();
    let type = getparams.get("type");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [genericNames, setGenericNames] = useState([]);
    const [genericNameString, setGenereicNameString] = useState("");

    const [genericNamesearch, setGenericNamessearch] = useState([]);
    const [selectedgenericName, setselectedgenericName] = useState({
        name: "",
    });
    const [genericError, setgenericError] = useState(false);

    useEffect(() => {
        Services.getMolecules("GET", null, token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    // setCategoryItems(response.categories);
                    setGenericNames(
                        response.MoleculeComposition.map((item) => {
                            return {
                                name: item.compositionName,
                                moleculeCompositionId:
                                    item.moleculeCompositionId,
                            };
                        })
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
        if (e.currentTarget.getAttribute("id") !== "genericsearch") {
            setGenericNamessearch([]);
        }
    };

    const onSubmit = (data) => {
        let method = JSON.stringify({
            genifast_Moleculename: selectedgenericName.name,
            genifast_MoleculeId: selectedgenericName.moleculeCompositionId,
        });

        Services.updateComposition("PUT", method, token, props.composition)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getCategories();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const searchhandler = (e) => {
        setGenereicNameString(e.target.value);
        setselectedgenericName({ name: "" });
        if (e.target.value.length > 0) {
            const items = genericNames
                .sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                })
                .filter((item) =>
                    `${item.name}`
                        .toLowerCase()
                        .includes(`${e.target.value}`.toLowerCase())
                );
            console.log(items);

            setGenericNamessearch(items);
        }
    };

    const buttonHandler = (e) => {
        setgenericError(false);
        setselectedgenericName({
            name: e.currentTarget.getAttribute("name"),
            moleculeCompositionId: e.currentTarget.getAttribute("id"),
        });
        setGenereicNameString(e.currentTarget.getAttribute("name"));
        setGenericNamessearch([]);
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>Update Product</h3>
                        <p>update necessary information from here</p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="productId">Molecule Name</label>
                        <div
                            className={`${dashboardclasses["select"]} ${dashboardclasses["product-search"]}`}>
                            <input
                                type="text"
                                onChange={searchhandler}
                                value={genericNameString}
                                id="genericNameInput"
                                autocomplete="off"
                            />
                            {genericNamesearch.length > 0 && (
                                <div
                                    className={
                                        dashboardclasses["product-items"]
                                    }
                                    id="genericsearch">
                                    {genericNamesearch.map((item) => {
                                        return (
                                            <button
                                                onClick={buttonHandler}
                                                id={item.moleculeCompositionId}
                                                name={item.name}
                                                type={"button"}>
                                                {item.name}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                            {genericError && (
                                <p className={dashboardclasses["error"]}>
                                    Select a valid generic Name
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            Update{" "}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
