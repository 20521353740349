import React, { useEffect, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { State } from "country-state-city";
function AddCategory(props) {
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const [tagerror, setTagError] = useState(false);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags, setTags] = React.useState([]);

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index) => {
        // console.log("The tag at index " + index + " was clicked");
    };

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getCompanyDetails("GET", null, token, props.id)
                .then((res) => {
                    // console.log(res);
                    if (res?.Status === 1) {
                        reset({ companyName: res.Companies.companyName });
                        setTags(
                            res.Companies?.hideStatesList?.map((item) => {
                                return {
                                    id: item,
                                    text: item,
                                };
                            })
                        );
                    } else if (res?.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const close = (e) => {
            console.log("close");
            if (e.key == "Escape") {
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const allstate = State.getStatesOfCountry("IN");

    const suggestions = allstate.map((item) => {
        return {
            id: item.name,
            text: item.name,
        };
    });
    const onSubmit = (data) => {
        setTagError(false);

        const selectedStates = tags.map((tag) => tag.text);

        let method = JSON.stringify({
            ...data,
            hideStatesList: selectedStates,
        });
        // return;
        if (!props.editable) {
            Services.addCompany("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateCompany("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update Company" : "Add Company"}
                        </h3>
                        <p>
                            {props.editable ? "Update Company" : "Add Company"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="compositionName">Company Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("companyName", {
                                    required: "Company Name  is required",
                                })}
                            />

                            {errors?.companyName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.companyName?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div
                        className={`${dashboardclasses["item-image-upload"]} `}>
                        <label htmlFor="OrderIDs">hideStatesList</label>
                        <div
                            className={`${dashboardclasses["select"]} ${dashboardclasses["tags"]}`}>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="bottom"
                                autofocus={false}
                                autocomplete
                            />
                            {tagerror && (
                                <p className={dashboardclasses["error"]}>
                                    Please enter states
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Company"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
