import React, { useState, useEffect, useRef } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
function AddProducts(props) {
    const Navigate = useNavigate();
    const [getparams] = useSearchParams();
    let type = getparams.get("type");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [categoryItems, setCategoryItems] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ isPrescriptionRequired: 0 });
    const [file, setFile] = useState("");
    const [base64, setbase64] = useState("");
    const [product, setproduct] = useState([]);
    const [genericNames, setGenericNames] = useState([]);
    const [genericNameString, setGenereicNameString] = useState("");

    const [genericNamesearch, setGenericNamessearch] = useState([]);
    const [selectedgenericName, setselectedgenericName] = useState({
        name: "",
        moleculeCompositionId: "",
    });
    const [genericError, setgenericError] = useState(false);
    const [manufacturedBy, setmanufacturedBy] = useState([]);

    useEffect(() => {
        Services.getMolecules("GET", null, token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    // setCategoryItems(response.categories);
                    setGenericNames(
                        response.MoleculeComposition.map((item) => {
                            return {
                                name: item.compositionName,
                                moleculeCompositionId:
                                    item.moleculeCompositionId,
                            };
                        })
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        Services.getCompany("GET", null, token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    // setCategoryItems(response.categories);
                    setmanufacturedBy(response.Companies);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        if (props.editable) {
            Services.getCategories("GET", null, token)
                .then((response) => {
                    if (response.Status === 1) {
                        console.log(response);
                        setCategoryItems(response.categories);
                        Services.getProductsByProductId(
                            "GET",
                            null,
                            token,
                            props.productid
                        ).then((res) => {
                            if (res?.Status === 1) {
                                console.log(res);
                                setproduct(res.Products);
                                setFile(res.Products.imageUrl);
                                setGenereicNameString(res.Products.genericName);
                                setselectedgenericName({
                                    name: res.Products.genericName,
                                    moleculeCompositionId:
                                        res.Products.moleculeCompositionId,
                                });
                                if (type) {
                                    reset({
                                        metaTitle: res.Products.metaTitle,
                                        metaDescription:
                                            res.Products.metaDescription,
                                        metaKeywords: res.Products.metaKeywords,
                                        primaryHeading:
                                            res.Products.primaryHeading,
                                        altTag: res.Products.altTag,
                                        metaKeywords: res.Products.metaKeywords,
                                        url: res.Products.url,
                                    });
                                } else {
                                    reset({
                                        // availableQty: res.Products.availableQty,
                                        // manufacturedBy:String(res.Products.manufacturedBy).,
                                        ...res.Products,
                                        genericName: "",
                                        status: res.Products.status,
                                        categoryId: res.Products.categoryId,
                                        // Description: res.Products.description,
                                        is_available_in_medbuzz:
                                            res.Products
                                                .is_available_in_medbuzz,
                                    });
                                }
                            } else if (res.Status === 0) {
                                toast.error(`${res.Message}`, {
                                    position: "top-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                if (res.Message === "Token expired") {
                                    Navigate("/");
                                }
                            }
                        });
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            Services.getCategories("GET", null, token)
                .then((res) => {
                    if (res.Status === 1) {
                        setCategoryItems(res.categories);
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
        if (e.currentTarget.getAttribute("id") !== "genericsearch") {
            setGenericNamessearch([]);
        }
    };
    // const fileTypes = ["JPEG", "PNG"];
    const handleChange = (file) => {
        if (file.type === "image/png" || file.type === "image/jpeg") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setbase64(reader.result);
                setFile(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } else {
            alert("please upload image in PNG and JPEG format ");
        }
    };

    const fileuploadDesign = (
        <div className={dashboardclasses["add-item"]}>
            <BsCloudUpload className={dashboardclasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const onSubmit = (data) => {
        let image;
        if (base64.length > 0) {
            image = { imageUrl: base64 };
        }
        console.log(data);
        let method = JSON.stringify({
            ...data,
            ...image,
            // status: product.status,
            genericName: genericNameString,
            moleculeCompositionId: selectedgenericName.moleculeCompositionId,
        });

        if (selectedgenericName.name.length === 0) {
            setgenericError(true);
            document
                .getElementById("genericNameInput")
                .scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }

        if (!props.editable && file.length !== 0) {
            Services.addMasterProduct("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setSearchfilter("");
                        props.setTypeFilter("");
                        props.setactivefilter("");
                        props.setmanufactureFilter("");
                        props.setgenericFilter("");
                        props.setmodal(false);
                        props.getproducts();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateProduct("PUT", method, token, props.productid)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setSearchfilter("");
                        props.setTypeFilter("");
                        props.setactivefilter("");
                        props.setmanufactureFilter("");
                        props.setgenericFilter("");
                        props.setEditable(false);
                        props.setmodal(false);
                        props.getproducts();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (file.length === 0) {
            alert("please upload icon");
        }
    };
    const searchhandler = (e) => {
        setGenereicNameString(e.target.value);
        setselectedgenericName({ name: "", moleculeCompositionId: "" });
        if (e.target.value.length > 0) {
            const items = genericNames
                .sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                })
                .filter((item) =>
                    `${item.name}`
                        .toLowerCase()
                        .includes(`${e.target.value}`.toLowerCase())
                );
            console.log(items);

            setGenericNamessearch(items);
        }
    };

    const buttonHandler = (e) => {
        setgenericError(false);
        setselectedgenericName({
            name: e.currentTarget.getAttribute("name"),
            moleculeCompositionId: e.currentTarget.getAttribute(
                "moleculeCompositionId"
            ),
        });
        setGenereicNameString(e.currentTarget.getAttribute("name"));
        setGenericNamessearch([]);
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update Product" : "Add Product"}
                        </h3>
                        <p>
                            {props.editable ? "Update Product" : "Add Product"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">product Code</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("productCode", {
                                    required: "product Code is required",
                                })}
                            />

                            {errors?.productCode && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.productCode?.message}
                                </p>
                            )}
                        </div>
                    </div> */}

                    {type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaTitle">MetaTitle</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaTitle", {
                                            required: "metaTitle  is required",
                                        })}
                                    />

                                    {errors?.metaTitle && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaTitle?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaDescription">
                                    MetaDescription
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <textarea
                                        rows="5"
                                        {...register(
                                            "metaDescription"
                                        )}></textarea>

                                    {errors?.metaDescription && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaDescription?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metaKeywords">
                                    MetaKeywords
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("metaKeywords", {
                                            required:
                                                "metaKeywords  is required",
                                        })}
                                    />

                                    {errors?.metaKeywords && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metaKeywords?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="url">URL</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("url", {
                                            required: "url  is required",
                                        })}
                                    />

                                    {errors?.url && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.url?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="url">Primary Heading</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("primaryHeading", {
                                            required: false,
                                        })}
                                    />

                                    {errors?.primaryHeading && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.primaryHeading?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="url">Alt Tag</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("altTag", {
                                            required: false,
                                        })}
                                    />

                                    {errors?.altTag && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.altTag?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {!type && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    Product Name
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("productName", {
                                            required:
                                                "product Name is required",
                                        })}
                                    />

                                    {errors?.productName && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.productName?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor=" Category">Category</label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        {...register("categoryId", {
                                            required: "category is required",
                                        })}>
                                        <option value="" disabled>
                                            Select category
                                        </option>
                                        {categoryItems.map((item) => (
                                            <option value={item.categoryId}>
                                                {item.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors?.categoryId && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.categoryId?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="image">Product Image</label>

                                <FileUploader
                                    classes={dashboardclasses["upload-image"]}
                                    multiple={false}
                                    handleChange={handleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                            <div className={dashboardclasses["uploaded-img"]}>
                                <img src={file} alt="" width={"120px"} />
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productId">Generic Name</label>
                                <div
                                    className={`${dashboardclasses["select"]} ${dashboardclasses["product-search"]}`}>
                                    <input
                                        type="text"
                                        onChange={searchhandler}
                                        value={genericNameString}
                                        id="genericNameInput"
                                        autocomplete="off"
                                    />
                                    {genericNamesearch.length > 0 && (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "product-items"
                                                ]
                                            }
                                            id="genericsearch">
                                            {genericNamesearch.map((item) => {
                                                console.log(item);
                                                return (
                                                    <button
                                                        onClick={buttonHandler}
                                                        id={item.value}
                                                        moleculeCompositionId={
                                                            item.moleculeCompositionId
                                                        }
                                                        name={item.name}
                                                        type={"button"}>
                                                        {item.name}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {genericError && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            Select a valid generic Name
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["dimension-wrapper"]
                                }>
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="productName">MRP</label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            onWheel={(event) =>
                                                event.currentTarget.blur()
                                            }
                                            step="0.001"
                                            {...register("price", {
                                                required: "price is required",
                                            })}
                                        />

                                        {errors?.price && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.price?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="productName">
                                        Medbuzz Price
                                    </label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            onWheel={(event) =>
                                                event.currentTarget.blur()
                                            }
                                            step="0.001"
                                            {...register(
                                                "medbuzz_sellingPrice",
                                                {
                                                    required:
                                                        "price is required",
                                                }
                                            )}
                                        />

                                        {errors?.medbuzz_sellingPrice && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {
                                                    errors?.medbuzz_sellingPrice
                                                        ?.message
                                                }
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="is_available_in_medbuzz">
                                    Medbuzz availability
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        {...register(
                                            "is_available_in_medbuzz",
                                            {
                                                required: "this is required",
                                            }
                                        )}>
                                        <option disabled value="">
                                            Select availability
                                        </option>
                                        <option value={1}> Available</option>
                                        <option value={0}>
                                            {" "}
                                            Not available
                                        </option>
                                    </select>

                                    {errors?.is_available_in_medbuzz && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.is_available_in_medbuzz
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="is_available_in_medbuzz">
                                    genifast availability
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        {...register(
                                            "is_available_in_genifast",
                                            {
                                                required: "this is required",
                                            }
                                        )}>
                                        <option disabled value="">
                                            Select availability
                                        </option>
                                        <option value={1}> Available</option>
                                        <option value={0}>
                                            {" "}
                                            Not available
                                        </option>
                                    </select>

                                    {errors?.is_available_in_genifast && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.is_available_in_genifast
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    medbuzz max order qty
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("medbuzz_max_order_qty", {
                                            required: "qty is required",
                                        })}
                                    />

                                    {errors?.medbuzz_max_order_qty && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.medbuzz_max_order_qty
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    medbuzz product ID
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("medbuzz_productID")}
                                    />

                                    {errors?.medbuzz_productID && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.medbuzz_productID?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    Safety stock Qty
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("safety_stock_qty")}
                                    />

                                    {errors?.safety_stock_qty && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.safety_stock_qty?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    isPrescriptionRequired
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        {...register("isPrescriptionRequired")}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>

                                    {errors?.isPrescriptionRequired && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.isPrescriptionRequired
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="productName">
                                    Genifast User selling price
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        onWheel={(event) =>
                                            event.currentTarget.blur()
                                        }
                                        step="0.001"
                                        {...register(
                                            "genifast_usersellingPrice",
                                            {
                                                required: "price is required",
                                            }
                                        )}
                                    />

                                    {errors?.genifast_usersellingPrice && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors
                                                    ?.genifast_usersellingPrice
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="sellingPrice">
                                    Genifast Selling Price
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        step="0.00001"
                                        onWheel={(event) =>
                                            event.currentTarget.blur()
                                        }
                                        {...register("genifast_sellingPrice", {
                                            required:
                                                "selling Price is required",
                                        })}
                                    />

                                    {errors?.genifast_sellingPrice && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.genifast_sellingPrice
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="gst">GST</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        onWheel={(event) =>
                                            event.currentTarget.blur()
                                        }
                                        step="0"
                                        type={"number"}
                                        {...register("gst", {
                                            required: "gst is required",
                                        })}
                                    />

                                    {errors?.gst && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.gst?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="packing">Packing</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("packing", {
                                            required: "packing  is required",
                                        })}
                                    />

                                    {errors?.packing && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.packing?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="packing">ManufacturedBy</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type="text"
                                        list="manufacturedBy"
                                        placeholder=" Manufacturedby"
                                        {...register("manufacturedBy", {
                                            required:
                                                "manufacturedBy  is required",
                                        })}
                                    />
                                    <datalist id="manufacturedBy">
                                        {manufacturedBy
                                            .sort((a, b) => {
                                                if (
                                                    a.companyName >
                                                    b.companyName
                                                )
                                                    return 1;
                                                if (
                                                    b.companyName >
                                                    a.companyName
                                                )
                                                    return -1;
                                                return 0;
                                            })
                                            .map((item) => (
                                                <option
                                                    value={item?.companyName}>
                                                    {item?.companyName}
                                                </option>
                                            ))}
                                    </datalist>
                                    {/* <select
                                        type={"text"}
                                        defaultValue=""
                                        >
                                        <option value="">
                                            Select Manufacturer
                                        </option>
                                        {manufacturedBy.map((item) => {
                                            return (
                                                <option
                                                    value={item?.companyName}>
                                                    {item?.companyName}
                                                </option>
                                            );
                                        })}
                                    </select> */}

                                    {errors?.manufacturedBy && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.manufacturedBy?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            {/* <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="Description">Description</label>
                                <div className={dashboardclasses["select"]}>
                                    <textarea
                                        rows="5"
                                        {...register("description")}></textarea>

                                    {errors?.description && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.description?.message}
                                        </p>
                                    )}
                                </div>
                            </div> */}
                            <div
                                className={
                                    dashboardclasses["dimension-wrapper"]
                                }>
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="length">
                                        Order Length (cms)
                                    </label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            step="0.00001"
                                            {...register("length", {
                                                required: "length  is required",
                                            })}
                                        />

                                        {errors?.length && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.length?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="breadth">
                                        Breadth (cms)
                                    </label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            step="0.00001"
                                            {...register("breadth", {
                                                required:
                                                    "breadth  is required",
                                            })}
                                        />

                                        {errors?.breadth && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.breadth?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="height">Height (cms)</label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            step="0.00001"
                                            {...register("height", {
                                                required: "height  is required",
                                            })}
                                        />

                                        {errors?.height && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.height?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["item-image-upload"]
                                    }>
                                    <label htmlFor="weight">Weight (gms)</label>
                                    <div className={dashboardclasses["select"]}>
                                        <input
                                            type={"number"}
                                            step="0.00001"
                                            {...register("weight", {
                                                required: "weight  is required",
                                            })}
                                        />

                                        {errors?.weight && (
                                            <p
                                                className={
                                                    dashboardclasses["error"]
                                                }>
                                                {errors?.weight?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="HSNcode">HSN Code</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"text"}
                                        {...register("HSNcode", {
                                            required: "HSNcode is required",
                                        })}
                                    />

                                    {errors?.HSNcode && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.HSNcode?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="status">Status</label>
                                <div className={dashboardclasses["select"]}>
                                    <select
                                        {...register("status", {
                                            required: "Status is required",
                                        })}>
                                        <option value="1">Active</option>
                                        <option value="2">inactive</option>
                                        <option value="3">No stock</option>
                                    </select>

                                    {errors?.status && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.status?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {/* <div className={dashboardclasses["item-tag-input"]}>
                        <label htmlFor=" child">Child Category</label>
                        <div className={dashboardclasses["item-tag"]}>
                            <TagsInput
                                value={selected}
                                onChange={setSelected}
                                name="fruits"
                                placeHolder="Child Category"
                            />
                        </div>
                    </div> */}

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Product"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
