import React from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/login/login-office.jpeg";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { menuItems } from "../Navbar/config";
import useStore from "../../Store";
function Login() {
    const Navigate = useNavigate();
    const setadmindata = useStore((State) => State.setAdminData);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        const body = JSON.stringify(data);
        Services.login("POST", body)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    localStorage.setItem(
                        "admindata",
                        JSON.stringify(response?.adminDetails)
                    );
                    // setadmindata(response?.adminDetail);
                    localStorage.setItem("token", response?.token);
                    const navitem = menuItems?.filter((item) => {
                        console.log(
                            response?.adminDetails.adminroldeinfo.accessItems[0]
                        );
                        return (
                            item.name ==
                            response?.adminDetails.adminroldeinfo.accessItems[0]
                        );
                    });
                    console.log(navitem);
                    Navigate(navitem[0].menuLink);
                    // Navigate("/Warehouse");
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["login-subwrapper"]}>
                <img src={LoginLogo} alt="login-logo" />

                <div className={LoginClasses["login-form"]}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                {...register("emailId", {
                                    required: "Email is required!",
                                })}
                            />
                            {errors?.emailId && (
                                <p className={LoginClasses.error}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="password">Password</label>

                            <input
                                type="password"
                                {...register("password", {
                                    required: "Password is required!",
                                })}
                            />
                            {errors?.password && (
                                <p className={LoginClasses.error}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                        <input type="submit" value="Log in" />
                        <span className={LoginClasses.border}></span>
                    </form>

                    <div className={LoginClasses["login-links"]}>
                        <NavLink to="/forgotpassword">
                            forgot your Password?
                        </NavLink>
                        {/* <NavLink to="/createaccount">
                            Create Your Account
                        </NavLink> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
