import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import image from "../../../Assets/image.png";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function OrdersReturnModal({
    setmodal,
    ledgerDetails,
    prodid,
    getcategories,
    qty,
}) {
    const Navigate = useNavigate();
    const [notes, setNotes] = useState();
    const [quantity, setquantity] = useState(qty);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const CancelHandler = (e) => {
        e.stopPropagation();
        setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const submitHandler = (e) => {
        e.preventDefault();
        let json = JSON.stringify({ availableQty: quantity });
        Services.updateQTY("PUT", json, token, prodid)
            .then((res) => {
                console.log(res);
                // setLoading(false);
                if (res.Status === 1) {
                    setmodal(false);
                    getcategories();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrders(res.Orders);
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    // console.log(selectedData);
    return (
        <div
            className={dashboardclasses["return-modal"]}
            onClick={CancelHandler}>
            <div
                className={`${dashboardclasses["return-modalContent"]} ${dashboardclasses["ledger-modalContent"]}`}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <form
                    className={dashboardclasses["order-item-data"]}
                    style={{ gap: "1rem" }}
                    onSubmit={submitHandler}>
                    <h4> {ledgerDetails?.productName}</h4>
                    <div className={dashboardclasses["ledger-form-control"]}>
                        <label htmlFor="Quantity">Quantity</label>
                        <input
                            type="number"
                            onChange={(e) => setquantity(e.target.value)}
                            value={quantity}
                        />
                    </div>

                    <button
                        type={"submit"}
                        className={dashboardclasses["submit-btn"]}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default OrdersReturnModal;
