import React, { useEffect, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddProducts(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const [categoryItems, setCategoryItems] = useState([]);
    const [SupplierInvoices, setSupplierInvoices] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        Services.getSupplier("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setCategoryItems(res.Suppliers);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        if (props.editable) {
            Services.SupplierPaymentDetails(
                "GET",
                null,
                token,
                props.promocodeid
            )
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        // setCategoryItems(response.categories);
                        reset({ ...response.supplierPayments });
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (response.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const supplierBills = (supplierId) => {
        Services.getInvoicebyId("GET", null, token, supplierId)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setSupplierInvoices(res.SupplierInvoice);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const onSubmit = (data) => {
        console.log(data);
        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            props.tableload(true);
            Services.addSupplierPayment("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        Navigate(`/supplierpayments?page=${1}`);
                        props.setpageCount(1);
                        props.tableload(false);
                        props.setmodal(false);
                        props.getpromocodes();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        props.tableload(false);
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    props.tableload(false);
                    alert("something went wrong please try again");

                    console.log(err);
                });
        } else if (props.editable) {
            Services.SupplierPaymentUpdate(
                "PUT",
                method,
                token,
                props.promocodeid
            )
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getpromocodes();

                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable
                                ? "Update Supplier Payment"
                                : "Add Supplier Payment"}
                        </h3>
                        <p>
                            {props.editable
                                ? "Update Supplier Payment"
                                : "Add Supplier Payment"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Supplier</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("supplierID", {
                                    required: "supplierID is required",
                                    onChange: (e) => {
                                        supplierBills(e.target.value);
                                    },
                                })}>
                                <option value="" disabled>
                                    Select Supplier
                                </option>
                                {categoryItems.map((item) => (
                                    <option value={item.supplierId}>
                                        {item.SupplierName}
                                    </option>
                                ))}
                            </select>

                            {errors?.supplierID && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.supplierID?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="amount">Amount</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"number"}
                                {...register("amount", {
                                    required: "amount is required",
                                })}
                            />

                            {errors?.amount && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.amount?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="invoiceNumber">Invoice number</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                {...register("invoiceNumber", {
                                    required: "Invoice number is required",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select invoive
                                </option>
                                {SupplierInvoices.length > 0 &&
                                    SupplierInvoices.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                            </select>

                            {errors?.invoiceNumber && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.invoiceNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="chequeNumber">Cheque number</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                {...register("chequeNumber", {
                                    required: "Cheque number is required",
                                })}
                            />
                            {errors?.chequeNumber && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.chequeNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="transactionId">Transaction Id</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"text"}
                                // step="0.00001"
                                {...register("transactionId", {
                                    required: "Transaction ID is required",
                                })}
                            />

                            {errors?.transactionId && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.transactionId?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {/* 
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor=" type">Status</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "Status is required",
                                })}>
                                <option value="" disabled>
                                    Status
                                </option>
                                <option value="1">Active </option>
                                <option value="2">InActive</option>
                            </select>
                            {errors?.status && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div> */}
                    {/* </div> */}

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Supplier Payment"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
