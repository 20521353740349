import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "../../../App.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import image from "../../../Assets/image.png";
import ReturnModal from "./ordersReturnModal";

import CancelModel from "./cancelOrder";
function Products() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [returnMododal, setReturnModal] = useState(false);
    const [cancelModel, setcancelmodel] = useState(false);
    const { id } = useParams();
    let [loading, setLoading] = useState(true);
    const [status, setStatus] = useState();
    const [orders, setOrders] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getorders = () => {
        Services.orderbyid("GET", null, token, id)
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.Status === 1) {
                    setOrders(res.Orders);
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        getorders();

        // eslint-disable-next-line
    }, []);

    const dateformatter = (cell) => {
        const date = new Date(cell);

        return (
            <p>
                <Moment format="MMMM Do YYYY hh:mm a">{date}</Moment>
            </p>
        );
    };

    const orderstatusformatter = (cell, cellcontent) => {
        const date = new Date(orders.deliveredDateTime);
        //eslint-disable-next-line
        if (cell == 1) {
            return (
                <div
                    style={{
                        display: "grid",
                        alignItems: "center",
                        justifyItems: "center",
                        justifySelf: "right",
                        gap: "0.5rem",
                    }}>
                    <div
                        className={`${
                            dashboardclasses[`order-delivered-wrapper`]
                        } ${dashboardclasses[`order-status-wrapper`]}`}>
                        <p className={dashboardclasses["order-delivered"]}>
                            Delivered
                        </p>
                    </div>
                    <Moment format="MMMM Do YYYY hh:mm a">{date}</Moment>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 2) {
            return (
                <div
                    className={`${dashboardclasses[`order-pending-wrapper`]} ${
                        dashboardclasses[`order-status-wrapper`]
                    }`}>
                    <p>Pending</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 3) {
            return (
                <div
                    className={`${
                        dashboardclasses[`order-delivered-wrapper`]
                    } ${dashboardclasses[`order-status-wrapper`]}`}>
                    <p>Shipment Order Created</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 7 || cell == 5) {
            return (
                <div
                    className={`${dashboardclasses[`order-returned-wrapper`]} ${
                        dashboardclasses[`order-status-wrapper`]
                    }`}>
                    <p>Cancelled</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 6) {
            return (
                <div className={dashboardclasses[`cancel-wrapper`]}>
                    <p className={dashboardclasses["cancel"]}>Returned </p>
                </div>
            );
        }
    };
    const statuschangehandler = (e) => {
        setStatus(e.target.value);
    };
    const statusHandler = (e) => {
        setLoading(true);
        let body = {
            orderStatus: status,
        };
        Services.UpdateOrderDetails("PUT", JSON.stringify(body), token, id)
            .then((res) => {
                if (res.Status === 1) {
                    getorders();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <>
            {cancelModel && (
                <CancelModel
                    setmodal={setcancelmodel}
                    id={id}
                    getorders={getorders}
                    setLoading={setLoading}
                />
            )}
            {returnMododal && (
                <ReturnModal
                    setmodal={setReturnModal}
                    orders={orders}
                    getorders={getorders}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="orders" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses.CategoryWrapper}>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <button
                                    className={dashboardclasses["backbutton"]}
                                    onClick={() => {
                                        Navigate(-1);
                                    }}>
                                    Back
                                </button>
                                <header
                                    className={
                                        dashboardclasses["order-details-header"]
                                    }>
                                    <h3>Order Details</h3>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "order-header-item"
                                            ]
                                        }>
                                        <h5>ORDER ID</h5>

                                        <p>#{orders.orderId}</p>
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "order-header-item"
                                            ]
                                        }>
                                        <h5>ORDER DATE TIME</h5>

                                        {dateformatter(orders.createdAt)}
                                    </div>
                                </header>
                                <div
                                    className={
                                        dashboardclasses[
                                            "orders-form-invoice-wrapper"
                                        ]
                                    }>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "orders-form-invoice"
                                            ]
                                        }>
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "invoice-detail"
                                                ]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "detail-status"
                                                    ]
                                                }>
                                                <span>status:</span>
                                                {orders?.orderStatus != 7 && (
                                                    <select
                                                        defaultValue={
                                                            orders.orderStatus
                                                        }
                                                        onChange={
                                                            statuschangehandler
                                                        }>
                                                        {" "}
                                                        <option value="1">
                                                            DELIVERED{" "}
                                                        </option>
                                                        <option value="2">
                                                            PENDING
                                                        </option>
                                                        <option value="3">
                                                            Shipment Order
                                                            Created
                                                        </option>
                                                        {/* <option value="7">
                                                        Cancel Order
                                                    </option> */}
                                                    </select>
                                                )}
                                                {orders.orderStatus != 7 && (
                                                    <button
                                                        onClick={statusHandler}>
                                                        Update
                                                    </button>
                                                )}
                                                {orders.orderStatus == 7 && (
                                                    <p
                                                        className={
                                                            dashboardclasses[
                                                                "cancelled"
                                                            ]
                                                        }>
                                                        Cancelled
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-handler"
                                                    ]
                                                }>
                                                <button
                                                    onClick={(e) =>
                                                        setReturnModal(true)
                                                    }>
                                                    Process Return Items
                                                </button>
                                                {orderstatusformatter(
                                                    orders.orderStatus
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                dashboardclasses["order-items"]
                                            }>
                                            <header
                                                className={
                                                    dashboardclasses[
                                                        "customer-info-header"
                                                    ]
                                                }>
                                                <h4>Customer Info</h4>
                                                {/* eslint-disable-next-line */}
                                                {/* {orders.isShipmentCreated ==
                                                0 ? (
                                                    <button
                                                        className={
                                                            dashboardclasses[
                                                                "add-shipment"
                                                            ]
                                                        }
                                                        onClick={
                                                            addshipmentHandler
                                                        }>
                                                        +Add Shipment Order
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={
                                                            dashboardclasses[
                                                                "add-shipment"
                                                            ]
                                                        }
                                                        onClick={() => {
                                                            setshipmentDetails(
                                                                true
                                                            );
                                                        }}>
                                                        {" "}
                                                        Shipment Details
                                                    </button>
                                                )} */}
                                            </header>

                                            <div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-item-data"
                                                        ]
                                                    }>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Name</h5>
                                                        <h4>:</h4>
                                                        <p>{orders.name}</p>
                                                    </div>

                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Contact Number</h5>
                                                        <h4>:</h4>
                                                        <p>
                                                            {
                                                                orders.contactNumber
                                                            }
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Email Id</h5>
                                                        <h4>:</h4>
                                                        <p> {orders.emailId}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-item-data"
                                                    ]
                                                }
                                                style={{ gap: "1rem" }}>
                                                <h4> Order List</h4>
                                                {orders?.orderitems?.map(
                                                    (items) => (
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "order-item"
                                                                ]
                                                            }>
                                                            <img
                                                                src={
                                                                    items.imageUrl !==
                                                                    "NA"
                                                                        ? items.imageUrl
                                                                        : image
                                                                }
                                                                alt="product"
                                                                onError={({
                                                                    currentTarget,
                                                                }) => {
                                                                    currentTarget.onerror =
                                                                        null; // prevents looping
                                                                    currentTarget.src =
                                                                        image;
                                                                }}
                                                            />
                                                            <div
                                                                className={
                                                                    dashboardclasses[
                                                                        "order-desc"
                                                                    ]
                                                                }>
                                                                <h3>
                                                                    {
                                                                        items.productName
                                                                    }
                                                                </h3>
                                                                <p>
                                                                    Quantity:
                                                                    {
                                                                        items.quantity
                                                                    }
                                                                </p>
                                                                <p>
                                                                    Company:{" "}
                                                                    {
                                                                        items?.manufacturedBy
                                                                    }
                                                                </p>
                                                                <h3>
                                                                    Price:₹
                                                                    {Math.round(
                                                                        items.genifast_usersellingPrice
                                                                    )}
                                                                    <span>
                                                                        (Inclusive
                                                                        of all
                                                                        taxes)
                                                                    </span>
                                                                </h3>
                                                                <p>
                                                                    MRP{" "}
                                                                    <del>
                                                                        ₹
                                                                        {
                                                                            items.price
                                                                        }
                                                                    </del>{" "}
                                                                    <span>
                                                                        You Save
                                                                        ₹
                                                                        {Math.round(
                                                                            items.price -
                                                                                items.sellingPrice
                                                                        )}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-item-data"
                                                    ]
                                                }>
                                                <h4>Shipping Details </h4>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date-address"
                                                        ]
                                                    }>
                                                    <h5>Address</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        {orders.address1} <br />
                                                        {orders.address2} <br />
                                                        {orders.city} <br />
                                                        {orders.state} <br />
                                                        {orders.pincode}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Landmark</h5>
                                                    <h4>:</h4>
                                                    <p>{orders.landmark}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Contact Number</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        {orders.contactNumber}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Email Id</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.emailId}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>AWB Number</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.AWBNumber}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>invoice</h5>
                                                    <h4>:</h4>
                                                    {/* <p> {orders.invoiceFile}</p> */}
                                                    <a
                                                        href={
                                                            orders.invoiceFile
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        Click me
                                                    </a>
                                                </div>
                                                {orders.orderStatus == 7 && (
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>
                                                            cancellation Reason
                                                        </h5>
                                                        <h4>:</h4>
                                                        {/* <p> {orders.invoiceFile}</p> */}
                                                        <p>
                                                            {
                                                                orders.cancellationReason
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                                {orders.isShipmentCreated ===
                                                    1 && (
                                                    <>
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "order-date"
                                                                ]
                                                            }>
                                                            <h5>
                                                                Shiprocket Order
                                                                Id
                                                            </h5>
                                                            <h4>:</h4>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    orders.ShiprocketOrderID
                                                                }
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "order-date"
                                                                ]
                                                            }>
                                                            <h5>
                                                                Tracking URL
                                                            </h5>
                                                            <h4>:</h4>
                                                            {orders.trackingUrl !==
                                                            "NA" ? (
                                                                <a
                                                                    href={
                                                                        orders.trackingUrl
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer">
                                                                    {
                                                                        orders.trackingUrl
                                                                    }
                                                                </a>
                                                            ) : (
                                                                <p>-</p>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-item-data"
                                                    ]
                                                }>
                                                <h4> Payment Details </h4>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Payment Mode</h5>
                                                    <h4>:</h4>
                                                    {orders.paymentType ===
                                                    1 ? (
                                                        <p> COD</p>
                                                    ) : (
                                                        <p>ONLINE</p>
                                                    )}
                                                </div>

                                                {orders.paymentType === 2 && (
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>PaymentID</h5>
                                                        <h4>:</h4>
                                                        <p>
                                                            {
                                                                orders.transactionId
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>SubTotal</h5>
                                                    <h4>:</h4>
                                                    <p> ₹ {orders.subTotal}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Discount Amount</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        ₹ {orders.promoAmount}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>
                                                        redeem Points Amount
                                                    </h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        ₹
                                                        {
                                                            orders.redeemPointsAmount
                                                        }
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Promocode</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.promocode}</p>
                                                </div>

                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Delivery Charges</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        {" "}
                                                        ₹{" "}
                                                        {orders.deliveryCharges}
                                                    </p>
                                                </div>
                                                {orders.paymentType === 1 && (
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>COD Charges</h5>
                                                        <h4>:</h4>
                                                        <p>₹ 50</p>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Grand Total</h5>
                                                    <h4>:</h4>

                                                    <p>₹ {orders.grandTotal}</p>
                                                </div>
                                            </div>
                                            {params.get("order-type") != 1 && (
                                                <button
                                                    style={{
                                                        justifySelf: "center",
                                                        height: "2.5rem",
                                                        width: "8rem",
                                                        fontSize: "1rem",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        backgroundColor: "red",
                                                        color: "white",
                                                    }}
                                                    onClick={() => {
                                                        setcancelmodel(true);
                                                    }}>
                                                    Cancel Order
                                                </button>
                                            )}
                                            {/* <div className="category-table">
                                            <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={orders.orderitems}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                                wrapperClasses="table-responsive"
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;
