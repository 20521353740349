import React, { useState, useRef, useLayoutEffect } from "react";
import sideNavClasses from "./navbar.module.css";
import { useNavigate, NavLink } from "react-router-dom";
import { FaShoppingBag } from "react-icons/fa";
import {
    AiOutlineMenu,
    AiOutlineDown,
    AiOutlineUnorderedList,
    AiOutlineFileSearch,
} from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { menuItems } from "./config";
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { CgShoppingBag } from "react-icons/cg";
import { useSearchParams } from "react-router-dom";
import Logout from "./logout";
import useStore from "../../Store";
function SideNav(props) {
    const showNav = useStore((state) => state.showNavbar);
    const setShowNav = useStore((State) => State.setshowNavbar);
    const [getparams] = useSearchParams();
    const isactive = props.isactive;
    const element = useRef();
    const showitem = useStore((State) => State.showSeo);
    const setshowitem = useStore((State) => State.setshowNSeo);
    const showMedbuzz = useStore((State) => State.showMedbuzz);
    const setshowMedbuzz = useStore((State) => State.setshowMedbuzz);

    const [navItems, setNavItems] = useState([]);
    const [logout, setlogout] = useState(false);
    const Navigate = useNavigate();

    useLayoutEffect(() => {
        const userdata = JSON.parse(localStorage.getItem("admindata"));
        // window.scrollTo({ top: "0" });
        let sideNav = menuItems.filter((item) =>
            userdata.adminroldeinfo.accessItems.includes(item.name)
        );
        setNavItems(sideNav);
    }, []);

    const homeHandler = () => {
        Navigate("/Warehouse");
    };
    const navbarhandler = () => {
        setShowNav((prev) => !prev);
        // localStorage.setItem("showNav", !showNav);
    };
    const logouthandler = () => {
        setlogout(true);
    };
    let type = getparams.get("type");
    return (
        <>
            {logout && <Logout setmodal={setlogout} />}
            <div
                ref={element}
                className={`${sideNavClasses["sidenavwrapper"]} `}>
                <div className={`${sideNavClasses["sidenavbar"]}`}>
                    <div className={sideNavClasses["logo-wrap"]}>
                        {showNav && (
                            <button
                                className={sideNavClasses["logo"]}
                                onClick={homeHandler}>
                                <FaShoppingBag
                                    className={sideNavClasses["logo-icon"]}
                                    color="#057a55"
                                />
                                <p>GeniFast</p>
                            </button>
                        )}
                        <button
                            onClick={navbarhandler}
                            style={{ cursor: "pointer", marginTop: "0.5rem" }}>
                            <AiOutlineMenu />
                        </button>
                    </div>
                    <div className={sideNavClasses["Navlinks"]}>
                        {navItems.map((item) => {
                            if (item.id == "SEO") {
                                return (
                                    <div className={sideNavClasses["seo"]}>
                                        <div
                                            className={
                                                sideNavClasses["seo-item"]
                                            }
                                            onClick={() => {
                                                setshowitem((prev) => !prev);
                                            }}>
                                            <IoBriefcaseOutline
                                                className={
                                                    sideNavClasses["nav-icon"]
                                                }
                                            />
                                            {showNav && <p>SEO </p>}
                                            {showitem ? (
                                                <AiOutlineDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            )}
                                        </div>
                                        {showitem && (
                                            <div
                                                className={
                                                    sideNavClasses[
                                                        "seo-item-nav"
                                                    ]
                                                }>
                                                <NavLink
                                                    key={1}
                                                    id={1}
                                                    to={
                                                        "/categories?type=categories-seo&page=1"
                                                    }
                                                    className={
                                                        type == "categories-seo"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <AiOutlineUnorderedList
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            Categories
                                                        </span>
                                                    )}
                                                </NavLink>
                                                <NavLink
                                                    key={2}
                                                    id={2}
                                                    to={
                                                        "/products?type=products-seo"
                                                    }
                                                    className={
                                                        type === "products-seo"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <CgShoppingBag
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            Master Products
                                                        </span>
                                                    )}
                                                </NavLink>
                                            </div>
                                        )}
                                    </div>
                                );
                            } else if (item.id === "Medbuzz") {
                                return (
                                    <div className={sideNavClasses["seo"]}>
                                        <div
                                            className={
                                                sideNavClasses["seo-item"]
                                            }
                                            onClick={() => {
                                                setshowMedbuzz((prev) => !prev);
                                            }}>
                                            <IoBriefcaseOutline
                                                className={
                                                    sideNavClasses["nav-icon"]
                                                }
                                            />
                                            {showNav && <p>Medbuzz </p>}
                                            {showMedbuzz ? (
                                                <AiOutlineDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            )}
                                        </div>
                                        {showMedbuzz && (
                                            <div
                                                className={
                                                    sideNavClasses[
                                                        "seo-item-nav"
                                                    ]
                                                }>
                                                <NavLink
                                                    key={1}
                                                    id={1}
                                                    to={
                                                        "/homepage-sections?type=sections&page=1"
                                                    }
                                                    className={
                                                        type == "sections"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <AiOutlineUnorderedList
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            homePage sections
                                                        </span>
                                                    )}
                                                </NavLink>
                                                <NavLink
                                                    key={2}
                                                    id={2}
                                                    to={
                                                        "/user-info?type=download"
                                                    }
                                                    className={
                                                        type === "download"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <CgShoppingBag
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            User info
                                                        </span>
                                                    )}
                                                </NavLink>
                                                <NavLink
                                                    key={3}
                                                    id={3}
                                                    to={
                                                        "/failedorders?type=orders-download"
                                                    }
                                                    className={
                                                        type ===
                                                        "orders-download"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <CgShoppingBag
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            Failed Orders
                                                        </span>
                                                    )}
                                                </NavLink>
                                                <NavLink
                                                    key={4}
                                                    id={4}
                                                    to={
                                                        "/medbuzz-users?type=users&page=1"
                                                    }
                                                    className={
                                                        type === "users"
                                                            ? sideNavClasses[
                                                                  "nav-active"
                                                              ]
                                                            : sideNavClasses[
                                                                  "nav-inactive"
                                                              ]
                                                    }>
                                                    {!showNav && (
                                                        <AiOutlineFileSearch
                                                            className={
                                                                sideNavClasses[
                                                                    "nav-icon"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {showNav && (
                                                        <span
                                                            style={
                                                                showNav
                                                                    ? {
                                                                          marginLeft:
                                                                              "1.2rem",
                                                                      }
                                                                    : {
                                                                          marginLeft:
                                                                              "0rem",
                                                                      }
                                                            }>
                                                            Medbuzz Users
                                                        </span>
                                                    )}
                                                </NavLink>
                                            </div>
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <NavLink
                                        key={item.id}
                                        id={item.id}
                                        to={item.menuLink}
                                        className={
                                            isactive === item.active && !type
                                                ? sideNavClasses["nav-active"]
                                                : sideNavClasses["nav-inactive"]
                                        }>
                                        <item.icon
                                            className={
                                                sideNavClasses["nav-icon"]
                                            }
                                        />
                                        {showNav && <span>{item.name}</span>}
                                    </NavLink>
                                );
                            }
                        })}
                    </div>
                    <div
                        className={`${
                            !showNav
                                ? sideNavClasses["logout-short"]
                                : sideNavClasses["logout"]
                        }`}>
                        <button onClick={logouthandler}>
                            {" "}
                            <HiOutlineLogout size={22} />
                            {showNav && <span>Logout</span>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SideNav;
