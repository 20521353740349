import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { BsFillBarChartFill } from "react-icons/bs";
import { BiLineChart } from "react-icons/bi";
function Warehouse() {
    const fromRef = useRef();
    const toRef = useRef();
    const Navigate = useNavigate();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    const [Sales, setSales] = useState({ total: 0, medbuzz: 0, genifast: 0 });
    const [custom, setCustom] = useState({
        total: false,
        medbuzz: false,
        genifast: false,
    });
    const [stockValue, setStockValue] = useState({ totalstockvalue: 0 });
    const sales = (type, app) => {
        Services.sales("GET", null, token, type, app)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setSales((prev) => {
                        return { ...prev, [app]: res.Sales };
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const Stock = () => {
        Services.StockValue("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setStockValue(res.Stockvalue);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        sales("all", "totalsales");
        sales("all", "genifastsales");
        sales("all", "medbuzzsales");
        Stock();
    }, []);
    const dateHandler = (e) => {
        let app = e.currentTarget.getAttribute("app");
        let type = e.target.value;
        if (type == "custom") {
            setCustom((prev) => {
                return { ...prev, [app]: true };
            });
            return;
        } else {
            setCustom((prev) => {
                return { ...prev, [app]: false };
            });
            if (type == "today") {
                sales("today", app);
            } else if (type === "thismonth") {
                sales("thismonth", app);
            } else if (type === "thisweek") {
                sales("thisweek", app);
            } else if (type === "all") {
                sales("all", app);
            }
        }
    };
    const submitHandler = (e) => {
        const app = e.currentTarget.getAttribute("app");
        e.preventDefault();
        let fromDate = fromRef.current.value;
        let toDate = toRef.current.value;
        console.log(fromDate, toDate);
        Services.salesByDates("GET", null, token, fromDate, toDate, app)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setSales((prev) => {
                        return { ...prev, [app]: res.Sales };
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <>
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="dashboard" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Dashboard</h3>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["dash-wrapper"]
                                    }>
                                    <div
                                        className={
                                            dashboardclasses["dash-Totalsales"]
                                        }>
                                        <select
                                            app="totalsales"
                                            defaultValue="all"
                                            onChange={dateHandler}>
                                            <option value={"all"}>All</option>
                                            <option value={"today"}>
                                                Today
                                            </option>
                                            <option value={"thisweek"}>
                                                This week
                                            </option>
                                            <option value={"thismonth"}>
                                                This Month
                                            </option>
                                            <option value={"custom"}>
                                                Custom Date
                                            </option>
                                        </select>
                                        {custom.totalsales && (
                                            <form>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>From Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={fromRef}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>To Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={toRef}
                                                    />
                                                </div>
                                                <button
                                                    app="totalsales"
                                                    type={"submit"}
                                                    onClick={submitHandler}>
                                                    Go
                                                </button>
                                            </form>
                                        )}
                                        <div
                                            className={
                                                dashboardclasses["sales-one"]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses["icon"]
                                                }>
                                                <BsFillBarChartFill
                                                    size={36}
                                                    color="orange"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "totalsales-count"
                                                    ]
                                                }>
                                                <p>Total Sales</p>
                                                <h3>
                                                    ₹{" "}
                                                    {Number(
                                                        Sales.totalsales
                                                    ).toFixed(2)}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses["dash-Totalsales"]
                                        }>
                                        <select
                                            app="genifastsales"
                                            defaultValue="all"
                                            onChange={dateHandler}>
                                            <option value={"all"}>All</option>
                                            <option value={"today"}>
                                                Today
                                            </option>
                                            <option value={"thisweek"}>
                                                This week
                                            </option>
                                            <option value={"thismonth"}>
                                                This Month
                                            </option>
                                            <option value={"custom"}>
                                                Custom Date
                                            </option>
                                        </select>
                                        {custom.genifastsales && (
                                            <form>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>From Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={fromRef}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>To Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={toRef}
                                                    />
                                                </div>
                                                <button
                                                    app="genifastsales"
                                                    type={"submit"}
                                                    onClick={submitHandler}>
                                                    Go
                                                </button>
                                            </form>
                                        )}
                                        <div
                                            className={
                                                dashboardclasses["sales-one"]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses["icon"]
                                                }>
                                                <BsFillBarChartFill
                                                    size={36}
                                                    color="orange"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "totalsales-count"
                                                    ]
                                                }>
                                                <p>Genifast Sales</p>
                                                <h3>
                                                    ₹{" "}
                                                    {Number(
                                                        Sales.genifastsales
                                                    ).toFixed(2)}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses["dash-Totalsales"]
                                        }>
                                        <select
                                            app="medbuzzsales"
                                            defaultValue="all"
                                            onChange={dateHandler}>
                                            <option value={"all"}>All</option>
                                            <option value={"today"}>
                                                Today
                                            </option>
                                            <option value={"thisweek"}>
                                                This week
                                            </option>
                                            <option value={"thismonth"}>
                                                This Month
                                            </option>
                                            <option value={"custom"}>
                                                Custom Date
                                            </option>
                                        </select>
                                        {custom.medbuzzsales && (
                                            <form>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>From Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={fromRef}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "date-control"
                                                        ]
                                                    }>
                                                    <label>To Date</label>
                                                    <input
                                                        type={"date"}
                                                        ref={toRef}
                                                    />
                                                </div>
                                                <button
                                                    app="medbuzzsales"
                                                    type={"submit"}
                                                    onClick={submitHandler}>
                                                    Go
                                                </button>
                                            </form>
                                        )}
                                        <div
                                            className={
                                                dashboardclasses["sales-one"]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses["icon"]
                                                }>
                                                <BsFillBarChartFill
                                                    size={36}
                                                    color="orange"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "totalsales-count"
                                                    ]
                                                }>
                                                <p>medbuzz Sales</p>
                                                <h3>
                                                    ₹{" "}
                                                    {Number(
                                                        Sales.medbuzzsales
                                                    ).toFixed(2)}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            dashboardclasses[
                                                "dash-TotalStockValue"
                                            ]
                                        }>
                                        <div
                                            className={
                                                dashboardclasses["sales-one"]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses["icon"]
                                                }>
                                                <BiLineChart
                                                    size={36}
                                                    color="orange"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "totalsales-count"
                                                    ]
                                                }>
                                                <p>Stock Value</p>
                                                <h3>
                                                    ₹{" "}
                                                    {Number(
                                                        stockValue?.totalstockvalue
                                                    ).toFixed(2)}
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                dashboardclasses["stock-value"]
                                            }>
                                            <table
                                                className={
                                                    dashboardclasses[
                                                        "stock-data"
                                                    ]
                                                }>
                                                <thead>
                                                    <tr>
                                                        <th>Warehouse Name</th>
                                                        <th>Stock Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stockValue
                                                        ?.stockbywarehouse
                                                        ?.length > 0 &&
                                                        stockValue?.stockbywarehouse?.map(
                                                            (item) => (
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            item.warehouseName
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{" "}
                                                                        {Number(
                                                                            item.stockvalue
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
