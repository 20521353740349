import React, { useEffect } from "react";
import poclasses from "./po.module.css";

function ProductDetails(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const selectHandler = (e) => {
        const POitemId = e.currentTarget.getAttribute("POitemId");
        const selectedBatch = props.batchDetails.filter(
            (suppliers) => suppliers.POitemId == POitemId
        );
        console.log(props.batchDetails);
        props.setmodal(false);
        props.setSelectedBach(selectedBatch[0]);
        console.log(selectedBatch[0]);
        let expiryParse = selectedBatch[0]?.expiry.split("/");
        console.log(props.selectedProducts);
        props.reset({
            expiry: `${expiryParse[0]}/${expiryParse[1]}`,
            batchNumber: selectedBatch[0]?.batchNumber,

            productCode: props.selectedProducts.productCode,
            gstPercentage: props.selectedProducts.gst,
            purchasePrice: selectedBatch[0].purchasePrice,
        });
    };

    return (
        <>
            <div className={poclasses["modal"]} onClick={CancelHandler}>
                <div
                    className={poclasses["modalContent"]}
                    onClick={openhandler}
                    style={{ width: "100%" }}>
                    {/* <div className={poclasses["add-batch"]}>
                        {" "}
                        <p>Batch Info</p>
                        <button onClick={addHandler}>Add Batch Info</button>
                    </div> */}

                    <div className={poclasses["product-table"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th>POitemId</th>
                                    <th>Supplier name</th>
                                    <th>Batch number</th>
                                    <th>GST Percentage</th>
                                    <th>purchase price</th>
                                    <th>Selling rice</th>
                                    <th>Quantity</th>
                                    <th>Units</th>
                                    <th>Expiry</th>
                                    {/* <th>Supplier Type</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {props?.batchDetails?.length > 0 ? (
                                    props?.batchDetails?.map((item) => {
                                        return (
                                            <tr
                                                onClick={selectHandler}
                                                POitemId={item.POitemId}
                                                key={item.batchNumber}>
                                                <td>{item?.POitemId}</td>
                                                <td>{item?.SupplierName}</td>
                                                <td>{item?.batchNumber}</td>
                                                <td>{item?.gstPercentage}</td>
                                                <td>{item?.purchasePrice}</td>
                                                <td>{item?.sellingPrice}</td>
                                                <td>{item?.quantity}</td>
                                                <td>{item?.units}</td>
                                                <td>{item?.expiry}</td>
                                                {/* <td>{item?.state}</td> */}
                                                {/* <td>{item?.GSTIN}</td> */}
                                                {/* <td>{item?.supplierType}</td> */}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={9}
                                            style={{ textAlign: "center" }}>
                                            No Batchinfo Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* productDetails */}
            </div>
        </>
    );
}

export default ProductDetails;
