import React, { useState, useRef, useEffect } from "react";
import topnavclasses from "./navbar.module.css";
import User from "../../Assets/user.svg";
import { FaBell } from "react-icons/fa";
import { GiCorn } from "react-icons/gi";
function TopNav() {
    const [notification, setNotification] = useState(false);
    const ref = useRef(null);

    const NotifcationHandler = () => {
        setNotification((prev) => !prev);
    };
    let adminData = JSON.parse(localStorage.getItem("admindata"));
    useEffect(() => {
        // Function for click event
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setNotification(false);
            }
        }

        // Adding click event listener
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);

    const items = [
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "stockout",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "NewOrder",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "stockout",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "stockout",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "stockout",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
        {
            desc: "Yellow Sweet Corn Stock out, Please check",
            stock: "stockout",
            date: "12 Dec 2021",
            time: "12:40PM",
        },
    ];
    return (
        <div className={topnavclasses["TopNav"]}>
            {/* <div className={topnavclasses["user-details"]}>
                <div className={topnavclasses["notifications"]} ref={ref}>
                    <FaBell
                        color="#0e9f6e"
                        size={22}
                        onClick={NotifcationHandler}
                    />
                    <p onClick={NotifcationHandler}>6</p>
                    {notification && (
                        <div className={topnavclasses["user-notifications"]}>
                            {items.map((item) => {
                                return (
                                    <div className={topnavclasses["item"]}>
                                        <GiCorn
                                            size={30}
                                            className={
                                                topnavclasses["item-icon"]
                                            }
                                        />
                                        <div
                                            className={
                                                topnavclasses["item-data"]
                                            }>
                                            <p
                                                className={
                                                    topnavclasses["desc"]
                                                }>
                                                {item?.desc}
                                            </p>
                                            <p>
                                                <span
                                                    className={
                                                        topnavclasses[
                                                            `${item.stock}`
                                                        ]
                                                    }>
                                                    {item.stock}
                                                </span>
                                                <span
                                                    className={
                                                        topnavclasses[
                                                            "date-time"
                                                        ]
                                                    }>
                                                    {item.date} {item.time}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div> */}
            <div className={topnavclasses["user"]}>
                {adminData?.warehouseName && (
                    <p>
                        <span>Warehouse</span> : {adminData.warehouseName}
                    </p>
                )}
                <img src={User} alt="user" />
                <p>{adminData?.firstName}</p>
            </div>
        </div>
    );
}

export default TopNav;
