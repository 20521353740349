import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import image from "../../../Assets/image.png";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function OrdersReturnModal({ setmodal, orders, getorders }) {
    console.log(orders);
    const Navigate = useNavigate();
    const [selectedData, setSelectedData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const [selectedProducts, setSelectedProducts] = useState([]);
    const CancelHandler = (e) => {
        e.stopPropagation();
        setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (selectedData.length == 0) {
            alert("Add atleast one product and Return Quantity");
            return;
        }
        let jsonData = JSON.stringify({
            orderId: orders.orderId,
            products: selectedData,
            warehouseId: orders.warehouseId,
        });
        console.log({
            orderId: orders.orderId,
            products: selectedData,
            warehouseId: orders.warehouseId,
        });
        // return;
        if (selectedData.length > 0) {
            Services.returnOrder("POST", jsonData, token)
                .then((res) => {
                    console.log(res);
                    // setLoading(false);
                    if (res.Status === 1) {
                        setmodal(false);
                        getorders();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // setOrders(res.Orders);
                        // setOrdersSearch(res.Orders);
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            alert("products cant be empty");
        }
    };

    const changehandler = (e) => {
        let productId = e.currentTarget.getAttribute("id");
        setSelectedProducts((prev) => {
            console.log([...new Set([productId, ...prev])]);
            return [...new Set([productId, ...prev])];
        });
        let returned = orders.returnItems.filter(
            (item) => item.productId == productId
        );
        let quantity = Number(e.target.value);
        if (orders.returnItems.length > 0 && returned.length > 0) {
            let orderitem = orders.orderitems.filter(
                (item) => item.productId == productId
            );

            console.log(returned);
            if (
                quantity >
                Number(
                    orderitem[0].quantity -
                        Number(returned[0].TotalReturnedQuantity)
                )
            ) {
                alert(
                    `the return Qty should be less than or equal to ${
                        orderitem[0].quantity -
                        Number(returned[0].TotalReturnedQuantity)
                    } `
                );
                let newProducts = selectedProducts.filter(
                    (item) => item != productId
                );
                setSelectedProducts(newProducts);
                document.getElementById(productId).value = null;
                return;
            }
        } else {
            let orderitem = orders.orderitems.filter(
                (item) => item.productId == productId
            );
            if (quantity > Number(orderitem[0].quantity)) {
                alert(
                    `the return Qty should be less than or equal to ${orderitem[0].quantity} `
                );
                let newProducts = selectedProducts.filter(
                    (item) => item != productId
                );
                setSelectedProducts(newProducts);
                document.getElementById(productId).value = null;
                return;
            }
        }
        if (!quantity) {
            let data = selectedData.filter(
                (item) => item.productId != productId
            );
            setSelectedData(data);

            let newProducts = selectedProducts.filter(
                (item) => item != productId
            );
            setSelectedProducts(newProducts);
            return;
        }
        let findindex = selectedData.findIndex(
            (item) => item.productId == productId
        );
        let seletedProductDetails = orders?.orderitems?.filter(
            (item) => item.productId == productId
        );
        let ReturnedQuantity = [];
        let remaining = quantity;
        for (
            let i = 0;
            i <= seletedProductDetails[0].batchInfo.length - 1;
            i++
        ) {
            if (remaining !== 0) {
                if (
                    remaining >
                    seletedProductDetails[0].batchInfo[i].quantitydeducted
                ) {
                    remaining =
                        remaining -
                        seletedProductDetails[0].batchInfo[i].quantitydeducted;

                    ReturnedQuantity = [
                        ...ReturnedQuantity,
                        {
                            POitemId:
                                seletedProductDetails[0].batchInfo[i].POitemId,
                            batchNumber:
                                seletedProductDetails[0].batchInfo[i]
                                    .batchNumber,
                            quantityreturned:
                                seletedProductDetails[0].batchInfo[i]
                                    .quantitydeducted,
                        },
                    ];
                } else {
                    ReturnedQuantity = [
                        ...ReturnedQuantity,
                        {
                            POitemId:
                                seletedProductDetails[0].batchInfo[i].POitemId,
                            batchNumber:
                                seletedProductDetails[0].batchInfo[i]
                                    .batchNumber,
                            quantityreturned: remaining,
                        },
                    ];

                    remaining = 0;
                }
            }
        }
        if (findindex == -1) {
            setSelectedData((prev) => {
                return [
                    ...prev,
                    {
                        TotalReturnedQuantity: e.target.value,
                        productId: productId,
                        ReturnedQuantity: ReturnedQuantity,
                    },
                ];
            });
        } else {
            let data = selectedData.filter(
                (item) => item.productId != productId
            );
            setSelectedData((prev) => {
                return [
                    ...data,
                    {
                        TotalReturnedQuantity: e.target.value,
                        productId: productId,
                        ReturnedQuantity: ReturnedQuantity,
                    },
                ];
            });
        }
    };
    const selectHandler = (e) => {
        let productId = e.currentTarget.getAttribute("id");
        let indexOfProduct = selectedProducts.findIndex(
            (item) => item == productId
        );
        if (indexOfProduct == -1) {
            setSelectedProducts((prev) => [...prev, productId]);
        } else {
            let data = selectedData.filter(
                (item) => item.productId != productId
            );
            setSelectedData(data);
            let newProducts = selectedProducts.filter(
                (item) => item != productId
            );
            setSelectedProducts(newProducts);

            document.getElementById(productId).value = null;
        }
    };
    console.log(selectedProducts);
    const checkProduct = (id) => {
        let newProducts = selectedProducts.findIndex((item) => item == id);
        if (newProducts != -1) {
            return true;
        } else return false;
    };
    // console.log(selectedData);
    return (
        <div
            className={dashboardclasses["return-modal"]}
            onClick={CancelHandler}>
            <div
                className={dashboardclasses["return-modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <form
                    className={dashboardclasses["order-item-data"]}
                    style={{ gap: "1rem" }}
                    onSubmit={submitHandler}>
                    <h4> Order List</h4>
                    {orders?.orderitems?.map((items) => (
                        <div
                            className={dashboardclasses["select-option-items"]}>
                            <div className={dashboardclasses["order-item"]}>
                                <img
                                    src={
                                        items.imageUrl !== "NA"
                                            ? items.imageUrl
                                            : image
                                    }
                                    alt="product"
                                />
                                <div className={dashboardclasses["order-desc"]}>
                                    <h3>{items.productName}</h3>
                                    <p>
                                        Quantity:
                                        {items.quantity}
                                    </p>

                                    {/* <select>
                                        {Array.from(
                                            { length: items.quantity },
                                            (_, i) => i + 1
                                        ).map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </select> */}
                                    <p>Company: {items?.manufacturedBy}</p>
                                    <h3>
                                        Price:₹
                                        {Math.round(items.sellingPrice)}
                                        <span>(Inclusive of all taxes)</span>
                                    </h3>
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["order-return-input"]
                                }>
                                <input
                                    type={"number"}
                                    // onmousew={() => false}
                                    onWheel={(e) => e.target.blur()}
                                    min={0}
                                    id={items.productId}
                                    batchNumber={items.batch_number}
                                    onChange={changehandler}
                                    placeholder={"Enter return Quantity"}
                                />
                                <button
                                    className={
                                        checkProduct(items.productId)
                                            ? dashboardclasses["red-btn"]
                                            : dashboardclasses["green-btn"]
                                    }
                                    type="button"
                                    id={items.productId}
                                    onClick={selectHandler}
                                    disabled={!checkProduct(items.productId)}>
                                    {checkProduct(items.productId)
                                        ? "Remove"
                                        : "Select"}
                                </button>
                            </div>
                        </div>
                    ))}
                    <button
                        type={"submit"}
                        className={dashboardclasses["submit-btn"]}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default OrdersReturnModal;
