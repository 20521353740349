import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GrFormView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddTransfer from "./NewTransfer";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
// import { BsThreeDotsVertical } from "react-icons/bs";
import TransferEdit from "./updateTransfer";
function Warehouse() {
    const Navigate = useNavigate();
    const [params, setparams] = useSearchParams();
    const statusRef = useRef();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    const [transferEdit, setTransferEdit] = useState(false);
    const [transferId, setTransferId] = useState("");
    const [TransferDetails, setTransferDetails] = useState({});
    const [users, setUsers] = useState([]);
    const [searchusers, setSearchusers] = useState([]);
    const [Navactive, setNavactive] = useState(params.get("transfer-type"));
    const [tableload, setTableLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [warehousedata, setWarehouses] = useState([]);
    // const [userid, setUserid] = useState("");
    const [warehouseid, setwarehouseId] = useState();
    // const [status, setStatus] = useState("");

    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setWarehouses(response.warehouses);
                    getStockTransferbyID(response.warehouses[0].warehouseId);
                    setwarehouseId(response.warehouses[0].warehouseId);
                    // setLoading(false);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getStockTransferbyID = (id) => {
        Services.getstockTransferBywarehouseId("GET", null, token, id)
            .then((res) => {
                setLoading(false);
                setTableLoad(false);
                if (res.Status === 1) {
                    setUsers(res.StockiIternalTransfer);
                    let pendingUsers = res.StockiIternalTransfer.filter(
                        (item) =>
                            item.status == Number(params.get("transfer-type"))
                    );
                    setSearchusers(pendingUsers);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        if (warehouseid) {
            getStockTransferbyID(warehouseid);
        }

        // eslint-disable-next-line
    }, [warehouseid]);
    useEffect(() => {
        getwarehouses();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [searchusers]);
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return <Moment format="MMMM Do YYYY, hh:mm a">{date}</Moment>;
    };
    const edithandler = (e, content) => {
        setTransferDetails(content);
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("transferId");
        setTransferId(id);
        setTransferEdit(true);
    };
    const ActionFormatter = (cell, cellcontent) => {
        if (cellcontent.status == 1) {
            return "NA";
        }
        return (
            <div className={dashboardclasses.tablebtns}>
                <button
                    onClick={(e) => {
                        edithandler(e, cellcontent);
                    }}
                    transferId={cellcontent.transferId}>
                    <FaRegEdit size={18} />
                </button>
            </div>
        );
    };
    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>
                {cell === 1 ? "Completed" : "In-process"}
            </p>
        );
    };
    let columns = [
        {
            dataField: "transferId",
            text: "TRANSFER ID",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "fromWarehouse",
            text: "FROM WAREHOUSE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "toWarehouse",
            text: "TO WAREHOUSE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { width: "300px", ...headerstyles };
            },
        },

        {
            dataField: "updatedAt",
            text: "DATE & TIME",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, texAlign: "left", width: "12%" };
            },
        },
    ];

    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });

            Navigate(
                `/internalTransfer?transfer-type=${Navactive}&page=${page}`
            );
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "transferId",
            order: "desc",
        },
    ];

    //     const search = users
    //         .filter(
    //             (item) =>
    //                 item.ownerName
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase()) ||
    //                 item.mobileNumber
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase()) ||
    //                 item.emailId
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase())
    //         )
    //         .filter((item) => item.userStatus == type);
    //     setTableLoad(true);
    //     Navigate(`/internalTransfer?transfer-type=${Navactive}&page=1`);
    //     setpageCount(1);
    //     setSearchusers(search);
    // };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };

    const navactivehandler = (status) => {
        setNavactive(status);
        let newUsers = users.filter((item) => item.status == status);
        setSearchusers(newUsers);
        setparams({ page: 1, "transfer-type": status });
    };
    const warehousefilter = (e) => {
        let id = e.target.value;
        navactivehandler("2");
        setwarehouseId(id);
        setparams({ page: 1, "transfer-type": 2 });
    };
    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            Navigate(`/transfer-details/${row.transferId}?page=1`);
        },
    };
    return (
        <>
            {transferEdit && (
                <TransferEdit
                    setmodal={setTransferEdit}
                    transferId={transferId}
                    getStockTransferbyID={getStockTransferbyID}
                    warehouseId={warehouseid}
                    TransferDetails={TransferDetails}
                />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="warehouseproducts" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div
                            className={
                                dashboardclasses["internal-transfer-heading"]
                            }>
                            <h3>Internal Transfer</h3>
                            <button
                                onClick={() => {
                                    Navigate("/newTransfer");
                                }}>
                                New Transfer
                            </button>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    {/* <input
                                        type={"text"}
                                        placeholder="search by Name/email/phone"
                                        onChange={searchHandler}
                                    /> */}
                                    <select
                                        defaultValue={warehouseid}
                                        onChange={warehousefilter}>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="2"
                                        className={
                                            Navactive === "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={() => navactivehandler("2")}>
                                        In Process
                                    </button>
                                    <button
                                        id="1"
                                        className={
                                            Navactive === "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={() => navactivehandler("1")}>
                                        Past
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`${"category-table"} ${"internal-transfer"}`}>
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchusers}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                            rowEvents={tableRowEvents}
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
