import React, { useEffect, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { menuItems } from "../../Navbar/config";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function AddProducts(props) {
    const Navigate = useNavigate();
    const [options, setoptions] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [selectedOption, setselectedOption] = useState();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const admindata = JSON.parse(localStorage.getItem("admindata"));
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const getadminroles = () => {
    //     Services.getAdminroles("GET", null, token)
    //         .then((res) => {
    //             console.log(res);
    //             // setLoading(false);
    //             if (res.Status === 1) {
    //                 props.setadminroles(res.AdminRoles);
    //             } else if (res.Status === 0) {
    //                 toast.error(`${res.Message}`, {
    //                     position: "top-center",
    //                     autoClose: 1000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //                 if (res.Message === "Token expired") {
    //                     Navigate("/");
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             // alert(err);
    //             alert("something went wrong please try again");
    //             console.log(err);
    //         });
    // };
    const onSubmit = (data) => {
        let method = JSON.stringify({
            ...data,
            ClaimedBy: admindata.adminUserId,
            ClaimStatus: 3,
        });

        Services.ClaimStatus("PUT", method, token, props.searchID)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getUsers(0);
                    // props.getUsers((Number(props.page) - 1) * 10);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>{"Claim"}</h3>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Remarks</label>
                        <div className={dashboardclasses["select"]}>
                            <textarea
                                type="text"
                                {...register("Remarks", {
                                    required: "Remarks are required",
                                })}
                            />

                            {errors?.Remarks && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Remarks?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            Claim
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
