import React from "react";
import poclasses from "../purchaseOrder/po.module.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function ProductDetails(props) {
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    //ProductTransferSearch
    const selectHandler = (e) => {
        let productId = e.currentTarget.getAttribute("productId");
        let [selectedProduct] = props?.products?.filter(
            (item) => item.productId == productId
        );
        let body = {
            warehouseId: props.warehouseId,
            productName: selectedProduct.productName,
            productId: productId,
        };

        props.productnameref.current.value = selectedProduct.productName;

        Services.ProductTransferSearch("POST", JSON.stringify(body), token)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.setShowPoItem(true);
                    if (res.PoItems.length == 0) {
                        // props.setmodal(false);
                        props.setErrorModal(true);
                        props.seterrorMessage("NO POITEMS FOUND");
                        props.productnameref.current.value = "";
                        props.reset({ quantity: "" });
                    } else {
                        props.setCurrentSelectedProducts({
                            PoItems: res.PoItems.map((item) => ({
                                ...item,
                                SelectedQuantity: 0,
                            })),
                            productId: selectedProduct.productId,
                            productDetails: selectedProduct,
                        });
                        props.setmodal(false);
                        props.setShowPoItem(true);
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <div className={poclasses["modal"]} onClick={CancelHandler}>
            <div
                className={poclasses["modalContent"]}
                onClick={openhandler}
                style={{ width: "100%" }}>
                <div className={poclasses["product-table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Product Id</th>
                                <th>Product Name</th>
                                <th>Available qty</th>
                                <th>Manufactured By</th>
                                <th>Price</th>
                                <th>Selling price</th>
                                <th>Expiry</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.products?.length > 0 ? (
                                props?.products?.map((item) => {
                                    return (
                                        <tr
                                            onClick={selectHandler}
                                            productId={item.productId}
                                            key={item.productId}>
                                            <td>{item?.productId}</td>
                                            <td>{item?.productName}</td>
                                            <td>{item?.availableQty}</td>
                                            <td>{item?.manufacturedBy}</td>
                                            <td>{item?.price}</td>
                                            <td>{item?.sellingPrice}</td>
                                            <td>{item?.expiry}</td>
                                            {/* <td>{item["expiry"]}</td> */}
                                            {/* <td>{item?.weight}</td> */}
                                            {/* <td>{item?.supplierType}</td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{ textAlign: "center" }}>
                                        No Products Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* productDetails */}
        </div>
    );
}

export default ProductDetails;
