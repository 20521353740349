import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import Update from "./updateNotify";
import PushModal from "./pushmodal";
import Moment from "react-moment";
function Warehouse() {
    const Navigate = useNavigate();
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [id, setid] = useState();
    const [params] = useSearchParams();
    const [warehousedata, setWarehouses] = useState([]);
    const [modal, setmodal] = useState(false);
    const [searchusers, setSearchusers] = useState([]);
    const [tableload, setTableLoad] = useState(false);
    const [searchTerm, setsearchTerm] = useState("");
    const [warehouseSearch, setWarehouseSearch] = useState("");
    const [notifyFilter, setnotifyFilter] = useState("");
    const [pageCount, setpageCount] = useState(1);
    const [userid, setuserid] = useState("");
    const [customerSearch, setCustomerSearch] = useState("");
    const [pushmodal, setpushmodal] = useState(false);
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };

    const getusers = () => {
        Services.getNotifyProducts("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setUsers(res.Products);
                    setSearchusers(res.Products);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        Services.getwarehouses("GET", null, token)

            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        getusers();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            setTableLoad(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [searchusers]);
    const radioformatter = (cell, cellcontent) => {
        let buttonenabler = true;
        if (cellcontent.availableQty > 0) {
            buttonenabler = false;
        }
        // console.log(typeof cellcontent.status);
        return (
            <div className={dashboardclasses.tablebtnsview}>
                {cellcontent.isNotified == 0 ? (
                    <button
                        onClick={edithandler}
                        id={cellcontent.notifyId}
                        disabled={buttonenabler}
                        userid={cellcontent.userId}
                        style={{
                            display: "flex",
                            gap: "0.5rem",
                        }}>
                        <FaRegEdit size={18} />
                        <p style={{ margin: "0" }}> Notify</p>
                    </button>
                ) : (
                    <p>notified</p>
                )}
            </div>
        );
    };
    const pushHandler = (e) => {
        let userid = e.currentTarget.getAttribute("userid");
        let notifyId = e.currentTarget.getAttribute("id");
        setid(notifyId);
        setuserid(userid);
        setpushmodal(true);
    };
    const pushformatter = (cell, cellcontent) => {
        // console.log(typeof cellcontent.status);
        return (
            <div className={dashboardclasses.tablebtnsview}>
                <button
                    onClick={pushHandler}
                    id={cellcontent.notifyId}
                    userid={cellcontent.userId}
                    style={{
                        display: "flex",
                        gap: "0.5rem",
                    }}>
                    <FaRegEdit size={18} />
                    <p style={{ margin: "0" }}> Push </p>
                </button>
            </div>
        );
    };

    const edithandler = (e) => {
        const method = JSON.stringify({ isNotified: 1 });
        setid(e.currentTarget.getAttribute("id"));
        // setmodal(true);
        Services.updateNotifyProducts(
            "PUT",
            method,
            token,
            e.currentTarget.getAttribute("id")
        )
            .then((res) => {
                if (res.Status === 1) {
                    getusers();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return <Moment format="MMMM Do YYYY, hh:mm a">{date}</Moment>;
    };
    const quantityformatter = (cell) => {
        return <div style={{ textAlign: "center" }}>{cell}</div>;
    };
    const columns = [
        {
            dataField: "notifyId",
            text: "ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ownerName",
            text: "CUSTOMER NAME",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { width: "300px", ...headerstyles };
            },
        },

        {
            dataField: "productName",
            text: "PRODUCT NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "warehouseName",
            text: "WAREHOUSE NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "quantity",
            text: "REQ QUANTITY",
            sort: false,
            formatter: quantityformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
        {
            dataField: "availableQty",
            text: "AVAILABLE QUANTITY",
            sort: false,
            formatter: quantityformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
        {
            dataField: "updatedAt",
            text: "DATE & TIME",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "120px" };
            },
        },
        {
            dataField: "userStatus",
            text: "STATUS",
            sort: false,
            formatter: radioformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Notify",
            text: "",
            sort: false,
            formatter: pushformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            window.scrollTo({ top: 10, behavior: "instant" });
            Navigate(`/notifyproducts?page=${page}`);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        let typeFilter = e.currentTarget.getAttribute("name");
        let search = [];
        if (typeFilter == "search") {
            setsearchTerm(e.target.value);
            search = users.filter((item) =>
                item.productName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            );
            if (warehouseSearch) {
                search = search.filter(
                    (item) => item.warehouseId == warehouseSearch
                );
            }
            if (notifyFilter) {
                search = search.filter(
                    (item) => item.isNotified == notifyFilter
                );
            }
            if (customerSearch) {
                search = search.filter((item) =>
                    item.ownerName
                        .toLowerCase()
                        .includes(String(customerSearch).toLowerCase())
                );
            }
        } else if (typeFilter == "status") {
            setnotifyFilter(e.target.value);
            search = users.filter((item) => item.isNotified == e.target.value);
            if (warehouseSearch) {
                search = search.filter(
                    (item) => item.warehouseId == warehouseSearch
                );
            }
            if (searchTerm) {
                search = search.filter((item) =>
                    item.productName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                );
            }
            if (customerSearch) {
                search = search.filter((item) =>
                    item.ownerName
                        .toLowerCase()
                        .includes(String(customerSearch).toLowerCase())
                );
            }
        } else if (typeFilter == "warehouseFilter") {
            setWarehouseSearch(e.target.value);
            search = users.filter((item) => item.warehouseId == e.target.value);
            if (searchTerm) {
                search = search.filter((item) =>
                    item.productName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                );
            }
            if (notifyFilter) {
                search = search.filter(
                    (item) => item.isNotified == notifyFilter
                );
            }
            if (customerSearch) {
                search = search.filter((item) =>
                    item.ownerName
                        .toLowerCase()
                        .includes(String(customerSearch).toLowerCase())
                );
            }
        } else if (typeFilter == "CustomerSearch") {
            setCustomerSearch(e.target.value);
            search = users.filter((item) =>
                item.ownerName
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            );
            if (notifyFilter) {
                search = search.filter(
                    (item) => item.isNotified == notifyFilter
                );
            }
            if (searchTerm) {
                search = search.filter((item) =>
                    item.productName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                );
            }
            if (warehouseSearch) {
                search = search.filter(
                    (item) => item.warehouseId == warehouseSearch
                );
            }
        }
        // console.log(search);
        setSearchusers(search);
        setTableLoad(true);
        Navigate(`/notifyproducts?page=${1}`);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <>
            {pushmodal && (
                <PushModal
                    setmodal={setpushmodal}
                    userid={userid}
                    id={id}
                    getusers={getusers}
                />
            )}
            {modal && (
                <Update setmodal={setmodal} getusers={getusers} id={id} />
            )}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="notifyproducts" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Notify Products</h3>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by Product name"
                                        onChange={searchHandler}
                                        name="search"
                                    />
                                    <select
                                        name="warehouseFilter"
                                        onChange={searchHandler}
                                        defaultValue="">
                                        <option value="" disabled>
                                            Warehouse
                                        </option>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        name="status"
                                        onChange={searchHandler}
                                        defaultValue="">
                                        <option value="" disabled>
                                            Status
                                        </option>
                                        <option value={1}>Notified</option>
                                        <option value={0}>Not notified</option>
                                    </select>
                                    <input
                                        type={"text"}
                                        placeholder="search by Customer name"
                                        onChange={searchHandler}
                                        name="CustomerSearch"
                                    />
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchusers}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            noDataIndication={emptyDataMessage}
                                            wrapperClasses="table-responsive"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
