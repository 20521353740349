import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import { Services } from "../../../Services";
import { useForm } from "react-hook-form";
import resolveClasses from "../AvailabilityForm/resolve.module.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
function Modal(props) {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const onSubmit = (data) => {
        setloading(true);
        let method = JSON.stringify({
            resolutionComments: data.cancellationReason,
            status: 2,
        });
        Services.getCallBackById("PUT", method, token, props.id)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getCallbackForm();
                    props.setNavactive("1");
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            {loading ? (
                <div className={resolveClasses["modal"]}>
                    <div className={resolveClasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            ) : (
                <div className={resolveClasses["modal"]}>
                    <div
                        className={resolveClasses["modal-content"]}
                        onClick={openhandler}>
                        <div className={resolveClasses["header"]}>
                            <p>Resolve </p>
                            <button
                                className={resolveClasses["close-btn"]}
                                onClick={CancelHandler}>
                                <IoClose size={27} />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={resolveClasses["form-control"]}>
                                <label htmlFor="Cancellation Reason">
                                    Resolve :
                                </label>
                                <textarea
                                    placeholder="reason"
                                    rows={4}
                                    {...register("cancellationReason", {
                                        required:
                                            "Cancellation Reason is required!",
                                    })}
                                />
                                {errors?.CancellationReason && (
                                    <p className={resolveClasses.error}>
                                        {errors?.CancellationReason?.message}
                                    </p>
                                )}
                            </div>
                            <div className={resolveClasses["control-btns"]}>
                                <button
                                    className={resolveClasses["delete-btn"]}
                                    type="button"
                                    onClick={CancelHandler}>
                                    Cancel
                                </button>
                                <button
                                    className={resolveClasses["submit-btn"]}
                                    type="submit">
                                    Resolve
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
