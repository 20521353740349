import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { BsWhatsapp } from "react-icons/bs";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
function Warehouse() {
    const Navigate = useNavigate();
    const [params, setparams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1);
    const [forcepage, setforcepage] = useState(0);
    const [pagsize, setpagesize] = useState(10);
    const [tableload, settableload] = useState(false);
    const [showPaginate, setShowPagiate] = useState(true);
    const [users, setusers] = useState([]);
    const [showClaim, setShowClaim] = useState(false);
    const [searchID, setSearchID] = useState("");

    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let cellContent = JSON.parse(localStorage.getItem("searchhits"));
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getUsers = (skip) => {
        console.log(skip);
        //searchByProductName
        settableload(true);
        let body = JSON.stringify({
            skip: skip,
            limit: 10,
            USERID: params.get("id"),
        });
        Services.medbuzz_Users_Search_Hit("POST", body, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    console.log(response);
                    settableload(false);
                    setusers(response.Users);
                    if (response.Users.length == 0) {
                        setShowPagiate(false);
                    }
                    setpagesize(10);
                    setpageCount(
                        Math.ceil(Number(response?.Count) / Number(10))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        if (params.get("page")) {
            setforcepage(Number(params.get("page")) - 1);
            getUsers((params.get("page") - 1) * 10);
            // console.log(typeof params.get("page"));
        } else {
            getUsers(0);
        }
        // eslint-disable-next-line
    }, []);
    const dateFormatter = (cell, cellContent) => {
        return (
            <Moment format="MMMM Do YYYY, h:mm:ss a">{new Date(cell)}</Moment>
        );
    };
    const columns = [
        {
            dataField: "SearchText",
            text: "SEARCH KEY",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                    "word-wrap": "break-word",
                };
            },
        },
        {
            dataField: "IsResultsFound",
            text: "RESULT FOUND",
            sort: false,
            formatter: (cell) => {
                if (cell == 1) {
                    return "YES";
                } else {
                    return "NO";
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "SearchType",
            text: "SEARCHTYPE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "Source",
            text: "SOURCE",
            sort: false,
            formatter: (cell) => {
                if (cell == 1) {
                    return "APP";
                } else {
                    return "WEB";
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "warehouseName",
            text: "WAREHOUSE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "CreatedAt",
            text: "DATE TIME",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "150px" };
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];

    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        window.scrollTo({ top: 10, behavior: "instant" });
        setparams({ page: selectedObject.selected + 1, id: params.get("id") });
        setforcepage(selectedObject.selected);
        settableload(true);
        getUsers(selectedObject.selected * pagsize);
    };
    return (
        <>
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="deals" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div>
                            <h3>Search Hit</h3>
                            <div
                                className={` ${dashboardclasses["searchHit_Customer"]}`}>
                                <p> {cellContent?.Name},</p>
                                <span>
                                    {cellContent?.PhoneNumber}{" "}
                                    <button
                                        style={{
                                            border: "none",
                                            backgroundColor: "inherit",
                                        }}
                                        onClick={() => {
                                            window.open(
                                                `https://wa.me/${cellContent?.PhoneNumber}`,
                                                "_blank"
                                            );
                                        }}>
                                        <BsWhatsapp size={18} color="green" />
                                    </button>
                                </span>
                                <p> {cellContent?.EmailID}</p>
                            </div>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={users}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                        {showPaginate && (
                                            <div
                                                style={{
                                                    justifySelf: "right",
                                                }}>
                                                <ReactPaginate
                                                    pageCount={pageCount}
                                                    // pageRange={2}
                                                    forcePage={forcepage}
                                                    marginPagesDisplayed={2}
                                                    onPageChange={
                                                        handlePageChange
                                                    }
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={
                                                        "page-link"
                                                    }
                                                    containerClassName={
                                                        "pagination"
                                                    }
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={
                                                        "page-link"
                                                    }
                                                    previousClassName={
                                                        "page-item"
                                                    }
                                                    previousLinkClassName={
                                                        "page-link"
                                                    }
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={
                                                        "page-link"
                                                    }
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
