import React, { useState, useEffect, useRef } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import uuid from "react-uuid";
import { GrFormClose } from "react-icons/gr";
import { State } from "country-state-city";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddCategory(props) {
    const Navigate = useNavigate();
    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState(false);
    const pincoderef = useRef();
    let states = State.getStatesOfCountry("IN");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    //DRUGLICENSE FILE UPLOAD
    const [fileNames, setFileNames] = useState([]);
    const [fileCount, setfileCount] = useState(0);
    const [uploadFileNames, setUploadFileNames] = useState([]);

    const [DrugLicensefileNames, setDrugLicenseFileNames] = useState([]);
    const [DrugLicensefileCount, setDrugLicensefileCount] = useState(0);
    const [uploadDrugLicensefiles, setDrugLicensefiles] = useState([]);

    //GST FILE UPLOAD
    const [GstBasefileNames, setGstBaseFileNames] = useState([]);
    const [GstfileCount, setGstfileCount] = useState(0);
    const [uploadGstFileNames, setUploadGstFileNames] = useState([]);

    const usertype = ["DOCTOR", "CHEMIST", "HOSPITAL"];
    useEffect(() => {
        setpincode(props.user.pincode);
        reset({
            ownerName: props.user.ownerName,
            shopName: props.user.shopName,
            address: props.user.address,
            city: props.user.city,
            state: props.user.state,
            emailId: props.user.emailId,
            mci_registrationNo: props.user.mci_registrationNo,
            GSTNo: props.user.GSTNo,
            drugLicenseNo: props.user.drugLicenseNo,
            status: props.user.status,
            rec_expiryDate: props.user.rec_expiryDate,
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    // const fileTypes = ["JPEG", "PNG"];
    const pincodeChange = (e) => {
        if (e.target.value.length <= 6) {
            setpincodeError(false);
            setpincode(e.target.value);
        }
    };
    const onSubmit = (data) => {
        if (pincode.length < 6) {
            pincoderef.current.focus();
            setpincodeError(true);
            return;
        }
        let submitData = { ...data };
        if (uploadFileNames.length > 0) {
            submitData = {
                pincode: pincode,
                ...submitData,
                doc_letterheadURL: [...uploadFileNames],
            };
        }
        if (DrugLicensefileNames.length > 0) {
            submitData = {
                ...submitData,
                drugLicenseURL: [...uploadDrugLicensefiles],
            };
        }
        if (uploadGstFileNames.length > 0) {
            submitData = {
                ...submitData,

                GSTFileURL: [...uploadGstFileNames],
            };
        }
        // console.log(submitData);
        // return;
        if (!props.editable) {
            Services.addSupplier("POST", JSON.stringify(submitData), token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateuser(
                "PUT",
                JSON.stringify(submitData),
                token,
                props.id
            )
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const base64 = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const handleChange = (files) => {
        if (fileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = fileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                base64(newfile);
                setfileCount((prev) => prev + 1);
                // return;
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        console.log(res);
                        setUploadFileNames((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
                // console.log(fileNames);
            } else {
                alert(`${files[key].name} is neither image nor PDF File`);
                return false;
            }
            return true;
        });
    };

    const gstFILEbase64 = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setGstBaseFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const gstFILEhandleChange = (files) => {
        if (GstfileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = GstfileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                gstFILEbase64(newfile);
                setGstfileCount((prev) => prev + 1);
                // return;
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        // console.log(res);
                        setUploadGstFileNames((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
                // console.log(fileNames);
            } else {
                alert(`${files[key].name} is neither Image nor  PDF File`);
                return false;
            }
            return true;
        });
    };

    const DrugLicensebase64 = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setDrugLicenseFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const druglicenseChange = (files) => {
        if (DrugLicensefileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = DrugLicensefileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                DrugLicensebase64(newfile);
                setDrugLicensefileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        // console.log(res);
                        setDrugLicensefiles((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
                // console.log(DrugLicensefileNames);
            } else {
                alert(`${files[key].name} is neither image nor PDF File`);
                return false;
            }
            return true;
        });
    };
    const removeHandler = (e) => {
        // console.log(fileNames);
        const id = e.currentTarget.getAttribute("id");
        // console.log(id);
        // eslint-disable-next-line
        if (e.currentTarget.getAttribute("usertype") === "doctor") {
            const new_files_array = fileNames.filter((item) => item.id != id);
            setFileNames(new_files_array);
            setfileCount((prev) => prev - 1);
        } else if (e.currentTarget.getAttribute("usertype") === "chemist") {
            const new_files_array = DrugLicensefileNames.filter(
                (item) => item.id != id
            );
            setDrugLicenseFileNames(new_files_array);
            setDrugLicensefileCount((prev) => prev - 1);
        } else if (e.currentTarget.getAttribute("usertype") === "gstfile") {
            const new_files_array = GstBasefileNames.filter(
                (item) => item.id != id
            );
            setGstBaseFileNames(new_files_array);
            setGstfileCount((prev) => prev - 1);
        }
    };
    const fileuploadDesign = (
        <div className={dashboardclasses["add-item"]}>
            <BsCloudUpload className={dashboardclasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg , *.png images and Pdf will be accepted)</p>
        </div>
    );
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>{props.editable ? "Update Users" : "Add User"}</h3>
                        <p>
                            {props.editable ? "Update User" : "Add User"}{" "}
                            necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={`${dashboardclasses["add-items"]} ${dashboardclasses["update-user-form"]}`}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="ownerName">Owner name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("ownerName", {
                                    required: "Owner name is required",
                                })}
                            />

                            {errors?.ownerName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.ownerName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="SupplierName">Shop Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("shopName", {
                                    required: "Shop Name is required",
                                })}
                            />

                            {errors?.shopName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.shopName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="SupplierName">Address</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("address", {
                                    required: "Address is required",
                                })}
                            />

                            {errors?.address && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.address?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="city">City</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("city", {
                                    required: "city is required",
                                })}
                            />

                            {errors?.city && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.city?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="pincode">pincode</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                ref={pincoderef}
                                type={"number"}
                                onChange={pincodeChange}
                                value={pincode}
                                pattern="^[0-9\b]+$"
                            />

                            {pincodeError && (
                                <p className={dashboardclasses["error"]}>
                                    Enter a valid pincode
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="phone">State</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                {...register("state", {
                                    required: "State is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.length > 0 &&
                                    states.map((item) => (
                                        <option value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {errors?.state && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.state?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="emailId">Email</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"email"}
                                {...register("emailId", {
                                    required: "emailId is required",
                                })}
                            />

                            {errors?.emailId && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.phone?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="expiryDate">expiry Date</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type={"date"}
                                {...register("rec_expiryDate", {
                                    required: "expiry Date is required",
                                })}
                            />

                            {errors?.expiryDate && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.expiryDate?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {props.user.userType === "DOCTOR" && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="MCI Registartion Number">
                                    MCI Registartion Number
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("mci_registrationNo", {
                                            required:
                                                "mci_registrationNo is required",
                                        })}
                                    />

                                    {errors?.mci_registrationNo && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {
                                                errors?.mci_registrationNo
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="Doctor letter head Url">
                                    Doctor letter head Url
                                </label>
                                <div
                                    className={
                                        dashboardclasses["uploaded-data"]
                                    }>
                                    <FileUploader
                                        classes={
                                            dashboardclasses[
                                                "upload-image-user"
                                            ]
                                        }
                                        multiple={true}
                                        handleChange={handleChange}
                                        name="file"
                                        children={fileuploadDesign}
                                        // types={fileTypes}
                                    />
                                    <div
                                        className={
                                            dashboardclasses[
                                                "uploaded-img-user"
                                            ]
                                        }>
                                        {fileNames.map((item) => {
                                            return (
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "uploaded-name"
                                                        ]
                                                    }>
                                                    <button
                                                        type="button"
                                                        id={item.id}
                                                        usertype={"doctor"}
                                                        onClick={removeHandler}>
                                                        <GrFormClose
                                                            className={
                                                                dashboardclasses[
                                                                    "image-deleteIcon"
                                                                ]
                                                            }
                                                        />
                                                    </button>
                                                    <img
                                                        src={item.filename}
                                                        alt="uploaded-file"
                                                        width={"200px"}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="GSTNo">GSTNo</label>
                        <div className={dashboardclasses["select"]}>
                            <input {...register("GSTNo", {})} />

                            {errors?.GSTNo && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.GSTNo?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="gst FILE">GST file</label>
                        <div className={dashboardclasses["uploaded-data"]}>
                            <FileUploader
                                classes={dashboardclasses["upload-image-user"]}
                                multiple={true}
                                handleChange={gstFILEhandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                            <div
                                className={
                                    dashboardclasses["uploaded-img-user"]
                                }>
                                {GstBasefileNames.map((item) => {
                                    return (
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "uploaded-name"
                                                ]
                                            }>
                                            <button
                                                type="button"
                                                id={item.id}
                                                usertype={"gstfile"}
                                                onClick={removeHandler}>
                                                <GrFormClose
                                                    className={
                                                        dashboardclasses[
                                                            "image-deleteIcon"
                                                        ]
                                                    }
                                                />
                                            </button>
                                            <img
                                                src={item.filename}
                                                alt="uploaded-file"
                                                width={"200px"}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {props.user.userType === "CHEMIST" && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="pincode">drugLicenseNo</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("drugLicenseNo", {
                                            required:
                                                "drugLicenseNo is required",
                                        })}
                                    />

                                    {errors?.drugLicenseNo && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.drugLicenseNo?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="pincode">drugLicenseURL</label>
                                <div
                                    className={
                                        dashboardclasses["uploaded-data"]
                                    }>
                                    <FileUploader
                                        classes={
                                            dashboardclasses[
                                                "upload-image-user"
                                            ]
                                        }
                                        multiple={true}
                                        handleChange={druglicenseChange}
                                        name="file"
                                        children={fileuploadDesign}
                                        // types={fileTypes}
                                    />
                                    <div
                                        className={
                                            dashboardclasses[
                                                "uploaded-img-user"
                                            ]
                                        }>
                                        {DrugLicensefileNames.map((item) => {
                                            return (
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "uploaded-name"
                                                        ]
                                                    }>
                                                    <button
                                                        type="button"
                                                        usertype={"chemist"}
                                                        id={item.id}
                                                        onClick={removeHandler}>
                                                        <GrFormClose
                                                            className={
                                                                dashboardclasses[
                                                                    "image-deleteIcon"
                                                                ]
                                                            }
                                                        />
                                                    </button>
                                                    <img
                                                        src={item.filename}
                                                        alt="uploaded-file"
                                                        width={"200px"}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="status">Status</label>
                            <div className={dashboardclasses["select"]}>
                                <select
                                    {...register("status", {
                                        required: "status Is required",
                                    })}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                                {errors?.status && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.status?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add User"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
