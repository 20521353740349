import React, { useEffect, useState } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { css } from "@emotion/react";
import resolveClasses from "../../dashboard/AvailabilityForm/resolve.module.css";
import FadeLoader from "react-spinners/FadeLoader";
import Modal from "../../utils/error";
function Reports() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const [FromDateError, setfromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [modal, setModal] = useState(false);
    const [error, seterror] = useState("");
    const [loading, setloading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        let timer = setTimeout(() => {
            setloading(false);
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const watchFormData = watch();
    const onSubmit = (data) => {
        console.log(data);
        if (new Date(data.StartDate) > new Date(data.EndDate)) {
            alert("please select valid Date range");
            return;
        }

        setloading(true);
        Services.userinfodownload("POST", JSON.stringify(data), token)
            .then((res) => {
                console.log("1", res);
                if (res.Status === 1) {
                    fetch(res.CsvFile).then((response) => {
                        response.blob().then((blob) => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `user_info.xlsx`;
                            alink.click();
                            setloading(false);
                            reset();
                        });
                    });
                } else if (res.Status === 0) {
                    setloading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                setloading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <>
            {modal && <Modal setmodal={setModal} errorMessage={error} />}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="Reports" />
                </div>

                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses["Container-report"]}>
                        <div className={dashboardclasses.CategoryWrapper}>
                            <h3>User info</h3>
                        </div>
                        {loading ? (
                            <div className={resolveClasses["modal"]}>
                                <div className={resolveClasses["spinner"]}>
                                    <FadeLoader color="#36d7b7" />
                                </div>
                            </div>
                        ) : (
                            <div
                                className={
                                    dashboardclasses["report-form-wraper"]
                                }>
                                <form
                                    className={dashboardclasses["edit-profile"]}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "edit-profile-formControl"
                                            ]
                                        }>
                                        <label htmlFor="From Date">
                                            From Date
                                        </label>
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-input"
                                                ]
                                            }>
                                            <input
                                                type="date"
                                                disabled={
                                                    watchFormData.Type ==
                                                        "ProductReports" ||
                                                    watchFormData.Type ==
                                                        "StockReports"
                                                }
                                                {...register("StartDate", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.StartDate && (
                                                <p
                                                    className={
                                                        dashboardclasses[
                                                            "error"
                                                        ]
                                                    }>
                                                    startDate is required!
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "edit-profile-formControl"
                                            ]
                                        }>
                                        <label htmlFor="toDate">To Date</label>
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "edit-profile-input"
                                                ]
                                            }>
                                            <input
                                                type="date"
                                                {...register("EndDate", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.EndDate && (
                                                <p
                                                    className={
                                                        dashboardclasses[
                                                            "error"
                                                        ]
                                                    }>
                                                    End Date is Required!
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <input type={"submit"} value="Download" />
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reports;
