import React, { useEffect, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { menuItems } from "../../Navbar/config";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function AddProducts(props) {
    const Navigate = useNavigate();
    const [options, setoptions] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [selectedOption, setselectedOption] = useState();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        setoptions(
            menuItems.map((item) => {
                return { value: item.name, label: item.name };
            })
        );
        if (props.editable) {
            reset({ roleName: props.active });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const getadminroles = () => {
        Services.getAdminroles("GET", null, token)
            .then((res) => {
                console.log(res);
                // setLoading(false);
                if (res.Status === 1) {
                    props.setadminroles(res.AdminRoles);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const onSubmit = (data) => {
        let selectedData = selectedOption?.selectedOption.map(
            (item) => item.label
        );

        if (!props.editable) {
            let method = JSON.stringify({
                ...data,
                accessItems: selectedData,
            });

            Services.AddAdminroles("POST", method, token)
                .then((res) => {
                    if (res.status === 1) {
                        props.setmodal(false);
                        getadminroles();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            let method = JSON.stringify({
                ...data,
                // accessItems: selectedData,
            });

            Services.UpdateAdminroles("PUT", method, token, props.roleid)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setactive(data.roleName);
                        props.setmodal(false);
                        getadminroles();
                        props.setEditable(false);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const changeHandler = (selectedOption) => {
        setselectedOption({ selectedOption });
        console.log({ selectedOption });
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>{props.editable ? "Update role" : "Add role"}</h3>
                        <p>
                            {props.editable ? "Update role" : "Add role"} and
                            necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="categoryName">Role Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                type="text"
                                {...register("roleName", {
                                    required: "roleName is required",
                                })}
                            />

                            {errors?.roleName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.roleName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {!props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="items">
                                Access Items <br />
                                {/* <p style={{ fontSize: "0.8rem", margin: 0 }}>
                                {" "}
                                (comma separated values)
                            </p> */}
                            </label>
                            <div className={dashboardclasses["select"]}>
                                <Select
                                    onChange={changeHandler}
                                    // value={selectedOption}
                                    className="react-select-container"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={options}
                                />
                                {errors?.accessItems && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.accessItems?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add role"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProducts;
