import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Addusers from "./addProducs";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
function Warehouse() {
    const Navigate = useNavigate();
    const [addUser, setAddUser] = useState(false);
    const [id, setid] = useState();
    const [editable, setEditable] = useState(false);

    const [tableload, settableload] = useState(false);
    const [products, setproducts] = useState([]);
    const [searchproducts, setSearchproducts] = useState([]);
    const [warehousedata, setWarehouses] = useState([]);

    const [warehouseid, setwarehouseId] = useState();
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const categoryHandler = () => {
        setAddUser((prev) => !prev);
    };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getwarehouseproducts = (id) => {
        settableload(true);
        Services.stockValue("GET", null, token, id)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    settableload(false);
                    // setproducts(response.Products);
                    // setSearchproducts(response.Products);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                    getwarehouseproducts("all");
                    setLoading(false);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message == "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getwarehouses();
        // eslint-disable-next-line
    }, []);

    const edithandler = (e) => {
        console.log({
            warehouseid: e.currentTarget.getAttribute("warehouseid"),
            productid: e.currentTarget.getAttribute("productid"),
        });
        setid({
            warehouseid: e.currentTarget.getAttribute("warehouseid"),
            productid: e.currentTarget.getAttribute("productid"),
        });
        setEditable(true);
        setAddUser(true);
    };
    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.deleteAdmindata(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        getwarehouses();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message == "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ledger = (e) => {
        let productId = e.currentTarget.getAttribute("productid");
        let warehouseid = e.currentTarget.getAttribute("warehouseid");

        Navigate(`/ledger/${productId}/${warehouseid}`);
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button
                    onClick={edithandler}
                    warehouseid={cellcontent.warehouseId}
                    productid={cellcontent.productId}>
                    <FaRegEdit size={18} />
                </button>

                <ul className={dashboardclasses["menu"]}>
                    <li>
                        <button
                            // onClick={deleteHandler}
                            warehouseid={cellcontent.warehouseId}
                            productid={cellcontent.productId}>
                            <BsThreeDotsVertical size={18} />
                        </button>
                        <ul className={dashboardclasses["submenu"]}>
                            <li>
                                <button
                                    onClick={ledger}
                                    warehouseid={cellcontent.warehouseId}
                                    productid={cellcontent.productId}>
                                    Ledger
                                </button>
                            </li>
                            <li>
                                <button
                                    warehouseid={cellcontent.warehouseId}
                                    productid={cellcontent.productId}>
                                    Customer
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };
    // const statusFormatter = (cell, cellcontent) => {
    //     let classname =
    //         cell === 1
    //             ? dashboardclasses["status_active"]
    //             : dashboardclasses["status_inactive"];
    //     return (
    //         <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
    //     );
    // };

    const columns = [
        {
            dataField: "productName",
            text: "PRODUCT NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                    // width: "15%",
                    "word-wrap": "break-word",
                };
            },
        },
        {
            dataField: "genericName",
            text: "GENERIC NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "manufacturedBy",
            text: "MANUFACTURER",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "price",
            text: "PRICE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "sellingPrice",
            text: "SELLING PRICE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "availableQty",
            text: "AVAILABLE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, texAlign: "left", width: "12%" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const warehousefilter = (e) => {
        setwarehouseId(e.target.value);
        getwarehouseproducts(e.target.value);
    };
    const namefilter = (e) => {
        const search = products.filter((item) =>
            String(item.productName.toLowerCase()).includes(
                e.target.value.toLowerCase()
            )
        );

        console.log(search);
        console.log("search");
        setSearchproducts(search);
    };
    return (
        <>
            {addUser && (
                <Addusers
                    setmodal={setAddUser}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    getwarehouses={getwarehouseproducts}
                    products={products}
                    warehouseid={warehouseid}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="Stock" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Stock</h3>

                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by product name"
                                        onChange={namefilter}
                                    />

                                    <select
                                        defaultValue={
                                            warehousedata[0].warehouseId
                                        }
                                        onChange={warehousefilter}>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={categoryHandler}>
                                        + Add Products
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchproducts}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                            wrapperClasses="table-responsive"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
