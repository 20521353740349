import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import "../../../App.css";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Addusers from "./addProducs";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
function Warehouse() {
    const Navigate = useNavigate();
    const [params, setparams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1);
    let adminData = JSON.parse(localStorage.getItem("admindata"));
    const [addUser, setAddUser] = useState(false);
    const [id, setid] = useState();
    const [editable, setEditable] = useState(false);
    const [forcepage, setforcepage] = useState(0);
    const [pagsize, setpagesize] = useState(1);
    const [tableload, settableload] = useState(false);
    const [products, setproducts] = useState([]);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchproducts, setSearchproducts] = useState([]);
    const [warehousedata, setWarehouses] = useState([]);
    const [nameSearch, setNamesearch] = useState("");

    const [warehouseid, setwarehouseId] = useState();
    let [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const categoryHandler = () => {
        setAddUser((prev) => !prev);
    };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getwarehouseproducts = (id, skip) => {
        //searchByProductName
        settableload(true);
        let body = JSON.stringify({
            skip: skip,
            warehouseId: id,
        });
        Services.warehouseProducts("POST", body, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    console.log(response);
                    settableload(false);
                    setproducts(response.Products);
                    setSearchproducts(response.Products);
                    if (response.Products.length == 0) {
                        setShowPagiate(false);
                    }
                    setpagesize(8);
                    setpageCount(
                        Math.ceil(Number(response?.Count) / Number(8))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getwarehouses = () => {
        Services.getwarehouses("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setWarehouses(response.warehouses);
                    if (params.get("page") != 1) {
                        setforcepage(params.get("page") - 1);
                        getwarehouseproducts(
                            response.warehouses[0].warehouseId,
                            params.get("page") - 1
                        );
                    } else {
                        getwarehouseproducts(
                            response.warehouses[0].warehouseId,
                            0
                        );
                    }
                    setwarehouseId(response.warehouses[0].warehouseId);
                    setLoading(false);
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        //
        if (adminData?.warehouseId) {
            setwarehouseId(adminData?.warehouseId);
            setWarehouses([
                {
                    warehouseid: adminData?.warehouseId,
                    warehouseName: adminData?.warehouseName,
                },
            ]);
            getwarehouseproducts(adminData?.warehouseId, 0);
            return;
        }
        getwarehouses();
        if (params.get("page")) {
            setpageCount(Number(params.get("page")));
            // console.log(typeof params.get("page"));
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let timer = setTimeout(() => {
            settableload(false);
            console.log("11");
        }, 150);
        return () => {
            clearTimeout(timer);
        };
    }, [searchproducts]);
    const edithandler = (e) => {
        console.log({
            warehouseid: e.currentTarget.getAttribute("warehouseid"),
            productid: e.currentTarget.getAttribute("productid"),
        });
        setid({
            warehouseid: e.currentTarget.getAttribute("warehouseid"),
            productid: e.currentTarget.getAttribute("productid"),
        });
        setEditable(true);
        setAddUser(true);
    };

    const ledger = (e) => {
        let productId = e.currentTarget.getAttribute("productid");
        let warehouseid = e.currentTarget.getAttribute("warehouseid");

        let [selectedItem] = searchproducts.filter(
            (item) => item.productId == productId
        );
        console.log(selectedItem);
        localStorage.setItem(
            "ledgerDetails",
            JSON.stringify({
                productName: selectedItem.productName,
                availableQty: selectedItem.availableQty,
            })
        );
        Navigate(`/ledger/${productId}/${warehouseid}`);
    };

    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.tablebtns}>
                <button
                    onClick={edithandler}
                    warehouseid={cellcontent.warehouseId}
                    productid={cellcontent.productId}>
                    <FaRegEdit size={18} />
                </button>

                <ul className={dashboardclasses["menu"]}>
                    <li>
                        <button
                            // onClick={deleteHandler}
                            warehouseid={cellcontent.warehouseId}
                            productid={cellcontent.productId}>
                            <BsThreeDotsVertical size={18} />
                        </button>
                        <ul className={dashboardclasses["submenu"]}>
                            {adminData?.adminroldeinfo?.roleName == "Admin" && (
                                <li>
                                    <button
                                        onClick={ledger}
                                        warehouseid={cellcontent.warehouseId}
                                        productid={cellcontent.productId}>
                                        Ledger
                                    </button>
                                </li>
                            )}
                            <li>
                                <button
                                    warehouseid={cellcontent.warehouseId}
                                    productid={cellcontent.productId}>
                                    Customer
                                </button>
                                <button
                                    onClick={() => {
                                        Navigate(
                                            `/product-details/${cellcontent.productId}/${cellcontent.warehouseId}?page=1`
                                        );
                                        localStorage.setItem(
                                            "productName",
                                            cellcontent.productName
                                        );
                                    }}>
                                    View Details
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const statusFormatter = (cell, cellcontent) => {
        let classname =
            cell === 1
                ? dashboardclasses["status_active"]
                : dashboardclasses["status_inactive"];
        return (
            <p className={classname}>{cell === 1 ? "Active" : "Inactive"}</p>
        );
    };
    const columns = [
        {
            dataField: "productName",
            text: "PRODUCT NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                    // width: "15%",
                    "word-wrap": "break-word",
                };
            },
        },
        {
            dataField: "genericName",
            text: "GENERIC NAME",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "manufacturedBy",
            text: "MANUFACTURER",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "price",
            text: "PRICE",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "genifast_sellingPrice",
            text: "GENIFAST PRICE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, textAlign: "center" };
            },
        },
        {
            dataField: "availableQty",
            text: "AVAILABLE",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "warehouseproduct_status",
            text: "Status",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "ACTIONS",
            text: "ACTIONS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, texAlign: "left", width: "12%" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: pageCount,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            Navigate(`/warehouseproducts?page=${page}`);
            setpageCount(pageCount);

            window.scrollTo({ top: 10, behavior: "instant" });
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const warehousefilter = (e) => {
        settableload(true);
        Navigate(`/warehouseproducts?page=${1}`);
        setpageCount(1);
        setwarehouseId(e.target.value);
        getwarehouseproducts(e.target.value, 0);
    };

    const searchByName = (skip, productName) => {
        let body = JSON.stringify({
            skip: skip,
            warehouseId: warehouseid,
            productName: productName,
        });
        Services.searchByProductName("POST", body, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    // setShowPagiate(false);
                    settableload(false);
                    setproducts(response.Products);
                    setSearchproducts(response.Products);
                    if (response.Products.length == 0) {
                        setShowPagiate(false);
                    }
                    setpagesize(8);
                    setpageCount(
                        Math.ceil(Number(response?.Count) / Number(8))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const namefilter = (e) => {
        settableload(true);
        setparams({ page: 1 });
        setforcepage(0);
        settableload(true);
        setNamesearch(e.target.value);
        searchByName(0, e.target.value);
        setShowPagiate(true);
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        window.scrollTo({ top: 10, behavior: "instant" });
        setparams({ page: selectedObject.selected + 1 });
        setforcepage(selectedObject.selected);
        settableload(true);
        if (nameSearch.length > 0) {
            searchByName(selectedObject.selected * pagsize, nameSearch);
        } else {
            getwarehouseproducts(
                warehouseid,
                selectedObject.selected * pagsize
            );
        }
    };
    return (
        <>
            {addUser && (
                <Addusers
                    setmodal={setAddUser}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    getwarehouses={getwarehouseproducts}
                    products={products}
                    warehouseid={warehouseid}
                    warehousedata={warehousedata}
                    setpageCount={setpageCount}
                    tableload={settableload}
                />
            )}

            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="warehouseproducts" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />

                    <div className={dashboardclasses.CategoryWrapper}>
                        <div className={dashboardclasses["internalTransfer"]}>
                            <h3>Warehouse Products</h3>
                            <button
                                onClick={() => {
                                    Navigate(
                                        "/internalTransfer?transfer-type=2&page=1"
                                    );
                                }}>
                                Internal Transfer
                            </button>
                        </div>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["category-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="search by product name"
                                        onChange={namefilter}
                                    />

                                    <select
                                        defaultValue={
                                            warehousedata[0].warehouseId
                                        }
                                        onChange={warehousefilter}>
                                        {warehousedata?.map((item) => (
                                            <option value={item.warehouseId}>
                                                {item.warehouseName}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={categoryHandler}>
                                        + Add Products
                                    </button>
                                </div>
                                {tableload ? (
                                    <div className={dashboardclasses["loader"]}>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                ) : (
                                    <div className="category-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={searchproducts}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            wrapperClasses="table-responsive"
                                            noDataIndication={emptyDataMessage}
                                        />
                                    </div>
                                )}
                                {showPaginate && (
                                    <div
                                        style={{
                                            justifySelf: "right",
                                        }}>
                                        <ReactPaginate
                                            pageCount={pageCount}
                                            // pageRange={2}
                                            forcePage={forcepage}
                                            marginPagesDisplayed={2}
                                            onPageChange={handlePageChange}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Warehouse;
